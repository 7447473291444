import { useEffect, useState } from "react"
import { RichText, Seperator, TabItem } from "@atoms"
import ViewAndSortOption from "@molecules/viewAndSortOptions"
import NUMBERS from "@helpers/constants/numbers"
import { ITabsProps } from "./_tabs.interface"

const Tabs = ({
  tabContents = [],
  options = [],
  activeItem,
  tabHeadItems,
  trigger,
  handlePagination,
  tabHeadClassName,
  tabContentClassName,
  tabWithDropdown,
  tabHeadID,
  ariaLabel,
  tabContentID,
  name,
  isMobileDisplay,
  label,
  view,
  hideViewTypeCTA,
  hideSortCTA,
  onSaveIdHandler,
  handleSort,
  selectedTabCallBack,
  labelKeyValuePair,
}: ITabsProps) => {
  const [selectedItem, setSelectedItem] = useState(activeItem?.num || NUMBERS.ZERO)
  const getTabHeadClasses = () => {
    const classes = ["nav", tabHeadClassName].filter(Boolean)
    return classes.join(" ")
  }

  const getTabContentClasses = () => {
    const classes = ["tab-content", tabContentClassName].filter(Boolean)
    return classes.join(" ")
  }

  const handleChange = (event: any) => {
    const index = parseInt(event.target[event.target.selectedIndex].getAttribute("data-index"), 10)
    setSelectedItem(index)
  }

  const handleClick = (index: number, label?: string) => {
    if (handlePagination) {
      handlePagination(0)
    }
    setSelectedItem(index)

    // @ts-ignore
    selectedTabCallBack && selectedTabCallBack(label)
  }

  useEffect(() => {
    if (trigger) {
      setSelectedItem(0)
    }
  }, [trigger])

  useEffect(() => {
    if (activeItem?.num) {
      setSelectedItem(activeItem.num)
    }
  }, [activeItem])

  const viewAndSortOptionsProps = {
    isMobileDisplay,
    label,
    view,
    hideViewTypeCTA,
    hideSortCTA,
    onSaveIdHandler,
    handleSort,
    labelKeyValuePair,
  }
  const handleNextTab = (firstTabInRound: number, nextTab: number, lastTabInRound: number) => {
    const tabToSelect = selectedItem === lastTabInRound ? firstTabInRound : nextTab
    setSelectedItem(tabToSelect)
    tabHeadItems[tabToSelect]?.ref?.current.focus()
  }
  const handleKeyPress = (event: any) => {
    const tabCount = Object.keys(tabHeadItems).length

    if (event.key === "ArrowLeft") {
      const next = selectedItem - 1
      handleNextTab(tabCount - 1, next, 0)
    }
    if (event.key === "ArrowRight") {
      const next = selectedItem + 1
      handleNextTab(0, next, tabCount - 1)
    }
  }
  return tabWithDropdown ? (
    <div className="tab-container tab-wDropdown">
      <ul className={getTabHeadClasses()} id={tabHeadID} role="tablist">
        {tabHeadItems?.map((item: any, index: number) => (
          <TabItem
            itemID={item.itemID}
            key={index}
            active={selectedItem === index}
            onClick={() => handleClick(index)}
            greyColor>
            {`${item.children}`}
          </TabItem>
        ))}
      </ul>
      <div className="language-dropdown-container">
        <select
          className="form-select language-dropdown"
          onChange={handleChange}
          aria-label={ariaLabel}
          name={name}>
          {options?.map((option, index) => (
            <option
              value={option.value}
              data-index={index}
              key={index}
              disabled={option.disabled}
              selected={option.selected}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <Seperator className="hr mob-seperator" />
      <div className={getTabContentClasses()} id={tabContentID}>
        {tabContents?.map((item: any, index: number) => (
          <div
            key={index}
            className={`tab-pane fade ${selectedItem === index ? "show active" : ""}`}
            role="tabpanel">
            <div className="language">{item}</div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="tab-container">
      <div className="list">
        <ul className={getTabHeadClasses()} id={tabHeadID} role="tablist">
          {tabHeadItems?.map((item: any, index: number) =>
            item?.itemID ? (
              <TabItem
                itemID={item.itemID}
                key={index}
                active={selectedItem === index}
                onClick={() => handleClick(index, item.id)}
                tabDesign={item.tabDesign}
                className="no-breaking-words"
                greyColor
                onKeyDown={handleKeyPress}>
                <RichText content={`${item.children}`} />
              </TabItem>
            ) : (
              <></>
            ),
          )}
          {!isMobileDisplay && (
            <li className="view-sort-btn-group">
              <ViewAndSortOption {...viewAndSortOptionsProps} />
            </li>
          )}
        </ul>
      </div>

      {isMobileDisplay && (
        <div className="view-sort-btn-group">
          <ViewAndSortOption {...viewAndSortOptionsProps} />
        </div>
      )}
      <div className={getTabContentClasses()} id={tabContentID}>
        {tabContents?.map((item: any, index: number) => (
          <div
            key={index}
            className={`tab-pane fade ${selectedItem === index ? "show active" : ""}`}
            role="tabpanel">
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tabs
