import { Anchor, Button, Container, Icon, Img, Seperator } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { useMediaQuery } from "@helpers/hooks"
import { useOutsideClick } from "@helpers/hooks/useOutsideClick"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import useUserSessionControl from "@helpers/hooks/userSessionControlHook/useUserSession"
import { Notification } from "@molecules"
import { LanguageSwitcherPopup, LoginPopup, SearchBar } from "@organisms"
import { UserMenuType } from "@organisms/userMenu/_userMenu.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import { middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { EVENTS } from "@utils/gtmEvents"
import {
  handleDataLayerNavigationHeader,
  handleDataLayerNavigationMenu,
} from "@utils/gtmUtilsHelpers"
import { dataLayerPush } from "@utils/gtmutils"
import { getAssetPrefixUrl, getHomepageurl, isDocCheckUser } from "@utils/helper"
import { encodeString } from "@utils/sha256"
import { useRouter } from "next/router"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { resetUser } from "store/actions/ActionCreators"
import {
  setRefApplicationID,
  setUserStateGlobally,
  updateReloadContent,
} from "store/actions/CommonActionCreators"
import { resetRatings } from "store/actions/RatingsActionCreators"
import { ISimplifiedMenu, ISwitcherItem } from "./_simplifiedHeader_interface"

export const SimplifiedMenuSwitcher = (type: string) => {
  switch (type) {
    case "SEARCH":
      return "simplifiedMenuSearch"
    case "LOGIN":
      return "simplifiedMenuLogin"
    case "REGISTER":
      return "simplifiedMenuRegister"
    case "LANGUAGE":
      return "simplifiedMenuLanguage"
    case "SWITCH_VIEWS":
      return "simplifiedMenuView"
    default:
      return ""
  }
}

const SimplifiedHeader = ({
  topSection,
  userData,
  languageList,
  megaMenu,
  loginPopup,
  micrositeConfig,
  headerConfig,
  brandSwitcherData,
}: ISimplifiedMenu | any) => {
  //variable declaration
  const [menuOpen, setMenuOpen] = useState(false)
  const [profileSwitcher, setProfileSwitcher] = useState(false)
  const [brandSwitcher, setBrandSwitcher] = useState(false)
  const [loginMenu, setLoginMenu] = useState<any>([])
  const navbarRef = useRef(null)
  const { applicationConfiguration, applicationComponentsData } = useContext(ApplicationContext)
  const language_switcher_title = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.language_switcher_title : null;
  const language_switcher = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.language_switcher : null;
  const Label_field  = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.Label_field : null;
  const custom_url  = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.custom_url : null;
  const login_register_enable  = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.login_register_enable : null;
  const disable_search   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.disable_search : null;
  const header_logo_link   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.header_logo_link : null;
  const header_logo_alt   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.header_logo_alt : null;
  const header_image_url   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.header_image_url : null;
  const search_label   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.search_label : null;
  const disable_logo   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.disable_logo : null;
  const disable_megamenu   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.disable_megamenu : null;
  const disable_cart   = applicationConfiguration?.siteConfig ? applicationConfiguration?.siteConfig?.disable_cart : null;
  const { handleLoginRedirection, getCurrentAppId, getUrlWithParams, appendEncodedReturnUrl } =
    useSandozIDAuth(applicationConfiguration)

  const [loginPopupShow, setLoginPopupShow] = useState(false)
  const headerDisplayControl = applicationConfiguration?.headerConfiguration
  const dispatch: Dispatch<any> = useDispatch()
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false)
  const [showLoginToast, setShowLoginToast] = useState(false)
  const [toastMessage, setToastMessage] = useState<string>("")
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const { clearUserSessionData } = useUserSessionControl()
  const [isLangSwitcherActive, setIsLangSwitcherActive] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const user: any = useSelector((state: UserState) => state.user, shallowEqual)
  const webshop: any = useSelector((state: any) => state.webshop)
  const appData: any = useSelector((state: any) => state?.common)

  const router = useRouter()
  const isMobile = useMediaQuery("(max-width: 767px)")
  const isTablet = useMediaQuery("(max-width: 991px)")

  let clickOutsideMenuDone = false
  let clickOutsideMenuButtonDone = false

  const handleClickOutsideSearch = () => {
    setIsSearchOpen(false)
  }
  const refSearch = useOutsideClick(handleClickOutsideSearch)
  const setMobileMenuState = () => {
    if (clickOutsideMenuDone) {
      resetMobileMenu()
    }
  }

  // flag to show Cart Icon for non-loggedIn users
  const showCartIcon =
    !!applicationConfiguration?.siteConfig?.webstore_enable_non_loggedin_users &&
    webshop?.productsInCart?.length > 0

  const handleCartNavigation = () => {
    router.push("/order-materials/cart")
  }
  const handleClickOutsideMenu = () => {
    clickOutsideMenuDone = true
    setMobileMenuState()
  }
  const handleCartRedirection = (event: any) => {
    if (event.key === "Enter") {
      handleCartNavigation()
    }
  }
  const handleClickOutsideMenuButton = () => {
    clickOutsideMenuButtonDone = true
    setMobileMenuState()
  }
  const handleClickOutsideLanguageSwitcherButton = () => {
    clickOutsideLanguageSwitcherMenuButtonDone = true
    setLanguageSwitcherState()
  }

  let clickOutsideLanguageSwitcherDone = false
  let clickOutsideLanguageSwitcherMenuButtonDone = false
  const refLanguageSwitcherButton = useOutsideClick(handleClickOutsideLanguageSwitcherButton)

  const setLanguageSwitcherState = () => {
    setIsLangSwitcherActive(false)
    clickOutsideLanguageSwitcherDone = false
    clickOutsideLanguageSwitcherMenuButtonDone = false
  }
  
  const handleClickOutsideLanguageSwitcher = () => {
    clickOutsideLanguageSwitcherDone = true
    setLanguageSwitcherState()
  }
  const refLanguageSwitcher = useOutsideClick(handleClickOutsideLanguageSwitcher)
  const refMenu = useOutsideClick(handleClickOutsideMenu)
  const refMenuButton = useOutsideClick(handleClickOutsideMenuButton)
  const resetRating: any = useCallback((value: any) => dispatch(resetRatings(value)), [dispatch])
  const updateReloadStatus: any = useCallback(
    (value: any) => dispatch(updateReloadContent(value)),
    [dispatch],
  )
  const updateRefAppID: any = useCallback(
    (value: any) => dispatch(setRefApplicationID(value)),
    [dispatch],
  )
  const deleteUserFromStore: any = useCallback(
    (value: any) => dispatch(resetUser(value)),
    [dispatch],
  )
  const resetUserGlobally: any = useCallback(
    (isLoggedIn: boolean, type: string) => dispatch(setUserStateGlobally(isLoggedIn, type)),
    [dispatch],
  )

  useEffect(() => {
    resetMobileMenu()
    setMenuOpen(false)
    setProfileSwitcher(false)
    setBrandSwitcher(false)

    setLoginMenu([
      {
        title: search_label,
        link: "",
        type: "SEARCH",
        link_type: false,
      },
      {
        title: topSection?.registerText,
        link: topSection?.registerLink,
        type: "REGISTER",
        link_type: false,
      },
      {
        title: topSection?.loginText,
        link: "",
        type: "LOGIN",
        link_type: false,
      },
      {
        title: language_switcher_title,
        link: "",
        type: "LANGUAGE",
        link_type: false,
      },
      {
        title: Label_field,
        link: custom_url,
        type: "SWITCH_VIEWS",
        link_type: false,
      },
    ])
  }, [])

  useEffect(() => {
    resetMobileMenu()
    setMenuOpen(false)
    setProfileSwitcher(false)
    setBrandSwitcher(false)
    setIsSearchOpen(false)
  }, [router.query])

  const getUserNames = (userDetails: any) => {
    const userProfileData = userDetails?.profile?.response?.profile
    const firstName = userProfileData?.records?.[0]?.FirstName
    const lastName = userProfileData?.records?.[0]?.LastName
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : ""

    return {
      firstName,
      lastName,
      fullName,
    }
  }
  useEffect(() => {
    if (!showLoginToast || !user.isLoggedIn || (user.isLoggedIn && !user.profile)) {
      return
    }

    const loginToastMessage = `<p>${topSection.toastMessages.loginMessage} ${
      getUserNames(user).fullName
    }</p>`
    setToastMessage(loginToastMessage)
    setTimeout(() => {
      setToastMessage("")
      setShowLoginToast(false)
    }, 5000)
  }, [showLoginToast, user])

  //functions
  const handleTextSearch = (text: string, e?: MouseEvent) => {
    if (e) {
      e.preventDefault()
    }
    if (text.length > 0) {
      if (router.pathname === "/search") {
        router.push({ pathname: "/search", query: { search: `${text}` } }, undefined, {
          shallow: true,
        })
      } else {
        router.push({ pathname: "/search", query: { search: `${text}` } })
      }
    }
  }
  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: "How can we help today?",
    },
    buttonText: "Search",
    handleTextSearch,
  }
  const handleLanguageSwitcher = () => {
    setIsLangSwitcherActive((current) => !current)
    handleDataLayerNavigationHeader("Language Switcher")
  }

  const rightHeaderSection = (
    <div className="menu-section-right">
      {!headerDisplayControl?.isMegaMenuDisabled && !headerConfig?.field_disable_megamenu && (
        <button
          className="navbar-toggler collapsed mobile-close-icon"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={false}
          aria-label="Toggle navigation"
          ref={refMenuButton}
          onClick={() => (clickOutsideMenuDone = false)}
          tabIndex={0}>
          {!menuOpen && (
            <Icon
              iconName="HamburgerIcon2"
              onClick={() => {
                setMenuOpen(true)
                setProfileSwitcher(false)
                setBrandSwitcher(false)
              }}
              className="hamburger-icon"
            />
          )}
          {menuOpen && (
            <Icon iconName="simplifiedMenuCloseIcon" onClick={() => setMenuOpen(false)} />
          )}
        </button>
      )}
    </div>
  )

  const loginSwitcher = (details: any) => (
    <>
      <div className="switcher">
        {userData?.loginStatus && (
          <div
            className="user-menu-login cursor-default"
            aria-labelledby="user-menu-login"
            onClick={(e) => e.stopPropagation()}>
            <h5 className="user-menu-greetings" id="user-menu-login">
              {topSection?.userMenuDetails?.user_menu_logged_in}
            </h5>
            <h5 className="user-menu-username">{userData?.name}</h5>
          </div>
        )}
        {details && details?.length > 0 && (
          <ul className="switcher-list">
            {details?.map((child: ISwitcherItem, key: number) => {
              return (
                <div key={key}>
                  {child?.type === "REGISTER" &&
                  login_register_enable &&
                  !userData?.loginStatus &&
                  child?.title ? (
                    <>
                      <li className="switcher-dropdown" key={key} >
                        <Anchor
                          href={applicationConfiguration?.isSandozIDEnabled
                            ? appendEncodedReturnUrl(child.link)
                            : child.link}
                          tabindex={0}
                          className="switcher-dropdown-list"
                          isExternal={child?.link_type ?? false}
                          icon={<Icon iconName={SimplifiedMenuSwitcher(child?.type) ?? ""} />}
                          noUnderline={true}
                          dataLayerOnClick={() => handleDataLayerNavigationHeader(child?.title)}>
                          <h5>{child?.title}</h5>
                        </Anchor>
                      </li>
                      <Seperator className="seperator" aria-hidden="true" />
                    </>
                  ) : (
                    <></>
                  )}
                  {child?.type === "LOGIN" &&
                  login_register_enable &&
                  !userData?.loginStatus &&
                  child?.title ? (
                    <>
                      <li className="switcher-dropdown" key={key}>
                        <div
                          className="switcher-dropdown-list-button"
                          onClick={() => {
                            setProfileSwitcher(false)
                            applicationConfiguration?.isSandozIDEnabled
                              ? handleLoginRedirection()
                              : setLoginPopupShow(true)
                            handleDataLayerNavigationHeader(child?.title)
                          }}
                          role="button"
                          onKeyDown={(event) => {
                            toggleLoginPopUp(event)
                            handleDataLayerNavigationHeader(child?.title)
                          }}
                          tabIndex={0}>
                          <span className="">
                            <Icon iconName={SimplifiedMenuSwitcher(child?.type)} />
                          </span>
                          <h5 className="login">{child?.title}</h5>
                        </div>
                      </li>
                      <Seperator className="seperator" aria-hidden="true" />
                    </>
                  ) : (
                    <></>
                  )}
                  {child?.type === "SEARCH" && !disable_search && child?.title ? (
                     <>
                      <li className="switcher-dropdown" key={key}>
                        <div
                          className="switcher-dropdown-list-button"
                          onClick={handleSearch}
                          role="button"
                          onKeyDown={(event) => {
                            toggleLoginPopUp(event)
                            handleDataLayerNavigationHeader(child?.title)
                          }}
                          tabIndex={0}>
                          <span className="">
                            <Icon iconName={SimplifiedMenuSwitcher(child.type) ?? ""} />
                          </span>
                          <h5 className="login ">{child?.title}</h5>
                        </div>
                      </li>
                      <Seperator className="seperator" aria-hidden="true" />
                      </>
                  ) : (
                    <></>
                  )}
                  {child?.type === "SWITCH_VIEWS" && child?.title ? (
                    <>
                      <li className="switcher-dropdown" key={key}>
                        <Anchor
                          href={child.link}
                          tabindex={0}
                          className="switcher-dropdown-list"
                          isExternal={child?.link_type ?? false}
                          icon={<Icon iconName={SimplifiedMenuSwitcher(child?.type) ?? ""} />}
                          noUnderline={true}
                          dataLayerOnClick={() => handleDataLayerNavigationHeader(child?.title)}>
                          <h5>{child?.title}</h5>
                        </Anchor>
                      </li>
                      <Seperator className="seperator" aria-hidden="true" />
                      </>
                  ) : (
                    <></>
                  )}

                  {/* added condition to check if microsites are enabled then do not show country switcher at level 0.And if micosites are not enable then show langauge switcher at all level */}
                  {(child?.type === "LANGUAGE" &&
                  child?.title &&
                  ((language_switcher && !micrositeConfig?.isMicrositeEnabled) ||
                  (micrositeConfig?.isMicrositeEnabled && micrositeConfig?.pageMenuLvl !== "0" && headerConfig?.field_disable_country_switcher === false))) ? (
                    <>
                      <li className="switcher-dropdown" key={key}>
                        <div
                          className="switcher-dropdown-list-button"
                          onClick={() => {
                            setProfileSwitcher(false)
                            handleLanguageSwitcher()
                          }}
                          role="button"
                          onKeyDown={(event) => {
                            toggleLoginPopUp(event)
                            handleDataLayerNavigationHeader(child?.title)
                          }}
                          tabIndex={0}>
                          <span className="">
                            <Icon iconName={SimplifiedMenuSwitcher(child?.type)} />
                          </span>
                          <h5 className="login">{child?.title}</h5>
                        </div>
                      </li>
                      <Seperator className="seperator" aria-hidden="true" />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
            {userData?.loginStatus &&
              topSection?.userMenuDetails?.ctaMenuLink?.length &&
              topSection?.userMenuDetails?.ctaMenuLink.map((list: UserMenuType, index: number) => {
                if (list.title) {
                  // if configured link is global site Url then append current site app_id on link
                  const redirectionLink = list?.isGlobalLink
                    ? getUrlWithParams(list.link)
                    : list.link
                  return (
                    <div key={index}>
                      <li  className="switcher-dropdown">
                        <Anchor
                          className="switcher-dropdown-profile"
                          href={redirectionLink}
                          tabindex={index + 1}
                          noUnderline
                          isExternal={list.link_type}
                          aria-label={list.title}
                          icon={<Icon iconName={"simplifiedMenuProfile"} />}>
                          <h5 className="profile-option ">{list.title}</h5>
                        </Anchor>
                      </li>
                      <Seperator className="divider" aria-hidden="true" />
                    </div>
                  )
                }
                return <></>
              })}
            {login_register_enable && userData?.loginStatus ? (
              <li className="switcher-dropdown">
                <Button
                  className="mb-0 logout-btn"
                  tabindex={0}
                  isSecondary={false}
                  onClick={makeUserLogout}
                  aria-label={topSection?.userMenuDetails?.user_menu_logged_out}>
                  <h5 className="user-menu-logoff">
                    {topSection?.userMenuDetails?.user_menu_logged_out}
                  </h5>
                </Button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        )}
      </div>
      <div
        className="dropdown-background"
        onClick={() => setProfileSwitcher(false)}
        aria-hidden="true"
      />
    </>
  )
  const getBrandSwitcher = (details: any) => {
    return (
      <>
        {details && details?.length > 0 && (
          <ul className="switcher">
            {details?.map((child: ISwitcherItem, key: number) => (
              <>
                <li className="switcher-dropdown" key={key}>
                  {child?.uri && (
                    <Anchor
                      href={child?.uri}
                      tabindex={0}
                      className="switcher-dropdown-list"
                      isExternal={child?.link_type ?? false}>
                      <h5 className="profile-option">{child?.title}</h5>
                    </Anchor>
                  )}
                </li>
                <Seperator className="seperator" />
              </>
            ))}
          </ul>
        )}
        <div
          className="dropdown-background"
          onClick={() => setBrandSwitcher(false)}
          aria-hidden="true"
        />
      </>
    )
  }

  const toggleLoginPopUp = (event: any) => {
    if (event.key === "Enter") {
      setLoginPopupShow(!loginPopupShow)
    }
  }
  const resetMobileMenu = () => {
    if (refMenuButton.current) {
      refMenuButton.current.className = "navbar-toggler collapsed"
    }
    if (refMenu.current) {
      refMenu.current.className = "collapse"
    }
    setMenuOpen(false)
    setProfileSwitcher(false)
    clickOutsideMenuDone = false
    clickOutsideMenuButtonDone = false
  }

  const handleUserMenu = () => {
    setProfileSwitcher(!profileSwitcher)
    setBrandSwitcher(false)
    setMenuOpen(false)
  }

  const handleSwitchMenu = () => {
    setBrandSwitcher(!brandSwitcher)
    setProfileSwitcher(false)
    setMenuOpen(false)
  }
  const handleSearch = () => {
    setIsSearchOpen(true)
    setProfileSwitcher(false)
  }

  const makeUserLogout = async () => {
    setProfileSwitcher(false)
    setShowIndicator(true)
    localStorage.removeItem("Key")
    if (isDocCheckUser(user)) {
      deleteUserFromStore({})
      clearUserSessionData()
    } else {
      const logoutUrl = applicationConfiguration?.isSandozIDEnabled
        ? config.onPrem.LOGOUT_API_URL_SANDOZ_ID
        : config.onPrem.LOGOUT_API_URL
      const data = applicationConfiguration?.isSandozIDEnabled ? { app_id: getCurrentAppId() } : {}
      const response = await middlewarePostAPI(logoutUrl, data)
      deleteUserFromStore(response)
      clearUserSessionData()
      if (!response.fail && applicationConfiguration?.isSandozIDEnabled) {
        let redirectUrl = new URL(window.location.href)
        if (applicationConfiguration?.isGlobalSite) {
          // clearing app_id from store on logout for global site
          updateRefAppID("")
          sessionStorage.setItem("redirection", JSON.stringify(0))
          redirectUrl = appData?.feUrl
            ? new URL(`${window.location.protocol}//${appData.feUrl}`)
            : redirectUrl
        }
        redirectUrl.searchParams.delete("tid")
        redirectUrl.searchParams.delete("status_code")
        const azureLogoutUrl = `${applicationConfiguration?.sandozLogoutUrl}?post_logout_redirect_uri=${redirectUrl}`
        window.location.href = azureLogoutUrl
      }
    }

    setShowIndicator(false)
    updateReloadStatus(false)
    const userName = user?.user?.response?.session_data?.user_data?.current_user?.name

    const trackLogOutEvent = {
      user_id: encodeString(userName),
    }

    // reset user globally
    resetUserGlobally(false, "")

    // GTM
    dataLayerPush(EVENTS.LOGOUT, trackLogOutEvent)
    setToastMessage(`<p>${topSection.toastMessages.logoutMessage}</p>`)
    setTimeout(() => {
      router.push("/", undefined, { shallow: true }).then(() => router.reload()) // reload after closing toast message
    }, 10000)
    setTimeout(() => {
      setToastMessage("")
    }, 5000)
  }

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  const getHeaderLogo = () => (
    <div className="header-logo">
      <Anchor
        tabindex={0}
        href={getHomepageurl()}
        dataLayerOnClick={() => handleDataLayerNavigationHeader("logo")}
        className="navbar-brand">
        <Img src={topSection?.logo?.src} width={154} height={24} alt={topSection?.logo?.alt} type="logo" />
      </Anchor>
    </div>
  )

  const handleMouseEnter = () => {
    setIsMenuOpen(true)
  }
  const handleMouseLeave = () => {
    setIsMenuOpen(false)
  }

  const desktopMenu = () => {
    return (
      <ul className="navbar-nav parent">
        {megaMenu?.map((parent: any, _key: number) => (
          <li
            className="header-list-item parent-list"
            key={_key}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="simplifiedMenu-header-menu">
              <Anchor
                className="dropdown-item-parent"
                href={parent?.link}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                data-bs-toggle="dropdown"
                tabindex={0}
                dataLayerOnClick={() => handleDataLayerNavigationMenu(parent.title)}>
                {parent?.title}
              </Anchor>
            </div>
            {parent?.child?.length > 0 ? (
              <ul className={"dropdown-main"}>
                {parent?.child?.map((child: any, _keyChild: number) => (
                  <li className={"parent-list-child"} key={_keyChild}>
                    <Anchor
                      className="dropdown-item"
                      href={child?.link}
                      tabindex={0}
                      key={_keyChild}>
                      <p className="dropdown-item-text">{child?.title}</p>
                    </Anchor>
                    <Seperator className="seperator" />
                    {child?.subChild?.length ? (
                      <ul className={`${child?.subChild?.length ? "dropdown-subchild" : ""}`}>
                        {child?.subChild?.map((subChild: any, index: number) => {
                          return (
                            <li
                              key={index}
                              className={`subchild-list ${
                                subChild?.grandChild?.length > 0 ? " dropdown-subchild-list" : ""
                              }`}>
                              <Anchor
                                className="dropdown-item"
                                href={subChild?.link}
                                tabindex={0}
                                key={_keyChild}>
                                <p className="dropdown-item-text">{subChild?.title}</p>
                              </Anchor>
                              <Seperator className="seperator" />
                              {subChild?.grandChild?.length ? (
                                <ul
                                  className={`${
                                    subChild?.grandChild?.length ? "dropdown-grandchild" : ""
                                  }`}>
                                  {subChild?.grandChild?.map(
                                    (grandChild: any, grandIndex: number) => {
                                      return (
                                        <li key={grandIndex} className={"grandchild-list"}>
                                          <Anchor
                                            className="dropdown-item"
                                            href={grandChild?.link}
                                            tabindex={0}
                                            key={grandIndex}>
                                            <p className="dropdown-item-text">
                                              {grandChild?.title}
                                            </p>
                                          </Anchor>
                                        </li>
                                      )
                                    },
                                  )}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <></>
            )}
          </li>
        ))}
      </ul>
    )
  }
  const tabletMobileMenu = () => {
    return (
      <div className="accordion accordionFlush" id="accordionFlushHeaderMobile">
        {megaMenu?.map((menu_item: any, key: number) => (
          <div className="accordion-item" key={key} data-theme-hover={menu_item?.subTheme}>
            <div className="accordion-header" id={`flush-heading_${key}`}>
              {menu_item?.child ? (
                <>
                  <Anchor
                    tabindex={0}
                    noUnderline={true}
                    href={menu_item.link}
                    dataLayerOnClick={() => handleDataLayerNavigationMenu(menu_item?.title)}>
                    {menu_item?.title}
                  </Anchor>
                  <button
                    className="accordion-button collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse_${key}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse_${key}`}>
                    <p className="menuIndicator">
                      <Icon iconName="ChevronRightBlue" width={24} height={24} className="ml-2" />
                    </p>
                  </button>
                </>
              ) : (
                <Anchor
                  tabindex={0}
                  noUnderline={true}
                  href={menu_item?.link}
                  className=" collapsed menu-link"
                  dataLayerOnClick={() => handleDataLayerNavigationMenu(menu_item?.title)}>
                  {menu_item?.title}
                </Anchor>
              )}
            </div>
            <div
              id={`flush-collapse_${key}`}
              className="accordion-collapse collapse"
              aria-labelledby={`flush-heading_${key}`}
              data-bs-parent="#accordionFlushHeaderMobile">
              <div className="accordion-body child">
                <div
                  className={`${"accordion accordion-flush menu-child"}`}
                  id={`accordionFlush${key}`}>
                  <div className="accordion-item">
                    <div className="accordion-header remove-for-now">
                      <Anchor
                        tabindex={0}
                        noUnderline={true}
                        href={menu_item?.link}
                        className={`${"accordion-button collapsed menu-link menu-head"} `}
                        dataLayerOnClick={() => handleDataLayerNavigationMenu(menu_item?.title)}>
                        {menu_item?.title}
                      </Anchor>
                    </div>
                  </div>
                  {menu_item?.child?.map((childItem: any, childKey: number) => (
                    <div
                      className="accordion-item"
                      key={childKey}
                      data-theme-hover={childItem?.subTheme}>
                      <div className="accordion-header" id={`flush-heading_${key}${childKey}`}>
                        {childItem?.subChild ? (
                          <>
                            <Anchor
                              href={childItem?.link}
                              tabindex={0}
                              noUnderline={true}
                              dataLayerOnClick={() =>
                                handleDataLayerNavigationMenu(menu_item?.title, childItem?.title)
                              }>
                              {childItem?.title}
                            </Anchor>
                            <button
                              className="accordion-button collapsed "
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapse_${key}${childKey}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapse_${key}${childKey}`}>
                              <p className="menuIndicator">
                                <Icon
                                  iconName="ChevronRightBlue"
                                  width={24}
                                  height={24}
                                  className="ml-2"
                                />
                              </p>
                            </button>
                          </>
                        ) : (
                          <Anchor
                            href={childItem?.link}
                            tabindex={0}
                            noUnderline={true}
                            className=" collapsed menu-link"
                            dataLayerOnClick={() =>
                              handleDataLayerNavigationMenu(menu_item?.title, childItem?.title)
                            }>
                            {childItem?.title}
                          </Anchor>
                        )}
                      </div>

                      <div
                        id={`flush-collapse_${key}${childKey}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading_${key}${childKey}`}
                        data-bs-parent={`#accordionFlush${key}`}>
                        <div className="accordion-body subchild">
                          <div
                            className={`${"accordion accordion-flush menu-child"} `}
                            id={`accordionFlush${key}${childKey}`}>
                            <div className="accordion-item">
                              <div className="accordion-header remove-for-now">
                                <Anchor
                                  href={childItem?.link}
                                  tabindex={0}
                                  noUnderline={true}
                                  className="accordion-button collapsed menu-link"
                                  dataLayerOnClick={() =>
                                    handleDataLayerNavigationMenu(
                                      menu_item?.title,
                                      childItem?.title,
                                    )
                                  }>
                                  {childItem?.title}
                                </Anchor>
                              </div>
                            </div>
                            {childItem?.subChild?.map((subChildItem: any, subChildKey: number) => (
                              <div
                                className="accordion-item"
                                key={subChildKey}
                                data-theme-hover={subChildItem?.subTheme}>
                                <div
                                  className="accordion-header"
                                  id={`flush-heading_${key}${childKey}${subChildKey}`}>
                                  {subChildItem?.grandChild ? (
                                    <>
                                      <Anchor
                                        href={subChildItem?.link}
                                        tabindex={0}
                                        noUnderline={true}
                                        dataLayerOnClick={() =>
                                          handleDataLayerNavigationMenu(
                                            menu_item?.title,
                                            childItem?.title,
                                            subChildItem?.title,
                                          )
                                        }>
                                        {subChildItem?.title}
                                      </Anchor>
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#flush-collapse_${key}${childKey}${subChildKey}`}
                                        aria-expanded="false"
                                        aria-controls={`flush-collapse_${key}${childKey}${subChildKey}`}>
                                        <p className="menuIndicator">
                                          <Icon
                                            iconName="ChevronRightBlue"
                                            width={24}
                                            height={24}
                                            className="ml-2"
                                          />
                                        </p>
                                      </button>
                                    </>
                                  ) : (
                                    <Anchor
                                      href={subChildItem?.link}
                                      tabindex={0}
                                      noUnderline={true}
                                      className=" collapsed menu-link"
                                      dataLayerOnClick={() =>
                                        handleDataLayerNavigationMenu(
                                          menu_item?.title,
                                          childItem?.title,
                                          subChildItem?.title,
                                        )
                                      }>
                                      {subChildItem?.title}
                                    </Anchor>
                                  )}
                                </div>

                                <div
                                  id={`flush-collapse_${key}${childKey}${subChildKey}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`flush-heading_${key}${childKey}${subChildKey}`}
                                  data-bs-parent={`#accordionFlush${key}${childKey}`}>
                                  <div className="accordion-body grandSubchild">
                                    <div
                                      className="accordion accordion-flush"
                                      id={`accordionFlush${key}${childKey}${subChildKey}`}>
                                      <div className="accordion-item">
                                        <div className="accordion-header remove-for-now">
                                          <Anchor
                                            href={subChildItem?.link}
                                            tabindex={0}
                                            noUnderline={true}
                                            className="accordion-button collapsed menu-link menu-head"
                                            dataLayerOnClick={() =>
                                              handleDataLayerNavigationMenu(
                                                menu_item?.title,
                                                childItem?.title,
                                                subChildItem?.title,
                                              )
                                            }>
                                            {subChildItem?.title}
                                          </Anchor>
                                        </div>
                                      </div>
                                      {subChildItem?.grandChild?.map(
                                        (grandChildItem: any, grandChildKey: number) => (
                                          <div
                                            className="accordion-item"
                                            key={grandChildKey}
                                            data-theme-hover={subChildItem?.subTheme}>
                                            <div
                                              className="accordion-header"
                                              id={`flush-heading_${key}${childKey}${subChildKey}${grandChildKey}`}>
                                              {grandChildItem?.link == "#" ? (
                                                <button
                                                  className="accordion-button collapsed"
                                                  type="button">
                                                  {grandChildItem?.title}

                                                  <p className="menuIndicator">
                                                    <Icon
                                                      iconName="ChevronRightBlue"
                                                      width={24}
                                                      height={24}
                                                      className="ml-2"
                                                    />
                                                  </p>
                                                </button>
                                              ) : (
                                                <Anchor
                                                  href={grandChildItem?.link}
                                                  tabindex={0}
                                                  noUnderline={true}
                                                  className="collapsed menu-link"
                                                  dataLayerOnClick={() =>
                                                    handleDataLayerNavigationMenu(
                                                      menu_item?.title,
                                                      childItem?.title,
                                                      subChildItem?.title,
                                                      grandChildItem?.title,
                                                    )
                                                  }>
                                                  {grandChildItem?.title}
                                                </Anchor>
                                              )}
                                            </div>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const getUserNameDetails = (user: any) => {
    let userName
    if (isDocCheckUser(user)) {
      userName = applicationConfiguration?.doccheck_user_name
    } else {
      userName = getUserNames(user)?.firstName
    }

    if (userName?.length > 3 && isMobile) {
      return userName?.slice(0, 3) + "..."
    }
    return userName
  }

  return (
    <>
      <header
        className={`header simplified-header ${
          topSection?.sticky_menu_enable ? "sticky-header" : ""
        }`}>
        <Container>
          <div className="simplifiedMenu-header ">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div
                className={`${
                  megaMenu?.length > NUMBERS?.THREE
                    ? "simplifiedMenu-header-main"
                    : "simplifiedMenu-header-mainmenu"
                }`}>
                      {((!headerDisplayControl?.isLogoDisabled &&
                      !micrositeConfig?.isMicrositeEnabled) ||
                      (micrositeConfig?.isMicrositeEnabled && 
                        applicationComponentsData?.appComponents &&
                        headerConfig?.field_disable_logo === false)) &&
                      getHeaderLogo()}
                {!(isTablet || isMobile) && disable_megamenu === 0 && megaMenu?.length ? (
                  desktopMenu()
                ) : (
                  <div className="simplifiedMenu-nomenu" />
                )}
                {
                  <div className="ml-lg-auto ml-sm-0">
                    <ul className="simplifiedMenu-header-login" id="login">
                      <li className="divider" />

                      {((!headerDisplayControl?.isCartDisabled &&
                        applicationConfiguration?.isWebStoreEnabled &&
                        !micrositeConfig?.isMicrositeEnabled) ||
                        (micrositeConfig?.isMicrositeEnabled &&
                          !headerConfig?.field_disable_cart &&
                          applicationConfiguration?.isWebStoreEnabled)) &&
                        ((userData?.loginStatus && disable_cart === 0) ||
                          (showCartIcon && disable_cart === 0)) && (
                          <li className="right-section cart-item">
                            <div
                              className=""
                              tabIndex={0}
                              aria-hidden="true"
                              onClick={handleCartNavigation}
                              onKeyDown={handleCartRedirection}>
                              <Icon iconName="cartIcon" />
                              {webshop?.productsInCart?.length > 0 &&
                                (webshop?.productsInCart?.length < 10 ? (
                                  <span className="item-count">
                                    {webshop?.productsInCart?.length}
                                  </span>
                                ) : (
                                  <span className="item-count">{`${NUMBERS.TEN}+`}</span>
                                ))}
                            </div>
                          </li>
                        )}

                      <li>
                        {loginMenu?.length && (
                          <div
                            className="right-section"
                            onClick={handleUserMenu}
                            aria-expanded={true}
                            tabIndex={0}
                            role="button">
                            <span className="simplifiedMenu-header-login-profileIcon">
                              <Icon iconName={"simplifiedMenuProfileIcon"} />
                            </span>
                            {userData?.loginStatus ? (
                              <p className="mb-lg-0 ms-auto simplifiedMenu-header-login-username">
                                {getUserNameDetails(user)}
                              </p>
                            ) : (
                              <></>
                            )}
                            <span className="simplifiedMenu-header-login-chevronDown">
                              <Icon iconName="simplifiedMenuChevronDown" />
                            </span>
                          </div>
                        )}
                        {profileSwitcher && !isTablet && loginSwitcher(loginMenu)}
                      </li>

                      {micrositeConfig?.isMicrositeEnabled &&
                      brandSwitcherData?.options?.length &&
                      micrositeConfig?.menuStructure === "two_level" &&
                      micrositeConfig?.pageMenuLvl !== "0" &&
                      micrositeConfig?.pageMenuLvl !== "1" &&
                      headerConfig?.field_disable_brand_switcher === false ? (
                        <li>
                          <div
                            className="right-section"
                            onClick={handleSwitchMenu}
                            aria-expanded={true}
                            tabIndex={0}
                            role="button">
                            <span className="simplifiedMenu-header-login-profileIcon">
                              <Icon iconName={"simplifiedMenuBrandSwitcherIcon"} />
                            </span>
                            <span className="simplifiedMenu-header-login-chevronDown">
                              <Icon iconName="simplifiedMenuChevronDown" />
                            </span>
                          </div>
                          {brandSwitcher &&
                            !isTablet &&
                            getBrandSwitcher(brandSwitcherData?.options)}
                        </li>
                      ) : (
                        <></>
                      )}

                      {isTablet && disable_megamenu === 0 && megaMenu?.length !== 0 && (
                        <li>{rightHeaderSection}</li>
                      )}
                    </ul>
                  </div>
                }
              </div>
            </nav>
            {isTablet && menuOpen ? (
              <div className="show" id="navbarHeader" ref={navbarRef}>
                {tabletMobileMenu()}
              </div>
            ) : (
              <></>
            )}
            {loginMenu && profileSwitcher && isTablet && loginSwitcher(loginMenu)}
            {brandSwitcherData?.options &&
              brandSwitcher &&
              isTablet &&
              getBrandSwitcher(brandSwitcherData?.options)}
          </div>
        </Container>

        {!disable_search && isSearchOpen ? (
          <div ref={refSearch} className={"simplifiedHeader-search"}>
            <SearchBar
              isTypeAhead={true}
              //@ts-ignore
              searchContent={searchBarConfiguration}
              className="header-modal"
              showIconOnCTA={true}
              setIsSearchOpen={setIsSearchOpen}
              displayClose={true}
            />
          </div>
        ) : (
          <></>
        )}
        {language_switcher ? (
          <div className="simplifiedHeader-language-switcher">
            <div
              className="header-langswitcher-popup"
              ref={isMobile ? refLanguageSwitcher : refLanguageSwitcherButton}>
              <LanguageSwitcherPopup
                {...languageList}
                loading={false}
                display={isLangSwitcherActive}
                setIsLangSwitcherActive={setIsLangSwitcherActive}
                displayClose={true}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </header>

      {!headerDisplayControl?.isLoginRegisterDisabled ? (
        <LoginPopup
          showPopup={loginPopupShow}
          id="login-popup"
          form={loginPopup?.form}
          bannerCTA={loginPopup?.bannerCTA}
          handlePopupShow={(popupShow) => {
            resetRating({ id: undefined })
            setLoginPopupShow(popupShow)
          }}
          handleForgotPasswordShow={(popupShow: boolean) => setForgotPasswordShow(popupShow)}
          popup={loginPopup?.popup}
          loginConfirmation={() => setShowLoginToast(true)}
        />
      ) : (
        <></>
      )}
      {language_switcher ? (
        <div className="simplifiedHeader-language-switcher-nonsticky">
          <div
            className="header-langswitcher-popup"
            ref={isMobile ? refLanguageSwitcher : refLanguageSwitcherButton}>
            <LanguageSwitcherPopup
              {...languageList}
              loading={false}
              display={isLangSwitcherActive}
              setIsLangSwitcherActive={setIsLangSwitcherActive}
              displayClose={true}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {!!toastMessage ? (
        <Notification
          notificationType="success"
          displayTxt={toastMessage}
          onClose={() => {
            setToastMessage("")
          }}
        />
      ) : (
        <></>
      )}
      {showIndicator && overlayElement()}
      {!disable_search && isSearchOpen && topSection?.sticky_menu_enable ? (
        <div ref={refSearch} className={"simplifiedHeader-search-nonsticky"}>
          <SearchBar
            isTypeAhead={true}
            //@ts-ignore
            searchContent={searchBarConfiguration}
            className="header-modal"
            showIconOnCTA={true}
            setIsSearchOpen={setIsSearchOpen}
            displayClose={true}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default SimplifiedHeader
