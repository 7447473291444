import getNestedObject from "@utils/nestedObjects"
import { filterURL, getImageMeta } from "@helpers/dataFunctions"
import { getApi, getApiDomainAndLang } from "@utils/baseApi"
import NUMBERS from "@helpers/constants/numbers"
import { URL_TARGET } from "@helpers/constants/generic"
import moment from "moment"
import { getAssetPrefixUrl } from "@utils/helper"
import { hexalSubThemeConfig } from "@utils/hexalSubThemeUtil"
export const getHeaderMegaMenu = async (serverData?: any, preFetchedData?: any, siteConfig?: any) => await renderMegaMenu(serverData, preFetchedData, siteConfig)
// export const getHeaderLightMenu = async () => await renderLightMenu()

// export const getHeaderData = async () => await renderTopHeader()

// export const getFloatingModuleData = async () => await renderFloatingModule()

// export const getEyebrowMenuData = async () => await renderEyebrowMenu()

// export const getFloatingBannerData = async () => await renderFloatingBanner()

const _filterParentID = (parentId: string) =>
  parentId.replace("menu_link_content:", "") ? parentId.replace("menu_link_content:", "") : null

export const getFooterMenu = async (siteConfig?: any) => await renderFooterMainMenu(siteConfig)

export const getFooterSecondaryMenu = async (siteConfig?: any) => await renderFooterSecondaryMenu(siteConfig)

// export const getFooterBottomSection = async () => await renderFooterBottomSection()

// export const getFooterMicrosite = async () => await renderFooterMicrosite()

// export const getLiteFooterData = async () => await renderLiteFooterData()

export const getCookieBannerUuid = async () => {
  const rawData = await getApi("/jsonapi/one_trust_policy/one_trust_policy")
  if (!rawData) {
    return null
  }

  const uuidKey = rawData?.data?.[0]?.attributes["guid"]
  return {
    uuid: uuidKey || null,
  }
}

const getMenuSort = (menu: any) =>
  menu
    ? menu.sort((a: { weight: any }, b: { weight: any }) => Number(a.weight) - Number(b.weight))
    : null

export const renderMegaMenu = async (serverData?: any, preFetchedData?: any, siteConfig?: any) => {
  let rawMenuData = preFetchedData ? preFetchedData : await getApi(
    "/jsonapi/menu_link_content/main?page%5Boffset%5D=0&page%5Blimit%5D=200&filter[enabled][value]=1",
    false,
    serverData,
  )
  const subTheme = rawMenuData?.included?.filter(
    (items: any) => items.type === "taxonomy_term--microsites",
  )
  if (!preFetchedData) rawMenuData = rawMenuData?.data
 
  if(siteConfig?.enable_multilingual_menu) {
    const { lang } = getApiDomainAndLang()
    rawMenuData = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.langcode === lang)
  }
  const menus: any = []
  const rawMainMenu = rawMenuData?.filter((rawMenu: any) => !rawMenu?.attributes?.parent) || []
  const rawSubMenus = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.parent) || []
  // main menu

  for (const menu of rawMainMenu) {
    const products: any = []
    const productCount = [1, 2]
    await Promise.all(
      productCount.map(async (index: number) => {
        if (menu?.attributes?.[`field_product_${index}_body`]) {
          const { imageUrl, alt, imageStyledUrl } = getImageMeta(
            menu?.relationships?.[`field_product_${index}_image`],
          )

          products.push({
            imageLink: imageUrl,
            styledimage: imageStyledUrl ?? null,
            title: menu?.attributes?.[`field_product_${index}_title`]?.value,
            paragraph: menu?.attributes?.[`field_product_${index}_body`]?.processed,
            redirectLink: filterURL(menu?.attributes?.[`field_product_${index}_link`]?.uri),
            alt,
          })
        }
      }),
    )

    if (menu?.attributes?.enabled) {
      let subThemeValue = subTheme?.map((data: any) => {
        if (
          data?.attributes?.drupal_internal__tid ===
          menu?.relationships?.field_sub_theme?.data?.meta?.drupal_internal__target_id
        ) {
          return data?.attributes?.name
        }
      })
      subThemeValue = subThemeValue?.filter((item: string) => item)
      // @ts-ignore
      const hexalSubTheme = hexalSubThemeConfig?.[subThemeValue?.[0]] ?? ""
      menus.push({
        id: menu?.id,
        title: menu?.attributes?.title,
        link: menu?.attributes?.link_override?.uri
          ? filterURL(menu?.attributes?.link_override?.uri)
          : filterURL(menu?.attributes?.link?.uri),
        external: menu?.attributes?.external,
        menuImageData: products,
        weight: menu?.attributes?.weight,
        targetType: menu?.attributes?.field_select_target,
        subTheme: hexalSubTheme,
        distinctive: menu?.attributes?.field_show_distinctive,
        icon_uri : getNestedObject(menu,"relationships.field_distinctive_menu_icon.data.meta.absolute_uri")
      })
    }
    // sorting in ascending order
    menus.sort((a: { weight: any }, b: { weight: any }) => Number(a.weight) - Number(b.weight))
  }

  // child
  for (const rawMenu of rawSubMenus) {
    if (rawMenu?.attributes?.enabled) {
      menus.forEach(async (firstNewMenu: any, firstMenuindex: number) => {
        let hexalSubThemeChildValue = subTheme?.map((data: any) => {
          if (
            data?.attributes?.drupal_internal__tid ===
            rawMenu?.relationships.field_sub_theme?.data?.meta?.drupal_internal__target_id
          ) {
            return data.attributes.name
          }
        })
        hexalSubThemeChildValue = hexalSubThemeChildValue?.filter((item: string) => item)
        // @ts-ignore
        const hexalSubThemeChild = hexalSubThemeConfig?.[hexalSubThemeChildValue?.[0]] ?? ""
        if (_filterParentID(rawMenu?.attributes?.parent) === firstNewMenu?.id) {
          if (menus[firstMenuindex]["child"] === undefined) {
            menus[firstMenuindex]["child"] = []
          }
          menus[firstMenuindex]["child"].push({
            id: rawMenu?.id,
            parent: _filterParentID(rawMenu?.attributes?.parent),
            title: rawMenu?.attributes?.title,
            link: rawMenu?.attributes?.link_override?.uri
              ? filterURL(rawMenu?.attributes?.link_override?.uri)
              : filterURL(rawMenu?.attributes?.link?.uri),
            external: false,
            weight: rawMenu.attributes.weight,
            targetType: rawMenu?.attributes?.field_select_target,
            // @ts-ignore
            subTheme: hexalSubThemeChild,
            distinctive: rawMenu?.attributes?.field_show_distinctive,
            icon_uri : getNestedObject(rawMenu,"relationships.field_distinctive_menu_icon.data.meta.absolute_uri")
          })
        }
        // sorting in ascending order
        getMenuSort(menus[firstMenuindex]?.child)
        // menus[firstMenuindex]?.child
        //   ? menus[firstMenuindex].child.sort(
        //       (a: { weight: any }, b: { weight: any }) => Number(a.weight) - Number(b.weight),
        //     )
        // : null
      })
    }
  }

  // subchild
  for (const rawMenu of rawSubMenus) {
    if (rawMenu?.attributes?.enabled) {
      await menus.forEach(async (secondNewMenu: any, SecondMenuindex: number) => {
        if (menus[SecondMenuindex]["child"]) {
          const childs = menus[SecondMenuindex]["child"]
          childs.forEach((child: any, childIndex: number) => {
            let hexalSubThemeChildValue = subTheme?.map((data: any) => {
              if (
                data?.attributes?.drupal_internal__tid ===
                rawMenu?.relationships.field_sub_theme?.data?.meta?.drupal_internal__target_id
              ) {
                return data.attributes.name
              }
            })
            hexalSubThemeChildValue = hexalSubThemeChildValue?.filter((item: string) => item)
            // @ts-ignore
            const hexalSubThemeChild = hexalSubThemeConfig?.[hexalSubThemeChildValue?.[0]] ?? ""
            if (_filterParentID(rawMenu?.attributes?.parent) === child?.id) {
              if (menus[SecondMenuindex]["child"][childIndex]["subChild"] === undefined) {
                menus[SecondMenuindex]["child"][childIndex]["subChild"] = []
              }
              menus[SecondMenuindex]["child"][childIndex]["subChild"].push({
                id: rawMenu?.id,
                parent: _filterParentID(rawMenu?.attributes?.parent),
                title: rawMenu?.attributes?.title,
                link: rawMenu?.attributes?.link_override?.uri
                  ? filterURL(rawMenu?.attributes?.link_override?.uri)
                  : filterURL(rawMenu?.attributes?.link?.uri),
                external: false,
                weight: rawMenu?.attributes?.weight,
                targetType: rawMenu?.attributes?.field_select_target,
                subTheme: hexalSubThemeChild,
                distinctive: rawMenu?.attributes?.field_show_distinctive,
                icon_uri : getNestedObject(rawMenu,"relationships.field_distinctive_menu_icon.data.meta.absolute_uri")
              })
            }
            // sorting in ascending order
            getMenuSort(menus[SecondMenuindex]?.child[childIndex]?.subChild)
            // menus[SecondMenuindex]?.child[childIndex]?.subChild
            //   ? menus[SecondMenuindex]?.child[childIndex]?.subChild.sort(
            //       (a: { weight: any }, b: { weight: any }) => Number(a.weight) - Number(b.weight),
            //     )
            //   : null
          })
        }
      })
    }
  }

  // grandchild
  for (const rawMenu of rawSubMenus) {
    if (rawMenu?.attributes?.enabled) {
      await menus.forEach(async (ThirdNewMenu: any, ThirdMenuindex: number) => {
        if (menus[ThirdMenuindex]["child"]) {
          const childs = menus[ThirdMenuindex]["child"]
          await childs.forEach(async (child: any, childIndex: number) => {
            if (childs[childIndex]["subChild"]) {
              const subChilds = childs[childIndex]["subChild"]
              await subChilds.forEach(async (subchild: any, subChildIndex: number) => {
                if (_filterParentID(rawMenu?.attributes?.parent) === subchild?.id) {
                  if (
                    menus[ThirdMenuindex]["child"][childIndex]["subChild"][subChildIndex][
                      "grandChild"
                    ] === undefined
                  ) {
                    menus[ThirdMenuindex]["child"][childIndex]["subChild"][subChildIndex][
                      "grandChild"
                    ] = []
                  }
                  menus[ThirdMenuindex]["child"][childIndex]["subChild"][subChildIndex][
                    "grandChild"
                  ].push({
                    id: rawMenu?.id,
                    parent: _filterParentID(rawMenu?.attributes?.parent),
                    title: rawMenu?.attributes?.title,
                    link: rawMenu?.attributes?.link_override?.uri
                      ? filterURL(rawMenu?.attributes?.link_override?.uri)
                      : filterURL(rawMenu?.attributes?.link?.uri),
                    external: false,
                    weight: rawMenu?.attributes?.weight,
                    targetType: rawMenu?.attributes?.field_select_target,
                    distinctive: rawMenu?.attributes?.field_show_distinctive,
                    icon_uri : getNestedObject(rawMenu,"relationships.field_distinctive_menu_icon.data.meta.absolute_uri")
                  })
                }
                // sorting in ascending order
                getMenuSort(
                  menus[ThirdMenuindex]?.child[childIndex]?.subChild[subChildIndex].grandChild,
                )
                // menus[ThirdMenuindex]?.child[childIndex]?.subChild[subChildIndex].grandChild
                //   ? menus[ThirdMenuindex]?.child[childIndex]?.subChild[
                //       subChildIndex
                //     ].grandChild.sort(
                //       (a: { weight: any }, b: { weight: any }) =>
                //         Number(a.weight) - Number(b.weight),
                //     )
                //   : null
              })
            }
          })
        }
      })
    }
  }

  return menus || null
}
export const getHeaderLightMenu = async (siteConfig?: any) => {
  let rawMenuData = await getApi("/jsonapi/menu_link_content/sticky-menu")
  rawMenuData = rawMenuData?.data
  if(siteConfig?.enable_multilingual_menu) {
    const { lang } = getApiDomainAndLang()
    rawMenuData = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.langcode === lang)
  }
  const stickyHeaderMenuData: any = {}
  const menus: any = []
  const rawMainMenu = rawMenuData?.filter((rawMenu: any) => !rawMenu?.data) || []

  for (const menuData of rawMainMenu) {
    const childData: any = []
    const i = 0
    const j = 0
    if (menuData?.attributes?.enabled && menuData?.attributes?.parent === null) {
      for (const menuChildData of rawMainMenu) {
        if (
          menuChildData?.attributes?.enabled &&
          menuChildData?.attributes?.parent != null &&
          _filterParentID(menuChildData?.attributes?.parent) === menuData?.id
        ) {
          childData.push({
            title: menuChildData?.attributes?.title,
            link: menuChildData?.attributes?.link_override?.uri
              ? filterURL(menuChildData?.attributes?.link_override?.uri)
              : filterURL(menuChildData?.attributes?.link?.uri),
            key: i + 1,
            childKey: j + 1,
          })
        }
      }

      const createMenu: any = {
        title: menuData?.attributes?.title,
        link: menuData?.attributes?.link_override?.uri
          ? filterURL(menuData?.attributes?.link_override?.uri)
          : filterURL(menuData?.attributes?.link?.uri),
      }
      if (childData.length > 0) {
        createMenu.child = childData
      }
      menus.push(createMenu)
    }
  }
  stickyHeaderMenuData.megaMenu = menus

  return (
    {
      topSection: stickyHeaderMenuData?.topSection ? stickyHeaderMenuData.topSection : null,
      megaMenu: stickyHeaderMenuData?.megaMenu ? stickyHeaderMenuData.megaMenu : null,
    } || null
  )
}
export const getMenuLink = (data: any) => {
  if (data?.length > 0) {
    const content = data?.map((val: any) => ({
      title: val.title,
      link: val.link_type === "internalLink" ? val.int_link : val.ext_link,
      link_type: val.link_type !== "internalLink",
      weight: val.weight,
      isGlobalLink: val?.is_global_myprofile === 1 || false,
      appendRedirectUrl: val?.is_return_url === 1 || false,
    }))
    return content?.sort(
      (a: { weight: any }, b: { weight: any }) => Number(a.weight) - Number(b.weight),
    )
  }
  return null
}

export const getFlashMessage = (siteConfig: any, title: string): any | null => {
  const flashMessages = siteConfig?.flash_num_msg
  const filteredMessages = flashMessages?.filter((item: any) => item?.title === title) || null

  return filteredMessages?.[0]?.success_message || null
}

export const getFlashMessageWithType = (siteConfig: any, code: string): any | null => {
  const flashMessages = siteConfig?.error_codes
  const filteredMessage = flashMessages?.find((item: any) => item?.error_message && item?.error_code === code) || null

  return filteredMessage
}

export const getHeaderData = (siteConfig: any = {}) => {
  if (!siteConfig) {
    return {}
  }

  const loginMessage = getFlashMessage(siteConfig, "Login message")
  const logoutMessage = getFlashMessage(siteConfig, "Logout message")

  return {
    themeName: siteConfig?.style_type,
    countryName: siteConfig?.country_name,
    chooseLocationText: siteConfig?.language_switcher_title,
    tagLine: siteConfig?.header_description?.value || null,
    login_register_enable: siteConfig?.login_register_enable === NUMBERS.ONE,
    loginText: siteConfig?.login_text || null,
    loginLink: siteConfig?.login_link || null,
    registerText: siteConfig?.register_text || null,
    registerLink: siteConfig?.register_link || null,
    logo: {
      href: siteConfig?.header_logo_link || null,
      src: siteConfig?.header_image_url || null,
      alt: siteConfig?.header_logo_alt || null,
    },
    searchDataContent: {
      placeholder: siteConfig?.search_help_text || null,
      buttonText: siteConfig?.search_title || null,
    },
    sticky_menu_enable: siteConfig?.sticky_menu_enable === NUMBERS.ONE,
    isBrandSwitcherDisabled: siteConfig?.disable_brand_switcher === 1,
    isCountrySwitcherDisabled: siteConfig?.disable_country_switcher === 1,
    userMenuDetails: {
      user_menu_logged_in: getNestedObject(siteConfig, "user_menu_logged_in"),
      ctaMenuLink: getMenuLink(getNestedObject(siteConfig, "user_menu_num")),
      user_menu_logged_out: getNestedObject(siteConfig, "user_menu_logged_out"),
    },
    toastMessages: {
      loginMessage,
      logoutMessage,
    },
  }
}

export const getEyebrowMenuData = (siteConfig: any) => {
  const eyebrowMenuRawData = siteConfig?.eyebrow_menu_data || null
  const enabled = siteConfig?.eyebrow_menu_enable_disable ?? 0
  if (!eyebrowMenuRawData) {
    return null
  }

  return {
    logo: eyebrowMenuRawData?.logo || null,
    links: eyebrowMenuRawData?.links || [],
    enabled,
  }
}

export const getFloatingModuleData = (siteConfig: any) => {
  return {
    title: getNestedObject(siteConfig, "isi_banner_title") || null,
    copyrightBottomText: getNestedObject(siteConfig, "isi_banner_copyright_text.value") || null,
    section1: {
      richText: getNestedObject(siteConfig, "isi_banner_indication_description.value") || null,
    },
    section2: {
      richText: getNestedObject(siteConfig, "isi_banner_warning_description.value") || null,
    },
    section3: {
      richText:
        getNestedObject(siteConfig, "isi_banner_warning_and_precautions_description.value") || null,
    },
    accordionData: [
      {
        title: getNestedObject(siteConfig, "isi_banner_accordion_title") || null,
        content: getNestedObject(siteConfig, "isi_banner_accordion_description.value") || null,
      },
    ],
    expandedCoverageNumber: Number(getNestedObject(siteConfig, "isi_banner_number")) || null,
    bannerType: getNestedObject(siteConfig, "banner_type") ?? null,
  }
}

export const getBannerModuleData = (siteConfig: any) => {
  return {
    title: getNestedObject(siteConfig, "legal_banner_sticky_text.value") || null,
    nonStickyTitle: getNestedObject(siteConfig, "legal_banner_non_sticky_text.value") || null,
    expandedCoverageNumber:
      Number(getNestedObject(siteConfig, "legal_banner_screen_ratio")) || null,
    legalBannerMobileCtaText:
      getNestedObject(siteConfig, "legal_banner_mobile_view_more_cta_txt") ?? null,
    bannerType: getNestedObject(siteConfig, "banner_type") ?? null,
    disclaimerBannerText: getNestedObject(siteConfig, "disclaimer_banner_text.value") ?? null,
    disclaimerBannerScreenOccupancy:
      getNestedObject(siteConfig, "disclaimer_banner_screen_occupancy") ?? null,
  }
}

export const getLoginFormData = async (siteConfig: any) => {
  if (!siteConfig) {
    return {}
  }

  const isDocCheckEnabled = Boolean(getNestedObject(siteConfig, "doccheck") || 0)

  const isScoutLoginEnabled = Boolean(
    getNestedObject(siteConfig, "disable_azure_scout_login_form") || 0,
  )

  const rawHeaderData = await getApi("/webform_rest/login/fields?_format=json")
  let docCheckLoginForm = null

  if (isDocCheckEnabled) {
    const docCheckFormData = await getApi("/webform_rest/doccheck_login/fields?_format=json")
    docCheckLoginForm = {
      isLoginFormDisabled: isDocCheckEnabled,
      title: getNestedObject(docCheckFormData, "login_with_doccheck_label.#title") || null,
      wrongCredentialsText:
        getNestedObject(rawHeaderData, "credential_mismatch.#message_message") || null,
      emailIncorrectFormat:
        getNestedObject(rawHeaderData, "email_incorrect_format.#message_message") || null,
      deactivatedUserMessage:
        getNestedObject(rawHeaderData, "deactivated_user_msg.#message_message") || null,
      passwordIncorrect:
        getNestedObject(rawHeaderData, "password_incorrect.#message_message") || null,
      forgotPassword: {
        text: getNestedObject(docCheckFormData, "forgot_password.#title") || null,
        url: getNestedObject(docCheckFormData, "forgot_password.#default_value") || null,
        underline: true,
      },
      registerLink: {
        text: getNestedObject(docCheckFormData, "register_link.#title") || null,
        url: getNestedObject(docCheckFormData, "register_link.#default_value") || null,
        underline: true,
        image: {
          url: getNestedObject(docCheckFormData, "image.#default_value") || null,
          alt: getNestedObject(docCheckFormData, "image.webform_id") || null,
        },
      },
      submitButton: {
        text: getNestedObject(docCheckFormData, "actions.#submit__label") || null,
        url: "#",
      },
      fields: [
        {
          type: "text",
          label: getNestedObject(docCheckFormData, "email.#title") || null,
          name: getNestedObject(docCheckFormData, "email.#admin_title") || null,
          placeholder: getNestedObject(docCheckFormData, "email.#placeholder") || null,
          required: true,
          helpText:
            getNestedObject(rawHeaderData, "email_incorrect_format.#message_message") || null,
        },
        {
          type: "password",
          name: getNestedObject(docCheckFormData, "password.#admin_title") || null,
          label: getNestedObject(docCheckFormData, "password.#title") || null,
          placeholder: getNestedObject(docCheckFormData, "password.#placeholder") || null,
          required: true,
          helpText: getNestedObject(rawHeaderData, "password_incorrect.#message_message") || null,
          icon: {
            iconOpen: getAssetPrefixUrl("/assets/icons/view.svg"),
            iconClose: getAssetPrefixUrl("/assets/icons/view_close.svg"),
          },
        },
      ],
    }
  }

  return {
    docCheckLoginForm,
    formLogin: {
      isLoginFormDisabled: !isScoutLoginEnabled,
      title: getNestedObject(rawHeaderData, "login.#title") || null,
      wrongCredentialsText:
        getNestedObject(rawHeaderData, "credential_mismatch.#message_message") || null,
      emailIncorrectFormat:
        getNestedObject(rawHeaderData, "email_incorrect_format.#message_message") || null,
      deactivatedUserMessage:
        getNestedObject(rawHeaderData, "deactivated_user_msg.#message_message") || null,
      passwordIncorrect:
        getNestedObject(rawHeaderData, "password_incorrect.#message_message") || null,
      forgotPassword: {
        text: getNestedObject(rawHeaderData, "forgot_password_.#title") || null,
        url: getNestedObject(rawHeaderData, "forgot_password_link.#default_value") || null,
        underline: false,
      },
      submitButton: {
        text: getNestedObject(rawHeaderData, "actions.#submit__label") || null,
        url: "#",
      },
      fields: [
        {
          type: "email",
          label: getNestedObject(rawHeaderData, "email.#title") || null,
          placeholder: getNestedObject(rawHeaderData, "email.#placeholder") || null,
          required: true,
          helpText:
            getNestedObject(rawHeaderData, "email_incorrect_format.#message_message") || null,
        },
        {
          type: "password",
          label: getNestedObject(rawHeaderData, "password.#title") || null,
          placeholder: getNestedObject(rawHeaderData, "password.#placeholder") || null,
          required: true,
          helpText: getNestedObject(rawHeaderData, "password_incorrect.#message_message") || null,
          icon: {
            iconOpen: getAssetPrefixUrl("/assets/icons/view.svg"),
            iconClose: getAssetPrefixUrl("/assets/icons/view_close.svg"),
          },
        },
      ],
    },
    banner: {
      banner: {
        heading: getNestedObject(rawHeaderData, "connect_with_us.#title") || null,
        content: getNestedObject(rawHeaderData, "register_now_text.#text") || null,
        buttonLabel: getNestedObject(rawHeaderData, "register_now.#title") || null,
        link: getNestedObject(rawHeaderData, "register_link.#default_value") || null,
        linkExternal: true,
      },
      articleBannerImage: {
        desktopImage: {
          src: getAssetPrefixUrl("/assets/images/Desktop_Quiz_Article_Teaser_Image.jpg") as string,
          alt: "Sandoz Logo Desktop",
          width: 452,
          height: 296,
        },
        mobileImage: {
          src: getAssetPrefixUrl("/assets/images/Desktop_Quiz_Article_Teaser_Image.jpg") as string,
          alt: "Sandoz Logo Mobile",
          width: 780,
          height: 700,
        },
      },
    },
  }
}

export const getFloatingBannerData = (siteConfig: any) => {
  return {
    children: siteConfig?.floating_banner_text?.value ?? null,
    display: Boolean(siteConfig?.display_on_site_level) ?? null,
  }
}

export const renderFooterMainMenu = async (siteConfig?: any, url?: string) => {
  let rawMenuData = await getApi(url ? url : "/jsonapi/menu_link_content/footer")
  rawMenuData = rawMenuData?.data
  if(siteConfig?.enable_multilingual_menu) {
    const { lang } = getApiDomainAndLang()
    rawMenuData = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.langcode === lang)
  }
  const menus: any = []
  const rawMainMenu = rawMenuData?.filter((rawMenu: any) => !rawMenu?.attributes?.parent)
  const rawSubMenus = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.parent)

  rawMainMenu?.map((menu: any) => {
    if (menu?.attributes?.enabled) {
      menus.push({
        id: menu?.id,
        title: menu?.attributes?.title,
        link: menu?.attributes?.link_override?.uri
          ? filterURL(menu?.attributes?.link_override?.uri)
          : filterURL(menu?.attributes?.link?.uri),
        external: menu?.attributes?.external,
        weight: menu?.attributes?.weight,
        targetType: menu?.attributes?.field_select_target,
      })
    }
  })
  // sorting in ascending order
  getMenuSort(menus)

  rawSubMenus?.map((rawMenu: any) => {
    if (rawMenu?.attributes?.enabled) {
      menus.map((newMenu: any, menuindex: number) => {
        if (_filterParentID(rawMenu?.attributes?.parent) === newMenu?.id) {
          if (menus[menuindex]["child"] === undefined) {
            menus[menuindex]["child"] = []
          }
          menus[menuindex]["child"].push({
            id: rawMenu?.id,
            parent: _filterParentID(rawMenu?.attributes?.parent),
            title: rawMenu?.attributes?.title,
            link: rawMenu?.attributes?.link_override?.uri
              ? filterURL(rawMenu?.attributes?.link_override?.uri)
              : filterURL(rawMenu?.attributes?.link?.uri),
            external: false,
            weight: rawMenu.attributes.weight,
            targetType: rawMenu?.attributes?.field_select_target,
          })
          // sorting in ascending order
          getMenuSort(menus[menuindex]?.child)
        }
      })
    }
  })

  return menus || null
}

export const renderMicroFooterMainMenu = (rawMenuData?: any) => {
  let menus: any = []

  if (Array.isArray(rawMenuData)) {
    rawMenuData.forEach((menu: any) => {
      if (menu.child && menu.child.length > 0) {
        menus = processChildMenus(menu.child, menus)
      }
    })
  }

  return menus || null
}

const processChildMenus = (childMenus: any, menus: any) => {
  childMenus.forEach((childMenu: any) => {
    const newMenu = {
      id: childMenu.title,
      title: childMenu.title,
      link: childMenu.link.uri,
      external: childMenu.external,
      child:[]
    }

    menus.push(newMenu)

    if (childMenu.child && childMenu.child.length > 0) {
      newMenu.child = processChildMenus(childMenu.child, [])
    }
  })

  return menus
}

export const renderFooterSecondaryMenu = async (siteConfig?: any, url?: string) => {
  let rawMenuData = await getApi(url ? url : "/jsonapi/menu_link_content/footer-secondary")
  rawMenuData = rawMenuData?.data
 
  if(siteConfig?.enable_multilingual_menu) {
    const { lang } = getApiDomainAndLang()
    rawMenuData = rawMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.langcode === lang)
  }
  const menus: any = []

  rawMenuData?.map((secmenu: any) => {
    if (secmenu?.attributes?.enabled) {
      menus.push({
        id: secmenu?.id,
        title: secmenu?.attributes?.title,
        link: secmenu?.attributes?.link_override?.uri
          ? filterURL(secmenu?.attributes?.link_override?.uri)
          : filterURL(secmenu?.attributes?.link?.uri),
        external: secmenu?.attributes?.external,
        weight: secmenu?.attributes?.weight,
        targetType: secmenu?.attributes?.field_select_target,
      })
    }
  })
  // sorting in ascending order
  getMenuSort(menus)

  return menus || null
}

export const renderMicroFooterSecondaryMenu = (rawMenuData?: any) => {
  const menus: any = []

  const traverseMenu = (menu: any,level: number = 0) => {
    if (level > 1) return
    if (menu.child && Array.isArray(menu.child)) {
      menu.child.forEach((childMenu: any) => {
        menus.push({
          title: childMenu.title,
          link: childMenu.link?.uri ? filterURL(childMenu.link.uri) : null,
          external: childMenu.external,
        });
        if (childMenu.child && Array.isArray(childMenu.child)) {
          traverseMenu(childMenu ,level + 1)
        }
      })
    }
  }
  if (Array.isArray(rawMenuData)) {
    rawMenuData.forEach((menu: any) => {
      traverseMenu(menu)
    })
  }
  return menus.length > 0 ? menus : []
}

export const getFooterBottomSection = (siteConfig: any) => {
  const logos = ["twitter", "linkedin", "youtube", "facebook"]

  let logoData = logos.map((item: string) => {
    return {
      altText: siteConfig?.[`${item}_alt`] ?? "",
      link: siteConfig?.[`${item}_link`] ?? "",
      logo: siteConfig?.[`${item}_logo`] ?? [],
      logoUrl: siteConfig?.[`${item}_logo_url`] ?? "",
      title: siteConfig?.[`${item}_title`] ?? "",
    }
  })

  logoData = logoData.filter((item: any) => item.logoUrl !== "")

  return {
    logoLink: siteConfig?.footer_logo_url || null,
    paragraph: siteConfig?.footer_description?.value || null,
    copyRightText: siteConfig?.copyright?.value || null,
    footerText: siteConfig?.second_footer_description?.value || null,
    socialIconData: logoData,
    follow_us: siteConfig?.follow_us || "",
  }
}

export const getFooterMicrosite = (siteConfig: any) => {
  const microFooterFlag = siteConfig?.microsite_footer_menu_enable_disable === NUMBERS.ONE
  const microFooterTitle = siteConfig?.microsite_footer_title_text ?? ""
  const microlinkData = siteConfig?.microsite
  const microData: any = []
  microlinkData?.map((microdata: any) => {
    microData.push({
      imgSrc: microdata?.logo ?? "",
      alt: microdata?.alt_text ?? "",
      plainText: microdata?.plain_text ?? "",
      link: microdata?.url ?? "",
      height: microdata?.logo_height,
      width: microdata?.logo_width,
      urlTarget: microdata?.url_target ?? URL_TARGET.SELF,
    })
  })
  return {
    microFooterData: microData,
    microFooterFlag,
    microFooterTitle,
  }
}
export const getRenderHcpModalPopUp = (siteConfig: any) => {
  if (!siteConfig) {
    return {}
  }
  return {
    header: {
      titleText: siteConfig?.hcp_popup_title,
    },
    content: {
      content: siteConfig?.hcp_popup_description?.value,
      isRichText: true,
    },
    footer: {
      closeBtn: {
        tabindex: NUMBERS.TWO,
        children: siteConfig?.hcp_popup_secondary_link_title,
        isSecondary: true,
        href: siteConfig?.hcp_popup_secondary_link_url ?? "",
        target: siteConfig?.hcp_popup_secondary_link_target ?? "",
      },
      actionBtn: {
        tabindex: NUMBERS.ONE,
        children: siteConfig?.hcp_popup_primary_link_title,
      },
    },
    enabled: siteConfig?.hcp_popup_enable_disable,
  }
}
// external link disclaimer modal
export const getExternalLinkModalContent = (siteConfig: any) => {
  return {
    header: {
      titleText: getNestedObject(siteConfig, "ext_disclaimer_popup_title"),
    },
    content: {
      content: getNestedObject(siteConfig, "ext_disclaimer_popup_description.value"),
      isRichText: true,
    },
    footer: {
      closeBtn: {
        tabindex: NUMBERS.TWO,
        children: getNestedObject(siteConfig, "ext_disclaimer_popup_secondary_link_title"),
        isSecondary: true,
        href: "javascript:void(0);",
      },
      actionBtn: {
        tabindex: NUMBERS.ONE,
        children: getNestedObject(siteConfig, "ext_disclaimer_popup_primary_link_title"),
      },
    },
    whiteListed: getNestedObject(siteConfig, "ext_disclaimer_popup_whitelist.value"),
    enabled: getNestedObject(siteConfig, "ext_disclaimer_popup_enable_disable"),
  }
}
export const getLiteFooterData = async (siteConfig: any, url?: string) => {
  let liteFooterMenuData = await getApi(url ? url : "/jsonapi/menu_link_content/footer-lite")
  const liteFooterFlag = siteConfig?.footer_lite === NUMBERS.ONE
  const { lang } = getApiDomainAndLang()
  let today = new Date().toLocaleDateString(lang)
  today = moment(today).locale(lang).format("D.M.Y")
  const footerBottomSection = {
    footer_logo_link: filterURL(siteConfig?.footer_logo_link) || null,
    logoLink: siteConfig?.footer_logo_url || null,
    footer_logo_alt: siteConfig?.footer_logo_alt || "",
    paragraph: siteConfig?.footer_description?.value || null,
    copyRightText: siteConfig?.copyright?.value || null,
    footerText: siteConfig?.second_footer_description?.value || null,
    date: `<p>${today}</p>` || null,
  }
  liteFooterMenuData = liteFooterMenuData?.data
  if(siteConfig?.enable_multilingual_menu) {
    liteFooterMenuData = liteFooterMenuData?.filter((rawMenu: any) => rawMenu?.attributes?.langcode === lang)
  }
  const footerSecondaryMenu: any = []
  liteFooterMenuData?.map((item: any) => {
    footerSecondaryMenu.push({
      title: item?.attributes?.title ?? "",
      link: item?.attributes?.link_override?.uri
        ? filterURL(item?.attributes?.link_override?.uri)
        : filterURL(item?.attributes?.link?.uri),
      targetType: item?.attributes?.field_select_target,
    })
  })
  return {
    footerBottomSection,
    footerSecondaryMenu,
    liteFooterFlag,
  }
}

// external link disclaimer modal
export const getLoginTimeoutModalContent = (siteConfig: any) => {
  return {
    enabled: getNestedObject(siteConfig, "login_timeout_enable"),
    timeOutInterval: getNestedObject(siteConfig, "idle_logout_time"),
    header: {
      titleText: getNestedObject(siteConfig, "login_timeout_popup_title"),
    },
    content: {
      content: getNestedObject(siteConfig, "login_timeout_popup_countdown_text_above"),
      isRichText: true,
    },
    footer: {
      closeBtn: {
        tabindex: NUMBERS.TWO,
        children: getNestedObject(siteConfig, "login_timeout_popup_stay_login_cta_label"),
        isSecondary: true,
      },
      actionBtn: {
        tabindex: NUMBERS.ONE,
        children: getNestedObject(siteConfig, "login_timeout_popup_logout_cta_label"),
      },
    },
  }
}
