import { ApplicationData } from "@utils/application-context/applicationData"
import { useRouter } from "next/router"
import { useCallback } from "react"

const useSandozIDAuth = (applicationConfiguration: ApplicationData) => {
  const router = useRouter()

  const getCurrentAppId = useCallback(() => {
    const { sandozRedirectUrl = "", siteConfig } = applicationConfiguration
    let appId = siteConfig?.client_id || ""
    if (!appId) {
      const urlParams = new URLSearchParams(sandozRedirectUrl)
      appId = urlParams.get("client_id") || ""
    }
    return appId
  }, [applicationConfiguration])

  const getUrlWithParams = useCallback(
    (url: string) => {
      const appId = getCurrentAppId() || ""
      const returnUrl = appId ? `${url}?app_id=${appId}` : url
      return returnUrl
    },
    [applicationConfiguration],
  )

  const removeQueryParam = (reload = false) => {
    const params: any = router.query
    delete params.tid
    delete params.slug
    router.push(
      {
        pathname: window.location.pathname,
        query: params,
      },
      undefined,
      { shallow: !reload },
    )
  }

  const appendEncodedReturnUrl = (url: string) => {
    const currUrl = window.location.href
    const encodedUrl = btoa(currUrl)
    return `${url?.includes("?") ? url : url + "?"}&return_url=${encodedUrl}`
  }

  const handleLoginRedirection = useCallback(() => {
    const { isSandozIDEnabled = false, sandozRedirectUrl = "" } = applicationConfiguration
    if (isSandozIDEnabled && sandozRedirectUrl) {
      const loginUrl = appendEncodedReturnUrl(sandozRedirectUrl)
      window.location.href = loginUrl
    }
  }, [applicationConfiguration])

  return {
    handleLoginRedirection,
    removeQueryParam,
    getUrlWithParams,
    getCurrentAppId,
    appendEncodedReturnUrl,
  }
}

export default useSandozIDAuth
