import NUMBERS from "@helpers/constants/numbers"
import { useMediaQuery } from "@helpers/hooks"
import { Pagination, Tabs, TitleDescription } from "@molecules"
import NewsSearchResultGrouped from "@organisms/newsSearchResultGrouped"
import React, { useContext, useState } from "react"
import getDirection from "@utils/direction"
import { INewsSearchResultInterface } from "./_newsSearchResult.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const NewsSearchResult = ({
  newsCardData,
  newsSearchConfig,
  searchedPhrase,
  searchCount,
  groupedResult,
  itemsPerPage,
  handleSort,
}: INewsSearchResultInterface) => {
  const [paginatePages, setPaginatePages] = useState({ start: 0, end: itemsPerPage })
  const [resetOffset, setRestOffset] = useState(false)

  const isMobile = useMediaQuery("(max-width: 991px)")

  const newsLabels = {
    old_to_new_text: newsSearchConfig?.old_to_new_text,
    new_to_old_text: newsSearchConfig?.new_to_old_text,
    default_sort: newsSearchConfig?.default_news_sort,
    sort_cta_text: newsSearchConfig?.sort_cta_text,
  }

  const formatMatchString = () => {
    const matchObj = {
      news_count_val: String(newsCardData.length),
      news_search_txt: "",
    }
    return newsSearchConfig?.search_results_found
      ?.replaceAll("@", "")
      .replace(
        /news_count_val|news_search_txt/gi,
        (matched) => matchObj[matched as keyof typeof matchObj],
      )
  }

  const handlePagination = (offSet: number) => {
    if (offSet === 0) {
      setRestOffset(true)
    } else if (offSet !== 0 && resetOffset) {
      setRestOffset(false)
    }
    setPaginatePages({ start: offSet, end: offSet + itemsPerPage })
  }

  const getPagination = (
    itemsRestriction: number,
    resultLength: number,
    tabName: string,
    index: number,
  ) => (
    <>
      <hr className="separator" />
      <Pagination
        itemsPerPage={itemsRestriction}
        itemsCount={resultLength}
        onClick={handlePagination}
        resetOffset={resetOffset}
        className={`${tabName}-${index}`}
      />
    </>
  )

  const tabHeadItems = [
    {
      children: getDirection() === "rtl" ? `(${newsCardData.length.toString()}) ${newsSearchConfig?.all_category_text}` : `${newsSearchConfig?.all_category_text} (${newsCardData.length})`,
      itemID: `${newsSearchConfig?.all_category_text}`,
      tabDesign: "wide",
    },
  ].concat(
    groupedResult.map((item) => ({
      children: getDirection() === "rtl" ? `(${item.results.length.toString()}) ${item.category}` : `${item.category} (${item.results.length})`,
      itemID: item.category,
      tabDesign: "wide",
    })),
  )

  const getAllCardsView = () => (
    <div key={0}>
      <div>
        <NewsSearchResultGrouped
          newsList={newsCardData.slice(paginatePages.start, paginatePages.end)}
        />
      </div>
      {newsCardData.length > itemsPerPage
        && getPagination(itemsPerPage, newsCardData.length, "All", NUMBERS.ZERO)}
    </div>
  )

  const getSearchResultsView = () =>
    groupedResult.map((item, index) => (
      <div key={index + 1}>
        <div key={index}>
          <NewsSearchResultGrouped
            newsList={item.results.slice(paginatePages.start, paginatePages.end)}
          />
        </div>
        {item.results.length > itemsPerPage
          && getPagination(itemsPerPage, item.results.length, item.category, index + NUMBERS.ONE)}
      </div>
    ))

  const tabContents = [getAllCardsView()].concat(getSearchResultsView())

  const { applicationConfiguration } = useContext(ApplicationContext)
  const labelKeyValuePair = applicationConfiguration?.siteConfig?.label_key_value_pair

  return (
    <div className="news-search-result scroll-top">
      {searchedPhrase && searchCount > NUMBERS.ZERO && (
        <TitleDescription
          title={formatMatchString() || ""}
          description=""
          searchKey=""
          className="news-search-result__header"
        />
      )}
      <Tabs
        tabHeadClassName="news-search-result__tabsContainer"
        tabHeadItems={tabHeadItems}
        tabContents={tabContents}
        isMobileDisplay={!!isMobile}
        handleSort={handleSort}
        label={newsLabels}
        trigger={searchCount}
        hideViewTypeCTA={true}
        hideSortCTA={!newsSearchConfig?.show_sort_cta}
        handlePagination={handlePagination}
        labelKeyValuePair={labelKeyValuePair}
      />
    </div>
  )
}

export default NewsSearchResult
