/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { middlewarePostAPI } from "@utils/baseApi"
import { resetUser } from "store/actions/ActionCreators"
import { config } from "@utils/baseApi/config.external"
import { useOutsideClick } from "@helpers/hooks/useOutsideClick"
import useElementSize from "@helpers/hooks/useElementSize"
import { Container, Icon, Img, LanguageSwitch, Row } from "@atoms"
import { FloatingBanner, HeaderMenu, MenuImage, Notification } from "@molecules"
import ConfigurationsContext from "@utils/ConfigurationsContext"
import {
  handleDataLayerNavigationHeader,
  handleDataLayerNavigationMenu,
} from "@utils/gtmUtilsHelpers"
import NUMBERS from "@helpers/constants/numbers"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import { encodeString } from "@utils/sha256"
import dynamic from "next/dynamic"
import { getAssetPrefixUrl, getHomepageurl, isDocCheckUser } from "@utils/helper"
import ApplicationContext from "@utils/application-context/applicationContext"
import { UserMenu } from "@organisms"
import { IHeaderProps } from "./_rebrandingHeader.interface"
import {
  IHeaderChildProps,
  IHeaderGrandChildProps,
  IHeaderMenuProps,
  IHeaderSubChildProps,
} from "../../molecules/headerMenu/_headerMenu.interface"
import { IMenuImageProps } from "../../molecules/menuImage/_menuImage.interface"
import SearchBar from "../searchBar"
import LanguageSwitcherPopup from "../languageSwitcherPopup"
import LoginPopup from "../loginPopup"
import { setRefApplicationID, setUserStateGlobally, updateReloadContent } from "store/actions/CommonActionCreators"
import { useMediaQuery } from "@helpers/hooks"
import Loader from "@atoms/loader"
import { resetRatings } from "store/actions/RatingsActionCreators"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import useUserSessionControl from "@helpers/hooks/userSessionControlHook/useUserSession"

const Anchor = dynamic(() => import("../../atoms/anchor"), { ssr: false })
const EyebrowMenu = dynamic(() => import("../eyebrowMenu"), { ssr: false })
const BrandSwitcher = dynamic(() => import("../../atoms/brandSwitcher"), { ssr: false })

const RebrandingHeader = ({
  loading,
  topSection,
  languageSelected,
  userData,
  languageList,
  megaMenu,
  loginPopup,
  floatingBanner,
  eyebrowMenu,
  onFloatingBannerClose,
  micrositeConfig,
  brandSwitcherData,
  headerConfig,
}: IHeaderProps | any) => {
  const [isLangSwitcherActive, setIsLangSwitcherActive] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [loginPopupShow, setLoginPopupShow] = useState(false)
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false)
  const [eyebrowMenuTopPosition, setEyebrowMenuTopPosition] = useState(0)
  const [toastMessage, setToastMessage] = useState<string>("")
  const [shouldShowBanner, setShouldShowBanner] = useState(true)
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [showLoginToast, setShowLoginToast] = useState(false)

  const { applicationConfiguration, applicationComponentsData } = useContext(ApplicationContext)
  const { handleLoginRedirection, getCurrentAppId, appendEncodedReturnUrl } = useSandozIDAuth(applicationConfiguration)

  const isTablet = useMediaQuery("(max-width: 991px)")
  const headerDisplayControl = applicationConfiguration?.headerConfiguration

  const headerLogoLink = topSection?.logo?.href

  const isMobile = useMediaQuery("(max-width: 767px)")
  const user: any = useSelector((state: UserState) => state.user, shallowEqual)
  const webshop: any = useSelector((state: any) => state.webshop)
  const appData: any = useSelector((state: any) => state?.common)

  const dispatch: Dispatch<any> = useDispatch()

  const [bannerRef, { height }] = useElementSize()

  const { clearUserSessionData } = useUserSessionControl()
  const handleLanguageSwitcher = () => {
    setIsLangSwitcherActive((current) => !current)
    handleDataLayerNavigationHeader("Language Switcher")
  }
  const menuImageProduct = megaMenu && megaMenu.length > 0 ? megaMenu[0].menuImageData : []

  // flag to show Cart Icon for non-loggedIn users
  const showCartIcon =
    !!applicationConfiguration?.siteConfig?.webstore_enable_non_loggedin_users &&
    webshop?.productsInCart?.length > 0

  const router = useRouter()
  let clickOutsideMenuDone = false
  let clickOutsideMenuButtonDone = false

  const updateRefAppID: any = useCallback(
    (value: any) => dispatch(setRefApplicationID(value)),
    [dispatch],
  )

  const updateReloadStatus: any = useCallback(
    (value: any) => dispatch(updateReloadContent(value)),
    [dispatch],
  )

  useEffect(() => {
    resetMobileMenu()
    setMenuOpen(false)
  }, [router.query])

  const toggleLoginPopUp = (event: any) => {
    if (event.key === "Enter") {
      setLoginPopupShow(!loginPopupShow)
    }
  }
  const handleTextSearch = (text: string, e?: MouseEvent) => {
    if (e) {
      e.preventDefault()
    }
    if (text.length > 0) {
      if (router.pathname === "/search") {
        router.push({ pathname: "/search", query: { search: `${text}` } }, undefined, {
          shallow: true,
        })
      } else {
        router.push({ pathname: "/search", query: { search: `${text}` } })
      }
    }
  }

  const resetMobileMenu = () => {
    if (refMenuButton.current) {
      refMenuButton.current.className = "navbar-toggler collapsed"
    }
    if (refMenu.current) {
      refMenu.current.className = "collapse"
    }
    setMenuOpen(false)
    clickOutsideMenuDone = false
    clickOutsideMenuButtonDone = false
  }

  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: "How can we help today?",
    },
    buttonText: "Search",
    handleTextSearch,
  }

  const setMobileMenuState = () => {
    if (clickOutsideMenuDone) {
      resetMobileMenu()
    }
  }

  const handleClickOutsideMenu = () => {
    clickOutsideMenuDone = true
    setMobileMenuState()
  }

  const refMenu = useOutsideClick(handleClickOutsideMenu)

  const handleClickOutsideMenuButton = () => {
    clickOutsideMenuButtonDone = true
    setMobileMenuState()
  }
  const refMenuButton = useOutsideClick(handleClickOutsideMenuButton)

  const handleClickOutsideSearch = () => {
    setIsSearchOpen(false)
  }
  const refSearch = useOutsideClick(handleClickOutsideSearch)

  let clickOutsideLanguageSwitcherDone = false
  let clickOutsideLanguageSwitcherMenuButtonDone = false

  const handleClickOutsideLanguageSwitcher = () => {
    clickOutsideLanguageSwitcherDone = true
    setLanguageSwitcherState()
  }
  const refLanguageSwitcher = useOutsideClick(handleClickOutsideLanguageSwitcher)

  const handleClickOutsideLanguageSwitcherButton = () => {
    clickOutsideLanguageSwitcherMenuButtonDone = true
    setLanguageSwitcherState()
  }
  const refLanguageSwitcherButton = useOutsideClick(handleClickOutsideLanguageSwitcherButton)

  const setLanguageSwitcherState = () => {
    setIsLangSwitcherActive(false)
    clickOutsideLanguageSwitcherDone = false
    clickOutsideLanguageSwitcherMenuButtonDone = false
  }

  const [displayFloatingBanner, setDisplayFloatingBanner] = useState(floatingBanner?.display)

  const cachedContext = useMemo(() => {
    const contextValue: any = {
      floatingBanner,
      eyebrowMenu,
      displayFloatingBanner,
      setDisplayFloatingBanner,
    }
    return contextValue
  }, [displayFloatingBanner])

  useEffect(() => {
    setShouldShowBanner(sessionStorage.getItem("showBanner") !== "false")
    if (displayFloatingBanner !== false) {
      return setEyebrowMenuTopPosition(height)
    }
    if (!displayFloatingBanner) {
      return setEyebrowMenuTopPosition(NUMBERS.ZERO)
    }
  }, [height, displayFloatingBanner])

  const deleteUserFromStore: any = useCallback(
    (value: any) => dispatch(resetUser(value)),
    [dispatch],
  )

  const resetUserGlobally: any = useCallback(
    (isLoggedIn: boolean, type: string) => dispatch(setUserStateGlobally(isLoggedIn, type)),
    [dispatch],
  )

  const resetRating: any = useCallback((value: any) => dispatch(resetRatings(value)), [dispatch])

  const makeUserLogout = async () => {
    setShowIndicator(true)
    localStorage.removeItem("Key")
    if (isDocCheckUser(user)) {
      deleteUserFromStore({})
      clearUserSessionData()
      // reset user globally
      resetUserGlobally(false, "")
    } else {
      const logoutUrl = applicationConfiguration?.isSandozIDEnabled
        ? config.onPrem.LOGOUT_API_URL_SANDOZ_ID
        : config.onPrem.LOGOUT_API_URL
      const data = applicationConfiguration?.isSandozIDEnabled ? { app_id: getCurrentAppId() } : {}
      const response = await middlewarePostAPI(logoutUrl, data)
      deleteUserFromStore(response)
      clearUserSessionData()
      if (!response.fail && applicationConfiguration?.isSandozIDEnabled) {
        let redirectUrl = new URL(window.location.href)
        if (applicationConfiguration?.isGlobalSite) {
          // clearing app_id from store on logout for global site
          updateRefAppID("")
          sessionStorage.setItem("redirection", JSON.stringify(0))
          redirectUrl = appData?.feUrl
            ? new URL(`${window.location.protocol}//${appData.feUrl}`)
            : redirectUrl
        }
        redirectUrl.searchParams.delete("tid")
        redirectUrl.searchParams.delete("status_code")
        const azureLogoutUrl = `${applicationConfiguration?.sandozLogoutUrl}?post_logout_redirect_uri=${redirectUrl}`
        window.location.href = azureLogoutUrl
      }
    }

    setShowIndicator(false)
    updateReloadStatus(false)
    const userdata = user?.user?.response?.session_data?.user_data?.current_user?.name

    const trackLogOutEvent = {
      user_id: encodeString(userdata),
    }

    // reset user globally
    resetUserGlobally(false, "")

    // GTM
    dataLayerPush(EVENTS.LOGOUT, trackLogOutEvent)
    setToastMessage(`<p>${topSection.toastMessages.logoutMessage}</p>`)
    setTimeout(() => {
      router.push("/", undefined, { shallow: true }).then(() => router.reload()) // reload after closing toast message
    }, 10000)
    setTimeout(() => {
      setToastMessage("")
    }, 5000)
  }

  const getUserNames = (userDetails: any) => {
    const userProfileData = userDetails?.profile?.response?.profile
    const firstName = userProfileData?.records?.[0]?.FirstName
    const lastName = userProfileData?.records?.[0]?.LastName
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : ""

    return {
      firstName,
      lastName,
      fullName,
    }
  }

  useEffect(() => {
    if (!showLoginToast || !user.isLoggedIn || (user.isLoggedIn && !user.profile)) {
      return
    }

    const loginToastMessage = `<p>${topSection.toastMessages.loginMessage} ${getUserNames(user).fullName
      }</p>`
    setToastMessage(loginToastMessage)
    setTimeout(() => {
      setToastMessage("")
      setShowLoginToast(false)
    }, 5000)
  }, [showLoginToast, user])

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  // Handle On click outside function for desktop and mobile
  const desktopSubMenuRef = useRef<HTMLDivElement>(null)
  const mobileSubMenuRef = useRef<HTMLDivElement>(null)
  const [isDesktopSubMenuOpen, setIsDesktopSubMenuOpen] = useState(false)
  const [isMobileSubMenuOpen, setIsMobileSubMenuOpen] = useState(false)

  useEffect(() => {
    const handleClickOutsideDesktop = (event: any) => {
      if (desktopSubMenuRef.current && !desktopSubMenuRef.current.contains(event.target as Node)) {
        setIsDesktopSubMenuOpen(false)
      }
      if (mobileSubMenuRef.current && !mobileSubMenuRef.current.contains(event.target as Node)) {
        setIsMobileSubMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutsideDesktop)

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDesktop)
    }
  }, [])

  // Retain Tab Focus when click on enter key
  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowDown" || event.key === "Enter") {
      setIsDesktopSubMenuOpen(true)
    }
  }

  const handleCartNavigation = () => {
    router.push("/order-materials/cart")
  }

  const handleCartRedirection = (event: any) => {
    if (event.key === "Enter") {
      handleCartNavigation()
    }
  }

  const headerLogo = (
    <div className={`header-logo${micrositeConfig?.isMicrositeEnabled ? " header-logo__microsite" : ""}`}>
      <Anchor
        tabindex={0}
        href={headerLogoLink?.length > 1 ? headerLogoLink : getHomepageurl()}
        dataLayerOnClick={() => handleDataLayerNavigationHeader("logo")}>
        <Img
          src={topSection?.logo?.src}
          width={206}
          height={24}
          alt={topSection?.logo?.alt}
          type="logo"
        />
      </Anchor>
    </div>
  )

  const loginLink = useMemo(() => (
    <div
      className="nav-item"
      onClick={() => {
        applicationConfiguration?.isSandozIDEnabled
          ? handleLoginRedirection()
          : setLoginPopupShow(true)
        handleDataLayerNavigationHeader(topSection?.loginText)
      }}
      role="button"
      onKeyDown={(event) => {
        toggleLoginPopUp(event)
        handleDataLayerNavigationHeader(topSection?.loginText)
      }}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}>
      {!isMobile && !isTablet && (
        <span className="header-userIcon">
          <Icon iconName="ProfileOutline" />
        </span>
      )}
      <p className="login bold">{topSection?.loginText}</p>
    </div>
  ), [applicationConfiguration, isMobile, isTablet])

  const registerLink = useMemo(() => (
    <Anchor
      href={applicationConfiguration?.isSandozIDEnabled ? appendEncodedReturnUrl(topSection?.registerLink) : topSection?.registerLink}
      tabindex={0}
      className="register-link external"
      noUnderline={true}
      dataLayerOnClick={() => handleDataLayerNavigationHeader(topSection?.registerText)}>
      <p className="register bold"> {topSection?.registerText}</p>
    </Anchor>
  ), [applicationConfiguration])

  const menuLeftSection = (
    <div className="menu-section-left">
      {(topSection?.login_register_enable) &&
        (!userData.loginStatus && !applicationConfiguration?.isSandozIDEnabled ? (
          <span
            onClick={() => {
              applicationConfiguration?.isSandozIDEnabled
                ? handleLoginRedirection()
                : setLoginPopupShow(true)
              handleDataLayerNavigationHeader(topSection?.loginText)
            }}
            onKeyDown={toggleLoginPopUp}
            role="button"
            tabIndex={1}
            className="navbar-toggler collapsed profile-outline">
            <Icon iconName="ProfileOutline" />
          </span>
        ) : (
          <div
            onClick={() => setIsMobileSubMenuOpen(!isMobileSubMenuOpen)}
            onKeyDown={() => setIsMobileSubMenuOpen(!isMobileSubMenuOpen)}>
            <div ref={mobileSubMenuRef} role="button" tabIndex={1} className="profile-ico">
              <span role="button" tabIndex={1} className={`navbar-toggler collapsed ${userData?.loginStatus ? "profile-fill" : "profile-outline"}`}>
                <Icon iconName={userData?.loginStatus ? "Profilefill" : "ProfileOutline"} />
              </span>
              {isMobileSubMenuOpen ? (userData?.loginStatus ? (
                <UserMenu
                  userName={userData?.name}
                  userMenuDetails={topSection?.userMenuDetails}
                  makeUserLogout={makeUserLogout}
                  isBtnPrimary={true}
                />
              ) : (
                <div className="user-menu user-menu__logout" aria-expanded={true}>
                  <nav className="mt-3" aria-label="menulist" key={0}>
                    {loginLink}
                  </nav>
                  <nav className="mt-3" aria-label="menulist" key={1}>
                    {registerLink}
                  </nav>
                </div>
              )) : <></>}
            </div>
          </div>
        ))}

      {((!headerDisplayControl?.isLangSwitcherDisabled &&
        applicationConfiguration?.isLanguageEnabled &&
        !micrositeConfig?.isMicrositeEnabled) ||
        (!headerDisplayControl?.isLangSwitcherDisabled &&
          micrositeConfig?.isMicrositeEnabled &&
          headerConfig?.field_disable_country_switcher === false &&
          micrositeConfig?.pageMenuLvl !== "0")) && (
          <div className="language-switcher-align">
            <div className="language-switcher-mob">
              <LanguageSwitch
                {...languageSelected}
                closeIcon={<Icon iconName="GlobalIcon" />}
                onClick={handleLanguageSwitcher}
                displayActive={isLangSwitcherActive}
              />
            </div>
            <div
              className="header-langswitcher-popup"
              ref={isMobile ? refLanguageSwitcher : refLanguageSwitcherButton}>
              <LanguageSwitcherPopup
                {...languageList}
                loading={loading}
                display={isLangSwitcherActive}
              />
            </div>
          </div>
        )}

      {micrositeConfig?.isMicrositeEnabled &&
        !isMobile &&
        brandSwitcherData &&
        micrositeConfig?.menuStructure === "two_level" &&
        micrositeConfig?.pageMenuLvl !== "0" &&
        micrositeConfig?.pageMenuLvl !== "1" &&
        headerConfig?.field_disable_brand_switcher === false && (
          <BrandSwitcher
            className="brand-switcher"
            options={brandSwitcherData?.options}
            onSelect={brandSwitcherData?.handleSelect}
            selectedItem={brandSwitcherData?.selectedItem}
          />
        )}

      {((!headerDisplayControl?.isCartDisabled &&
        applicationConfiguration?.isWebStoreEnabled &&
        !micrositeConfig?.isMicrositeEnabled) ||
        (micrositeConfig?.isMicrositeEnabled && !headerConfig?.field_disable_cart && applicationConfiguration?.isWebStoreEnabled)) &&
        (userData.loginStatus || showCartIcon) && (
          <div className="cart-item">
            <div className="cart-icon" role="button" tabIndex={0} onClick={handleCartNavigation}>
              <span role="button" tabIndex={0} className="">
                <Icon iconName="cartIcon" />
                {webshop?.productsInCart?.length > 0 &&
                  (webshop?.productsInCart?.length < 10 ? (
                    <span className="item-count">{webshop?.productsInCart?.length}</span>
                  ) : (
                    <span className="item-count">{`${NUMBERS.TEN}+`}</span>
                  ))}
              </span>
            </div>
          </div>
        )}
    </div>
  )

  const menuRightSection = (
    <div className="menu-section-right">
      {!headerDisplayControl?.isSearchDisabled && !headerConfig?.field_disable_search && (
        <span className="navbar-toggler collapsed mobile-close-icon">
          {!isSearchOpen && (
            <Icon
              iconName="Search2"
              onClick={() => setIsSearchOpen(true)}
              className="search-icon"
            />
          )}
          {isSearchOpen && <Icon iconName="Close2" onClick={() => setIsSearchOpen(false)} />}{" "}
        </span>
      )}
      {!headerDisplayControl?.isMegaMenuDisabled && !headerConfig?.field_disable_megamenu && (
        <button
          className="navbar-toggler collapsed mobile-close-icon"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarHeader"
          aria-controls="navbarHeader"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref={refMenuButton}
          onClick={() => (clickOutsideMenuDone = false)}
          tabIndex={0}>
          {!menuOpen && (
            <Icon
              iconName="HamburgerIcon2"
              onClick={() => setMenuOpen(true)}
              className="hamburger-icon"
            />
          )}
          {menuOpen && (
            <Icon
              className="hamburger-icon-close"
              iconName="CloseHamburgerMenu"
              onClick={() => setMenuOpen(false)}
            />
          )}
        </button>
      )}
    </div>
  )

  return (
    <>
      <ConfigurationsContext.Provider value={cachedContext}>
        {floatingBanner?.display && displayFloatingBanner !== false && shouldShowBanner && (
          <div className="floatingBanner" ref={bannerRef}>
            <FloatingBanner
              display={floatingBanner.display}
              id="floatingBannerId"
              onClose={onFloatingBannerClose}>
              {floatingBanner.children}
            </FloatingBanner>
          </div>
        )}

        {eyebrowMenu && eyebrowMenu?.enabled === 1 && (
          <EyebrowMenu
            logo={eyebrowMenu?.logo}
            links={eyebrowMenu?.links || []}
            topPosition={eyebrowMenuTopPosition}
            scrollAmount={NUMBERS.SCROLL_AMOUNT}
          />
        )}
      </ConfigurationsContext.Provider>

      {!headerDisplayControl?.isHeaderDisabled && (
        <header className="header">
          <Container>
            <nav className="navbar navbar-expand-lg logo-section">
              {!isTablet && (
                <div className="header-parent">
                  {micrositeConfig?.isMicrositeEnabled &&
                    brandSwitcherData &&
                    micrositeConfig?.menuStructure === "two_level" &&
                    micrositeConfig?.pageMenuLvl !== "0" &&
                    micrositeConfig?.pageMenuLvl !== "1" &&
                    headerConfig?.field_disable_brand_switcher === false && (
                      <BrandSwitcher
                        className="brand-switcher"
                        options={brandSwitcherData?.options}
                        onSelect={brandSwitcherData?.handleSelect}
                        selectedItem={brandSwitcherData?.selectedItem}
                      />
                    )}

                  {((!headerDisplayControl?.isLogoDisabled &&
                    !micrositeConfig?.isMicrositeEnabled) ||
                    (micrositeConfig?.isMicrositeEnabled && 
                      applicationComponentsData?.appComponents &&
                      headerConfig?.field_disable_logo === false)) &&
                    headerLogo}
                  <div className="header-menu-section header-menu-desktop">
                    <ul className="navbar-nav header-list">
                      {((!headerDisplayControl?.isLangSwitcherDisabled &&
                        applicationConfiguration?.isLanguageEnabled &&
                        !micrositeConfig?.isMicrositeEnabled) ||
                        (!headerDisplayControl?.isLangSwitcherDisabled &&
                          micrositeConfig?.isMicrositeEnabled &&
                          headerConfig?.field_disable_country_switcher === false &&
                          micrositeConfig?.pageMenuLvl !== "0")) && (
                          <>
                            <li className="nav-item">
                              <LanguageSwitch
                                {...languageSelected}
                                closeIcon={
                                  <Icon
                                    iconName="CollapseUpIcon"
                                    className="language-switch-close-icon"
                                  />
                                }
                                onClick={handleLanguageSwitcher}
                                displayActive={isLangSwitcherActive}
                              />
                            </li>
                            <div
                              className="header-langswitcher-popup"
                              ref={refLanguageSwitcherButton}>
                              <LanguageSwitcherPopup
                                {...languageList}
                                loading={loading}
                                display={isLangSwitcherActive}
                              />
                            </div>
                          </>
                        )}

                      {((!headerDisplayControl?.isCartDisabled &&
                        applicationConfiguration?.isWebStoreEnabled &&
                        !micrositeConfig?.isMicrositeEnabled) ||
                        (micrositeConfig?.isMicrositeEnabled && !headerConfig?.field_disable_cart &&
                          applicationConfiguration?.isWebStoreEnabled)) &&
                        (userData.loginStatus || showCartIcon) && (
                          <li className="cart-item">
                            <div
                              className="nav-item"
                              tabIndex={0}
                              aria-hidden="true"
                              onClick={handleCartNavigation}
                              onKeyDown={handleCartRedirection}>
                              <Icon iconName="cartIcon" />
                              {webshop?.productsInCart?.length > 0 &&
                                (webshop?.productsInCart?.length < 10 ? (
                                  <span className="item-count">
                                    {webshop?.productsInCart?.length}
                                  </span>
                                ) : (
                                  <span className="item-count">{`${NUMBERS.TEN}+`}</span>
                                ))}
                            </div>
                          </li>
                        )}

                      {userData.profileLoading ? (
                        <Loader display={userData.profileLoading} />
                      ) : (
                        <>
                          {(topSection?.login_register_enable) &&
                            (!userData.loginStatus ? (
                              <>
                                <li className="nav-item">
                                  {loginLink}
                                </li>
                                <li className="nav-item divider">
                                  {registerLink}
                                </li>
                              </>
                            ) : (
                              <li
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                tabIndex={0}
                                className="nav-item"
                                onClick={() => setIsDesktopSubMenuOpen(!isDesktopSubMenuOpen)}
                                onKeyDown={handleKeyDown}>
                                <div ref={desktopSubMenuRef}>
                                  <div className="nav-item user-menu-hover">
                                    <div className="profile-name">
                                      <span className="header-userIcon">
                                        <Icon iconName="Profilefill" />
                                      </span>
                                      <p className="mb-0">
                                        {isDocCheckUser(user)
                                          ? applicationConfiguration?.doccheck_user_name
                                          : getUserNames(user).firstName}
                                      </p>
                                    </div>
                                  </div>
                                  {isDesktopSubMenuOpen && (
                                    <UserMenu
                                      userName={userData?.name}
                                      userMenuDetails={topSection?.userMenuDetails}
                                      makeUserLogout={makeUserLogout}
                                      isBtnPrimary={true}
                                    />
                                  )}
                                </div>
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {isTablet && (
                <div className="header-parent-tablet">
                  <div className="header-menu-section header-menu-tablet">
                    {(!headerDisplayControl?.isLoginRegisterDisabled ||
                      !headerDisplayControl?.isCartDisabled ||
                      !headerDisplayControl?.isLangSwitcherDisabled ||
                      !headerConfig?.field_disable_cart) &&
                      menuLeftSection}
                    {((!headerDisplayControl?.isLogoDisabled &&
                      !micrositeConfig?.isMicrositeEnabled) ||
                      (micrositeConfig?.isMicrositeEnabled && 
                        applicationComponentsData?.appComponents &&
                        headerConfig?.field_disable_logo === false)) &&
                      headerLogo}
                    {(!headerDisplayControl?.isSearchDisabled ||
                      !headerDisplayControl?.isMegaMenuDisabled ||
                      !headerConfig?.field_disable_megamenu ||
                      !headerConfig?.field_disable_search) &&
                      menuRightSection}
                  </div>
                </div>
              )}
              {isMobile && (
                <div
                  className={`header-parent-mobile ${micrositeConfig?.isMicrositeEnabled ? "header-microsite-parent-mobile" : ""
                    }`}>
                  {(!headerDisplayControl?.isLogoDisabled && 
                    micrositeConfig?.isMicrositeEnabled && 
                    applicationComponentsData?.appComponents &&
                    headerConfig?.field_disable_logo === false) &&
                    headerLogo}
                  {micrositeConfig?.isMicrositeEnabled &&
                    brandSwitcherData &&
                    micrositeConfig?.menuStructure === "two_level" &&
                    micrositeConfig?.pageMenuLvl !== "0" &&
                    micrositeConfig?.pageMenuLvl !== "1" &&
                    headerConfig?.field_disable_brand_switcher === false && (
                      <BrandSwitcher
                        className="brand-switcher"
                        options={brandSwitcherData?.options}
                        onSelect={brandSwitcherData?.handleSelect}
                        selectedItem={brandSwitcherData?.selectedItem}
                      />
                    )}
                  <div
                    className={`header-menu-section header-menu-mobile ${micrositeConfig?.isMicrositeEnabled ? "header-microsite-menu-mobile" : ""
                      }`}>
                    {(!headerDisplayControl?.isLoginRegisterDisabled ||
                      !headerDisplayControl?.isCartDisabled ||
                      !headerDisplayControl?.isLangSwitcherDisabled ||
                      !headerConfig?.field_disable_cart) &&
                      menuLeftSection}
                    {(!headerDisplayControl?.isLogoDisabled && !micrositeConfig?.isMicrositeEnabled) &&
                      headerLogo}
                    {(!headerDisplayControl?.isSearchDisabled ||
                      !headerDisplayControl?.isMegaMenuDisabled ||
                      !headerConfig?.field_disable_search) &&
                      menuRightSection}
                  </div>
                </div>
              )}
            </nav>
            {!headerDisplayControl?.isMegaMenuDisabled ||
              !headerDisplayControl?.isSearchDisabled ||
              !headerConfig?.field_disable_search ? (
              <nav className="navbar navbar-expand-lg header-child">
                {!headerDisplayControl?.isMegaMenuDisabled &&
                  !headerConfig?.field_disable_megamenu && (
                    <div className="navbar-nav">
                      {megaMenu?.map((menuItem: IHeaderMenuProps, index: number) => (
                        <HeaderMenu
                          title={menuItem.title}
                          link={menuItem.link}
                          menuImageData={menuItem.menuImageData}
                          child={menuItem.child}
                          key={index}
                          subTheme={menuItem.subTheme}
                          targetType={menuItem.targetType}
                          distinctive={menuItem.distinctive}
                          icon_uri={menuItem?.icon_uri}
                        />
                      ))}
                    </div>
                  )}
                {!headerDisplayControl?.isSearchDisabled && !headerConfig?.field_disable_search && (
                  <div className="header-list-item search">
                    {!isSearchOpen && (
                      <Icon iconName="Search2" onClick={() => setIsSearchOpen(true)} />
                    )}
                    {isSearchOpen && (
                      <Icon
                        iconName="CloseSearch"
                        className="search-close-icon"
                        onClick={() => setIsSearchOpen(false)}
                      />
                    )}
                  </div>
                )}
              </nav>
            ) : (
              <></>
            )}
            {!headerDisplayControl?.isMegaMenuDisabled || !headerConfig?.field_disable_megamenu ? (
              <div className="collapse" id="navbarHeader" ref={refMenu}>
                <div className="accordion accordionFlush" id="accordionFlushHeaderMobile">
                  {megaMenu?.map((menu_item: IHeaderMenuProps, key: number) => (
                    <div className="accordion-item" key={key}>
                      <div className={`${"accordion-header "} ${menu_item?.distinctive ? "distinctive" : ""}`} id={`flush-heading_${key}`}>
                        {menu_item.child ? (
                          <>
                            <Anchor
                              tabindex={0}
                              noUnderline={true}
                              href={menu_item.link}
                              dataLayerOnClick={() =>
                                handleDataLayerNavigationMenu(menu_item.title)
                              }>
                              {<>
                                <Img type="icon" image_style_uri={menu_item?.icon_uri} src={menu_item?.icon_uri || ""} alt={menu_item.title} width={24} height={24} />
                                {menu_item.title}</>}
                            </Anchor>
                            <button
                              className="accordion-button collapsed "
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapse_${key}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapse_${key}`}>
                              <p className="MenuIndicator">
                                <Icon iconName="ChevronHeaderAccordion" width={32} height={32} />
                              </p>
                            </button>
                          </>
                        ) : (
                          <Anchor
                            tabindex={0}
                            noUnderline={true}
                            href={menu_item.link}
                            className=" collapsed menu-link"
                            dataLayerOnClick={() => handleDataLayerNavigationMenu(menu_item.title)}>
                            {menu_item.title}
                          </Anchor>
                        )}
                      </div>
                      <div
                        id={`flush-collapse_${key}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading_${key}`}
                        data-bs-parent="#accordionFlushHeaderMobile">
                        <div className="accordion-body child">
                          <div
                            className={`${"accordion accordion-flush menu-child"} ${menu_item?.distinctive ? "distinctive" : ""}`}
                            id={`accordionFlush${key}`}>
                            <div className="accordion-item">
                              <div className="accordion-header remove-for-now">
                                <Anchor
                                  tabindex={0}
                                  noUnderline={true}
                                  href={menu_item.link}
                                  className="accordion-button collapsed menu-link menu-head"
                                  dataLayerOnClick={() =>
                                    handleDataLayerNavigationMenu(menu_item.title)
                                  }>
                                  {
                                    <>
                                      <Img type="icon" image_style_uri={menu_item?.icon_uri} src={menu_item?.icon_uri || ""} alt={menu_item.title} width={24} height={24} />
                                      {menu_item.title}
                                    </>}
                                </Anchor>
                              </div>
                            </div>
                            {menu_item.child?.map(
                              (childItem: IHeaderChildProps, childKey: number) => (
                                <div className="accordion-item" key={childKey}>
                                  <div
                                    className={`${"accordion-header "} ${childItem?.distinctive ? "distinctive" : ""}`}
                                    id={`flush-heading_${key}${childKey}`}>
                                    {childItem.subChild ? (
                                      <>
                                        <Anchor
                                          href={childItem.link}
                                          tabindex={0}
                                          noUnderline={true}
                                          dataLayerOnClick={() =>
                                            handleDataLayerNavigationMenu(
                                              menu_item.title,
                                              childItem.title,
                                            )
                                          }>
                                          {
                                            <>
                                              <Img type="icon" image_style_uri={childItem?.icon_uri} src={childItem?.icon_uri || ""} alt={childItem.title} width={24} height={24} />
                                              {childItem.title}
                                            </>}
                                        </Anchor>
                                        <button
                                          className="accordion-button collapsed "
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#flush-collapse_${key}${childKey}`}
                                          aria-expanded="false"
                                          aria-controls={`flush-collapse_${key}${childKey}`}>
                                          <p className="MenuIndicator">
                                            <Icon
                                              iconName="ChevronHeaderAccordion"
                                              width={32}
                                              height={32}
                                            />
                                          </p>
                                        </button>
                                      </>
                                    ) : (
                                      <Anchor
                                        href={childItem.link}
                                        tabindex={0}
                                        noUnderline={true}
                                        className=" collapsed menu-link"
                                        dataLayerOnClick={() =>
                                          handleDataLayerNavigationMenu(
                                            menu_item.title,
                                            childItem.title,
                                          )
                                        }>
                                        {
                                          <>
                                            <Img type="icon" image_style_uri={childItem?.icon_uri} src={childItem?.icon_uri || ""} alt={childItem.title} width={24} height={24} />
                                            {childItem.title}
                                          </>}
                                      </Anchor>
                                    )}
                                  </div>

                                  <div
                                    id={`flush-collapse_${key}${childKey}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`flush-heading_${key}${childKey}`}
                                    data-bs-parent={`#accordionFlush${key}`}>
                                    <div className="accordion-body subchild">
                                      <div
                                        className={`${"accordion accordion-flush menu-child"} ${childItem?.distinctive ? "distinctive" : ""} `}
                                        id={`accordionFlush${key}${childKey}`}>
                                        <div className="accordion-item">
                                          <div className="accordion-header remove-for-now">
                                            <Anchor
                                              href={childItem.link}
                                              tabindex={0}
                                              noUnderline={true}
                                              className="accordion-button collapsed menu-link"
                                              dataLayerOnClick={() =>
                                                handleDataLayerNavigationMenu(
                                                  menu_item.title,
                                                  childItem.title,
                                                )
                                              }>
                                              {
                                                <>
                                                  <Img type="icon" image_style_uri={childItem?.icon_uri} src={childItem?.icon_uri || ""} alt={childItem.title} width={24} height={24} />
                                                  {childItem.title}
                                                </>}
                                            </Anchor>
                                          </div>
                                        </div>
                                        {childItem.subChild?.map(
                                          (
                                            subChildItem: IHeaderSubChildProps,
                                            subChildKey: number,
                                          ) => (
                                            <div className="accordion-item" key={subChildKey}>
                                              <div
                                                className={`${"accordion-header "} ${subChildItem?.distinctive ? "distinctive" : ""}`}
                                                id={`flush-heading_${key}${childKey}${subChildKey}`}>
                                                {subChildItem.grandChild ? (
                                                  <>
                                                    {" "}
                                                    <Anchor
                                                      href={subChildItem.link}
                                                      tabindex={0}
                                                      noUnderline={true}
                                                      dataLayerOnClick={() =>
                                                        handleDataLayerNavigationMenu(
                                                          menu_item.title,
                                                          childItem.title,
                                                          subChildItem.title,
                                                        )
                                                      }>
                                                      {
                                                        <>
                                                          <Img type="icon" image_style_uri={subChildItem?.icon_uri} src={subChildItem?.icon_uri || ""} alt={subChildItem.title} width={24} height={24} />
                                                          {subChildItem.title}
                                                        </>}
                                                    </Anchor>
                                                    <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#flush-collapse_${key}${childKey}${subChildKey}`}
                                                      aria-expanded="false"
                                                      aria-controls={`flush-collapse_${key}${childKey}${subChildKey}`}>
                                                      <p className="MenuIndicator">
                                                        <Icon
                                                          iconName="ChevronHeaderAccordion"
                                                          width={32}
                                                          height={32}
                                                        />
                                                      </p>
                                                    </button>
                                                  </>
                                                ) : (
                                                  <Anchor
                                                    href={subChildItem.link}
                                                    tabindex={0}
                                                    noUnderline={true}
                                                    className=" collapsed menu-link"
                                                    dataLayerOnClick={() =>
                                                      handleDataLayerNavigationMenu(
                                                        menu_item.title,
                                                        childItem.title,
                                                        subChildItem.title,
                                                      )
                                                    }>
                                                    {
                                                      <>
                                                        <Img type="icon" image_style_uri={subChildItem?.icon_uri} src={subChildItem?.icon_uri || ""} alt={subChildItem.title} width={24} height={24} />
                                                        {subChildItem.title}
                                                      </>}
                                                  </Anchor>
                                                )}
                                              </div>

                                              <div
                                                id={`flush-collapse_${key}${childKey}${subChildKey}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`flush-heading_${key}${childKey}${subChildKey}`}
                                                data-bs-parent={`#accordionFlush${key}${childKey}`}>
                                                <div className="accordion-body grandSubchild">
                                                  <div
                                                    className="accordion accordion-flush"
                                                    id={`accordionFlush${key}${childKey}${subChildKey}`}>
                                                    <div className="accordion-item">
                                                      <div className={`${"accordion-header remove-for-now"} ${subChildItem?.distinctive ? "distinctive" : ""}`}>
                                                        <Anchor
                                                          href={subChildItem.link}
                                                          tabindex={0}
                                                          noUnderline={true}
                                                          className="accordion-button collapsed menu-link menu-head"
                                                          dataLayerOnClick={() =>
                                                            handleDataLayerNavigationMenu(
                                                              menu_item.title,
                                                              childItem.title,
                                                              subChildItem.title,
                                                            )
                                                          }>
                                                          {
                                                            <>
                                                              <Img type="icon" image_style_uri={subChildItem?.icon_uri} src={subChildItem?.icon_uri || ""} alt={subChildItem.title} width={24} height={24} />
                                                              {subChildItem.title}
                                                            </>}
                                                        </Anchor>
                                                      </div>
                                                    </div>
                                                    {subChildItem.grandChild?.map(
                                                      (
                                                        grandChildItem: IHeaderGrandChildProps,
                                                        grandChildKey: number,
                                                      ) => (
                                                        <div
                                                          className="accordion-item"
                                                          key={grandChildKey}>
                                                          <div
                                                            className={`${"accordion-header "} ${grandChildItem?.distinctive ? "distinctive" : ""}`}
                                                            id={`flush-heading_${key}${childKey}${subChildKey}${grandChildKey}`}>
                                                            {grandChildItem.link == "#" ? (
                                                              <button
                                                                className="accordion-button collapsed"
                                                                type="button">
                                                                {grandChildItem.title}

                                                                <p className="MenuIndicator">
                                                                  <Icon
                                                                    iconName="ChevronHeaderAccordion"
                                                                    width={32}
                                                                    height={32}
                                                                  />
                                                                </p>
                                                              </button>
                                                            ) : (
                                                              <Anchor
                                                                href={grandChildItem.link}
                                                                tabindex={0}
                                                                noUnderline={true}
                                                                className={`${"collapsed menu-link"} ${grandChildItem?.distinctive ? "distinctive" : ""}`}
                                                                dataLayerOnClick={() =>
                                                                  handleDataLayerNavigationMenu(
                                                                    menu_item.title,
                                                                    childItem.title,
                                                                    subChildItem.title,
                                                                    grandChildItem.title,
                                                                  )
                                                                }>
                                                                {
                                                                  <>
                                                                    <Img type="icon" image_style_uri={grandChildItem?.icon_uri} src={grandChildItem?.icon_uri || ""} alt={grandChildItem.title} width={24} height={24} />
                                                                    {grandChildItem.title}
                                                                  </>}
                                                              </Anchor>
                                                            )}
                                                          </div>
                                                        </div>
                                                      ),
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ),
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {menuImageProduct?.length > NUMBERS.ZERO && (
                  <div className="product-list">
                    <Row>
                      {menuImageProduct.map((menuImage: IMenuImageProps, _key: number) => (
                        <MenuImage
                          imageLink={menuImage.imageLink}
                          title={menuImage.title}
                          paragraph={menuImage.paragraph}
                          redirectLink={menuImage.redirectLink}
                          key={_key}
                          alt={menuImage.alt}
                        />
                      ))}
                    </Row>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            {!headerDisplayControl?.isLoginRegisterDisabled ? (
              <LoginPopup
                showPopup={loginPopupShow}
                id="login-popup"
                form={loginPopup.form}
                bannerCTA={loginPopup.bannerCTA}
                handlePopupShow={(popupShow) => {
                  resetRating({ id: undefined })
                  setLoginPopupShow(popupShow)
                }}
                handleForgotPasswordShow={(popupShow: boolean) => setForgotPasswordShow(popupShow)}
                popup={loginPopup.popup}
                loginConfirmation={() => setShowLoginToast(true)}
              />
            ) : (
              <></>
            )}
          </Container>
        </header>
      )}

      {!headerDisplayControl?.isSearchDisabled && isSearchOpen && (
        <div ref={refSearch}>
          <SearchBar
            isTypeAhead={true}
            searchContent={searchBarConfiguration}
            className="header-modal"
            showIconOnCTA={true}
          />
        </div>
      )}
      {!!toastMessage && (
        <Notification
          notificationType="success"
          displayTxt={toastMessage}
          onClose={() => {
            setToastMessage("")
          }}
        />
      )}
      {showIndicator && overlayElement()}
    </>
  )
}

export default RebrandingHeader
