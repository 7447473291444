import {
  Button,
  Chips,
  CustomDropDown,
  DatePicker,
  Icon,
  IconButton,
  RichText,
  SearchBox,
} from "@atoms"
import React, { useEffect, useState } from "react"
import NUMBERS from "@helpers/constants/numbers"
import { useMediaQuery } from "@helpers/hooks"
import { IFilterProps } from "./enhancedFilterSearch.interface"

const EnhancedFilterSearch = (props: IFilterProps) => {
  const {
    filterLabels,
    noOfResults,
    filtersOptions,
    selectedFilters = {},
    handleSearch,
    handleUnselectAll,
    applyAdditionalFilters,
    currentSearchText,
    handleAdditionalFilters,
    handleRemoveItem,
    handleSearchText,
    isCareerSearch,
    handleSortByKey,
    handleSortByOrder,
    sortList,
    selectedSortValue,
    resetLocalFilterWithReducer,
    isDisabled,
    handleViewChange,
    currentView,
    disabledAdvanceSearch,
    isViewTypeEnabled = false,
    startDate = "",
    endDate = "",
    showResultCount = false,
    displayMobileSort = false,
    disabledSearch = false,
    disabledColumnSort,
    labelKeyValuePair
  } = props

  const {
    searchLabel,
    searchPlaceholder,
    SearchCTAText,
    resultsFoundLabel,
    applyFiltersCTA,
    unselectFiltersCTA,
    unSelectItemsLabel,
    sortByCTAText,
    filterIconName,
    listViewText,
    gridViewText,
  } = filterLabels

  const allowedSortList = sortList && Object?.fromEntries(
    Object?.entries(sortList)?.filter(
      ([key, value]) => !disabledColumnSort?.includes(key),
    ),
  ) 
  const [searchText, setSearchText] = useState(currentSearchText)
  const [isCleared, setIsCleared] = useState<boolean>(false)
  const [displayDropdowns, setDisplayDropdowns] = useState<boolean>(
    isCareerSearch || disabledAdvanceSearch || false,
  )
  const [showSortList, setShowSortList] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [showTooltipView, setShowTooltipView] = useState(false)



  const isMobile = useMediaQuery("(max-width: 991px)")
  const isSmallMobile = useMediaQuery("(max-width: 576px)")

  const handleSearchTextChanges = (text: string) => {
    if (searchText && !text) {
      handleSearch?.(text)
    }
    setSearchText(text)
    handleSearchText?.(text)
  }

  useEffect(() => {
    setSearchText(currentSearchText)
  }, [currentSearchText])

  const handleFilterToggle = () => {
    setDisplayDropdowns(!displayDropdowns)
    resetLocalFilterWithReducer?.()
  }

  const formatMatchString = (resultsLength: number) =>
    resultsFoundLabel && resultsLength
      ? `<span>${resultsFoundLabel?.replace(
        "@count_val",
        `<span class="results">${resultsLength?.toString()}</span>`,
      )}</span>`
      : ""

  const handleSearchButton = () => {
    handleSearch(searchText)
  }
  const handleApplyFilters = () => {
    applyAdditionalFilters()
    setDisplayDropdowns(false)
  }

  const handleUnselectAllFilters = () => {
    handleUnselectAll()
    setDisplayDropdowns(false)
  }

  const handleSortClick = () => {
    setShowSortList(!showSortList)
  }

  const handleSort = (key: string) => {
    handleSortByKey?.(key)
    setShowSortList(!showSortList)
    setDisplayDropdowns(false)
  }

  const handleSortOrder = () => {
    handleSortByOrder?.()
    setShowSortList(!showSortList)
    setDisplayDropdowns(false)
  }

  const clearFilter = (filterName: string) => {
    handleAdditionalFilters?.(filterName, [])
  }

  const handleClear = (event: any) => {
    if (event?.key === "Enter") {
      handleUnselectAll()
      setDisplayDropdowns(false)
    }
  }

  const handleToggleHover = () => {
    if (filterIconName) {
      setShowTooltip(true)
    }
  }

  const handleToggleLeave = () => {
    setShowTooltip(false)
  }

  const handleToggleViewHover = () => {
    if (filterIconName) {
      setShowTooltipView(true)
    }
  }

  const handleToggleViewLeave = () => {
    setShowTooltipView(false)
  }

  const labelObject = (labelKeyValuePair ?? []).reduce((acc, item) => {
    const key = Object.values(item).find((val): val is string => typeof val === "string" && val.endsWith("_tooltip_text"))
    const value = Object.values(item).find((val): val is string => typeof val === "string" && !val.endsWith("_tooltip_text"))
    if (key && value) {
      return { ...acc, [key]: value }
    }
    return acc 
  }, {} as Record<string, string>)
  
  const listHoverText = labelObject?.["list_tooltip_text"]
  const gridHoverText = labelObject?.["grid_tooltip_text"]

  return (
    <div>
      <section>
        <form className="enhanced-filter" onSubmit={(e) => e.preventDefault()}>
          <div
            className={`search-container ${isViewTypeEnabled ? "search-container-withView" : ""}`}>
            {!disabledSearch && (
              <>
            <div className="search-container--box">
              <label htmlFor="searchBarInputId">
                <RichText className="title" content={searchLabel} />
              </label>
              <SearchBox
                currentSearchText={searchText}
                placeholderText={searchPlaceholder}
                setSearchText={handleSearchTextChanges}
                handleSearch={handleSearch}
                isCleared={isCleared}
                useStateVal={false}
                type="search"
              />
            </div>
            <div className="search-container--button">
              <Button
                type="button"
                className="search-button"
                tabindex={1}
                onClick={handleSearchButton}
                isDisabled={searchText?.trim().length < NUMBERS.THREE || searchText === ""}
                iconPosition="right"
                icon={<Icon iconName="SearchIcon2" />}>
                {SearchCTAText}
              </Button>
            </div>
            </>
            )}
            {!disabledAdvanceSearch &&
            <div className="search-container--toggle">
              <div className="search-icons-container">
                <div
                  className={`search-container--toggle_icon ${displayDropdowns ? "search-container--toggle_icon__bg" : ""
                    }`}
                  onMouseEnter={handleToggleHover}
                  onMouseLeave={handleToggleLeave}>
                  <IconButton
                    tabindex={0}
                    onClick={handleFilterToggle}
                    icon={<Icon iconName="FilterIcon" />}
                    title={`${filterIconName ? "" : "search-container--toggle_icon"}`}
                  />
                  {showTooltip && <div className="tooltip">{filterIconName}</div>}
                </div>
                {isViewTypeEnabled && (
                  <div className={`search-container--toggle_icon ${displayDropdowns ? "search-container--toggle_icon__bg" : ""
                    }`}
                    onMouseEnter={handleToggleViewHover}
                    onMouseLeave={handleToggleViewLeave}>
                    <IconButton
                      tabindex={1}
                      onClick={handleViewChange}
                      className="search-container--toggle_icon search-container--toggle_view"
                      icon={
                        currentView === "grid" ? (
                          <><Icon iconName="ListView" /> {(listHoverText || listViewText) && showTooltipView && <div className="tooltip">{listHoverText || listViewText}</div>}</>
                        ) : (
                          <><Icon iconName="GridView" /> {(gridHoverText || gridViewText) && showTooltipView && <div className="tooltip">{gridHoverText || gridViewText}</div>}</>
                        )
                      }
                      title={`${listViewText ? "" : "search-container--toggle_icon"}`}
                    />
                  </div>
                )}
              </div>
            </div>
           }
          </div>
          {displayDropdowns && !disabledAdvanceSearch && (
            <div className="dropdown-container">
              <div className="dropdown-container--modal">
                {isMobile && (
                  <RichText
                    className="item-number-container"
                    content={formatMatchString(noOfResults)}
                  />
                )}
                <div className="dropdown-container--container">
                  <div
                    className="dropdown-container--dropdowns"
                    // @ts-ignore
                    style={{"--columns":
                        filtersOptions.length > 4
                          ? filtersOptions.length === 6
                            ? 3
                            : 4
                          : filtersOptions.length,
                    }}>
                    {filtersOptions?.map(
                      (
                        { label, filterName = "", selectOptions, placeHolderTxt, value },
                        index,
                      ) => (
                        <div key={label} className="dropdown-container--dropdowns_dropdown">
                          <div className="dropdown-labels">
                            <div className="dropdown-title">
                              <RichText className="title" content={label} />
                              {(selectedFilters?.[filterName]?.length  && !placeHolderTxt) ? (
                                <span className="dropdown-select-count">
                                  ({selectedFilters?.[filterName]?.length})
                                </span>
                              ) : (
                                <div />
                              )}
                            </div>
                            {selectedFilters?.[filterName]?.length ? (
                              <span
                                className="unselect-filter"
                                aria-hidden="true"
                                onClick={() => clearFilter(filterName)}
                                role="button"
                                tabIndex={1}>
                                {unSelectItemsLabel}
                              </span>
                            ) : (
                              <div />
                            )}
                          </div>
                          {!placeHolderTxt ? (
                            <CustomDropDown
                              {...selectOptions}
                              // labelItemsSelected={labelItemsSelected}
                              isCleared={isCleared}
                              intialValue={selectedFilters?.[filterName] ?? []}
                              onValueChange={(val) => handleAdditionalFilters?.(filterName, val)}
                              simpleDropDownLabel={true}
                            />
                          ) : (
                            <DatePicker
                              dataPlaceHolder={placeHolderTxt}
                              value={
                                filterName === "field_start_date"
                                  ? startDate
                                  : filterName === "field_end_date"
                                  ? endDate
                                  : ""
                              }
                              name={`${filterName}-${index}`}
                              setValue={(newDate: string) => handleAdditionalFilters?.(filterName, newDate)}
                              timeFormat="HH:mm:ss:SSS"
                            />
                          )}
                        </div>
                      ),
                    )}
                  </div>

                  <div className="dropdown-container--buttons">
                    <Button
                      type="button"
                      className="search-button"
                      tabindex={1}
                      onClick={handleApplyFilters}
                      isDisabled={isDisabled}>
                      <span className="apply">
                        {applyFiltersCTA}
                        <Icon iconName="CheckMark" />
                      </span>
                    </Button>
                    {Object.values(selectedFilters).flat(1).length ? (
                      <div className="clear-btn">
                        <span
                          className="clear-filter"
                          aria-hidden="true"
                          tabIndex={0}
                          role="button"
                          onClick={handleUnselectAllFilters}>
                          {unselectFiltersCTA}
                        </span>
                        <Icon iconName="closeBlue" />
                      </div>
                    ) : null}
                  </div>
                </div>
                {isCareerSearch && isMobile && allowedSortList && (
                  <div className="sort-container">
                    <div className="search-filter-options">
                      <div className="sort-dropdown-container">
                        <Button
                          type="button"
                          className="sort-results-button btn-light"
                          tabindex={1}
                          onClick={handleSortClick}
                          icon={<Icon iconName="SortByIcon" />}
                          iconPosition={isSmallMobile ? "right" : ""}>
                          {sortByCTAText}
                        </Button>
                        {showSortList && (
                          <div className="sort-dropdown-content">
                            {Object.entries(allowedSortList)?.map(([key, value]: any) => (
                              <button
                                type="button"
                                key={key}
                                className="list-group-item"
                                value={value}
                                onClick={() => handleSort(key)}>
                                <span
                                  className={
                                    key === selectedSortValue?.column ? "checkMark" : "noCheckMark"
                                  }>
                                  {key === selectedSortValue?.column ? (
                                    <Icon iconName="CheckMark" />
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span
                                  className={
                                    key === selectedSortValue?.column ? "selcted-sort" : ""
                                  }>
                                  {value}
                                </span>
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                      <IconButton
                        tabindex={0}
                        onClick={handleSortOrder}
                        className={`search-container--toggle_icon ${displayDropdowns || (isCareerSearch && !isMobile)
                          } ${selectedSortValue?.order === "DESC" ? "highlight-sort" : ""}`}
                        icon={
                          selectedSortValue?.order === "DESC" ? (
                            <Icon iconName="SortZA1" />
                          ) : (
                            <Icon iconName="SortAZ1" />
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {disabledAdvanceSearch && !isCareerSearch && displayMobileSort && isMobile && allowedSortList && (
            <>
            <div className="sort-container">
              <div className="search-filter-options">
                <div className="sort-dropdown-container">
                  <Button
                    type="button"
                    className="sort-results-button btn-light"
                    tabindex={1}
                    onClick={handleSortClick}
                    icon={<Icon iconName="SortByIcon" />}
                    iconPosition={isSmallMobile ? "right" : ""}>
                    {sortByCTAText}
                  </Button>
                  {showSortList && (
                    <div className="sort-dropdown-content">
                      {Object.entries(allowedSortList)?.map(([key, value]: any) => (
                        <button
                          type="button"
                          key={key}
                          className="list-group-item"
                          value={value}
                          onClick={() => handleSort(key)}>
                          <span
                            className={
                              key === selectedSortValue?.column ? "checkMark" : "noCheckMark"
                            }>
                            {key === selectedSortValue?.column ? (
                              <Icon iconName="CheckMark" />
                            ) : (
                              ""
                            )}
                          </span>
                          <span
                            className={
                              key === selectedSortValue?.column ? "selcted-sort" : ""
                            }>
                            {value}
                          </span>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <IconButton
                  tabindex={0}
                  onClick={handleSortOrder}
                  className={`search-container--toggle_icon ${
                    selectedSortValue?.order === "DESC" ? "highlight-sort" : ""
                  }`}
                  icon={
                    selectedSortValue?.order === "DESC" ? (
                      <Icon iconName="SortZA1" />
                    ) : (
                      <Icon iconName="SortAZ1" />
                    )
                  }
                />
              </div>
            </div>
            </>
          )}
          <div className="item-number">
            {(!(isMobile && displayDropdowns) || showResultCount) && !disabledSearch && (
              <div>
                <RichText
                  className="item-number-container"
                  content={formatMatchString(noOfResults)}
                />
              </div>
            )}
            <div>
              {unselectFiltersCTA && Object.values(selectedFilters).flat(1).length ? (
                <div
                  className="clear-btn"
                  onClick={handleUnselectAllFilters}
                  tabIndex={0}
                  role="button"
                  onKeyDown={handleClear}>
                  <span className="filter-clear">{unselectFiltersCTA}</span>
                  <Icon iconName="Close2" />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {Object.values(selectedFilters).flat(1).length ? (
            <div className="chips-container">
              {Object.entries(selectedFilters).map(([key, value]: any) =>
                value.length
                  ? value.map((item: any) => (
                    <Chips
                      key={item.value}
                      content={item.label}
                      handleClick={() => handleRemoveItem(key, item)}
                    />
                  ))
                  : null,
              )}
            </div>
          ) : null}
        </form>
      </section>
    </div>
  )
}

export default EnhancedFilterSearch