import StarsRating from "@molecules/starsRating"
import IModuleInformation from "./_modulesInformation.interface"
import { useState } from "react"
import { Button, RichText } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import { useRouter } from "next/router"
import { COMPLETION_STATUS } from "@helpers/constants/generic"

const ModulesInformation = ({
  moduleHeading,
  title,
  rating,
  avgRating,
  trainingTime,
  desc,
  keyOpinion,
  ctaLink,
  ctaLabel,
  course_id,
  module_id,
  activity_id,
  enableStatus,
  completionStatusLabel,
  completionStatus,
}: IModuleInformation) => {
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const isMobile = useMediaQuery("(max-width: 767px)")

  const router = useRouter()

  const handleClick = (link: string) => {
    router.push(`${link}?cid=${course_id}&mid=${module_id}&aid=${activity_id}`)
  }

  return (
    <div className="modulesInfo">
      <div className="modulesInfo-top">
        {trainingTime ? <p className="modulesInfo-top-time">{trainingTime}</p> : <></>}
      </div>
      {moduleHeading ? <h1 className="modulesInfo-heading bold">{moduleHeading}</h1> : <></>}
      <div className="moduleInfo-star">
        <StarsRating
          isDisabled={false}
          localStorageName={rating?.localStorageName}
          disableLayoutAfterRate={false}
          avgRating={avgRating}
          setShowLoginPopUp={() => setShowLoginPopUp(true)}
          className=""
          title=""
        />
      </div>
      {title ? <h5 className="modulesInfo-title bold">{title}</h5> : <></>}
      {desc ? <RichText className="modulesInfo-desc bold" content={desc} /> : <></>}
      {keyOpinion ? <RichText className="modulesInfo-leader bold" content={keyOpinion} /> : <></>}
      {enableStatus && completionStatusLabel ? (
        <div className="modulesInfo-status">
          <p
            className={`modulesInfo-status-courseStatus ${
              COMPLETION_STATUS[completionStatus as keyof typeof COMPLETION_STATUS]
            }`}>
            {completionStatusLabel}
          </p>
        </div>
      ) : (
        <></>
      )}
      {ctaLabel && !isMobile ? (
        <div className="modulesInfo-cta">
          <Button tabindex={0} isSecondary onClick={() => handleClick(ctaLink || "")}>
            {ctaLabel}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ModulesInformation
