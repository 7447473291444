/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useRouter } from "next/router"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { middlewarePostAPI } from "@utils/baseApi"
import { resetUser } from "store/actions/ActionCreators"
import { config } from "@utils/baseApi/config.external"
import { useOutsideClick } from "@helpers/hooks/useOutsideClick"
import useElementSize from "@helpers/hooks/useElementSize"
import { Container, Icon, Img, LanguageSwitch, RichText, Row } from "@atoms"
import { FloatingBanner, HeaderMenu, MenuImage, Notification } from "@molecules"
import ConfigurationsContext from "@utils/ConfigurationsContext"
import {
  handleDataLayerNavigationHeader,
  handleDataLayerNavigationMenu,
} from "@utils/gtmUtilsHelpers"
import NUMBERS from "@helpers/constants/numbers"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import { encodeString } from "@utils/sha256"
import dynamic from "next/dynamic"
import { getAssetPrefixUrl, getHomepageurl, isDocCheckUser } from "@utils/helper"
import ApplicationContext from "@utils/application-context/applicationContext"
import { UserMenu } from "@organisms"
import { IHeaderProps } from "./_header.interface"
import {
  IHeaderChildProps,
  IHeaderGrandChildProps,
  IHeaderMenuProps,
  IHeaderSubChildProps,
} from "../../molecules/headerMenu/_headerMenu.interface"
import { IMenuImageProps } from "../../molecules/menuImage/_menuImage.interface"
import SearchBar from "../searchBar"
import LanguageSwitcherPopup from "../languageSwitcherPopup"
import LoginPopup from "../loginPopup"
import { setRefApplicationID, setUserStateGlobally, updateReloadContent } from "store/actions/CommonActionCreators"
import Loader from "@atoms/loader"
import { resetRatings } from "store/actions/RatingsActionCreators"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import useUserSessionControl from "@helpers/hooks/userSessionControlHook/useUserSession"

const Anchor = dynamic(() => import("../../atoms/anchor"), { ssr: false })
const EyebrowMenu = dynamic(() => import("../eyebrowMenu"), { ssr: false })

const Header = ({
  loading,
  topSection,
  languageSelected,
  userData,
  languageList,
  megaMenu,
  loginPopup,
  floatingBanner,
  eyebrowMenu,
  isDisabled,
  onFloatingBannerClose,
}: IHeaderProps | any) => {
  const [isLangSwitcherActive, setIsLangSwitcherActive] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [loginPopupShow, setLoginPopupShow] = useState(false)
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false)
  const [eyebrowMenuTopPosition, setEyebrowMenuTopPosition] = useState(0)
  const [toastMessage, setToastMessage] = useState<string>("")
  const [shouldShowBanner, setShouldShowBanner] = useState(true)
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [showLoginToast, setShowLoginToast] = useState(false)

  const { applicationConfiguration } = useContext(ApplicationContext)
  const { handleLoginRedirection, getCurrentAppId, appendEncodedReturnUrl } = useSandozIDAuth(applicationConfiguration)

  const headerDisplayControl = applicationConfiguration?.headerConfiguration
  const user: any = useSelector((state: UserState) => state.user, shallowEqual)
  const webshop: any = useSelector((state: any) => state.webshop)
  const appData: any = useSelector((state: any) => state?.common)


  const dispatch: Dispatch<any> = useDispatch()
  const [bannerRef, { height }] = useElementSize()

  const { clearUserSessionData } = useUserSessionControl()

  const handleLanguageSwitcher = () => {
    setIsLangSwitcherActive((current) => !current)
    handleDataLayerNavigationHeader("Language Switcher")
  }
  const menuImageProduct = megaMenu && megaMenu.length > 0 ? megaMenu[0].menuImageData : []

  // flag to show Cart Icon for non-loggedIn users
  const showCartIcon =
    !!applicationConfiguration?.siteConfig?.webstore_enable_non_loggedin_users &&
    webshop?.productsInCart?.length > 0

  const router = useRouter()
  let clickOutsideMenuDone = false
  let clickOutsideMenuButtonDone = false

  const updateRefAppID: any = useCallback(
    (value: any) => dispatch(setRefApplicationID(value)),
    [dispatch],
  )

  const updateReloadStatus: any = useCallback(
    (value: any) => dispatch(updateReloadContent(value)),
    [dispatch],
  )

  useEffect(() => {
    resetMobileMenu()
    setMenuOpen(false)
  }, [router.query])

  const toggleLoginPopUp = (event: any) => {
    if (event.key === "Enter") {
      setLoginPopupShow(!loginPopupShow)
    }
  }

  const handleTextSearch = (text: string, e?: MouseEvent) => {
    if (e) {
      e.preventDefault()
    }
    if (text.length > 0) {
      if (router.pathname === "/search") {
        router.push({ pathname: "/search", query: { search: `${text}` } }, undefined, {
          shallow: true,
        })
      } else {
        router.push({ pathname: "/search", query: { search: `${text}` } })
      }
    }
  }

  const resetMobileMenu = () => {
    if (refMenuButton.current) {
      refMenuButton.current.className = "navbar-toggler collapsed"
    }
    if (refMenu.current) {
      refMenu.current.className = "collapse"
    }
    setMenuOpen(false)
    clickOutsideMenuDone = false
    clickOutsideMenuButtonDone = false
  }

  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: "How can we help today?",
    },
    buttonText: "Search",
    handleTextSearch,
  }

  const setMobileMenuState = () => {
    if (clickOutsideMenuDone) {
      resetMobileMenu()
    }
  }

  const handleClickOutsideMenu = () => {
    clickOutsideMenuDone = true
    setMobileMenuState()
  }

  const refMenu = useOutsideClick(handleClickOutsideMenu)

  const handleClickOutsideMenuButton = () => {
    clickOutsideMenuButtonDone = true
    setMobileMenuState()
  }
  const refMenuButton = useOutsideClick(handleClickOutsideMenuButton)

  const handleClickOutsideSearch = () => {
    setIsSearchOpen(false)
  }
  const refSearch = useOutsideClick(handleClickOutsideSearch)

  const handleClickOutsideLanguageSwitcher = () => {
    clickOutsideLanguageSwitcherDone = true
    setLanguageSwitcherState()
  }
  const refLanguageSwitcher = useOutsideClick(handleClickOutsideLanguageSwitcher)

  const handleClickOutsideLanguageSwitcherButton = () => {
    clickOutsideLanguageSwitcherMenuButtonDone = true
    setLanguageSwitcherState()
  }
  const refLanguageSwitcherButton = useOutsideClick(handleClickOutsideLanguageSwitcherButton)

  let clickOutsideLanguageSwitcherDone = false
  let clickOutsideLanguageSwitcherMenuButtonDone = false

  const setLanguageSwitcherState = () => {
    if (clickOutsideLanguageSwitcherDone && clickOutsideLanguageSwitcherMenuButtonDone) {
      setIsLangSwitcherActive(false)
      clickOutsideLanguageSwitcherDone = false
      clickOutsideLanguageSwitcherMenuButtonDone = false
    }
  }

  const [displayFloatingBanner, setDisplayFloatingBanner] = useState(floatingBanner?.display)

  const cachedContext = useMemo(() => {
    const contextValue: any = {
      floatingBanner,
      eyebrowMenu,
      displayFloatingBanner,
      setDisplayFloatingBanner,
    }
    return contextValue
  }, [displayFloatingBanner])

  useEffect(() => {
    setShouldShowBanner(sessionStorage.getItem("showBanner") !== "false")
    if (displayFloatingBanner !== false) {
      return setEyebrowMenuTopPosition(height)
    }
    if (!displayFloatingBanner) {
      return setEyebrowMenuTopPosition(NUMBERS.ZERO)
    }
  }, [height, displayFloatingBanner])

  const deleteUserFromStore: any = useCallback(
    (value: any) => dispatch(resetUser(value)),
    [dispatch],
  )

  const resetUserGlobally: any = useCallback(
    (isLoggedIn: boolean, type: string) => dispatch(setUserStateGlobally(isLoggedIn, type)),
    [dispatch],
  )

  const resetRating: any = useCallback((value: any) => dispatch(resetRatings(value)), [dispatch])

  const makeUserLogout = async () => {
    setShowIndicator(true)
    localStorage.removeItem("Key")
    if (isDocCheckUser(user)) {
      deleteUserFromStore({})
      clearUserSessionData()
      // reset user globally
      resetUserGlobally(false, "")
    } else {
      const logoutUrl = applicationConfiguration?.isSandozIDEnabled
        ? config.onPrem.LOGOUT_API_URL_SANDOZ_ID
        : config.onPrem.LOGOUT_API_URL
      const data = applicationConfiguration?.isSandozIDEnabled ? { app_id: getCurrentAppId() } : {}
      const response = await middlewarePostAPI(logoutUrl, data)
      deleteUserFromStore(response)
      clearUserSessionData()
      if (!response.fail && applicationConfiguration?.isSandozIDEnabled) {
        let redirectUrl = new URL(window.location.href)
        if (applicationConfiguration?.isGlobalSite) {
          // clearing app_id from store on logout for global site
          updateRefAppID("")
          sessionStorage.setItem("redirection", JSON.stringify(0))
          redirectUrl = appData?.feUrl ? new URL(`${window.location.protocol}//${appData.feUrl}`) : redirectUrl
        }
        redirectUrl.searchParams.delete("tid")
        redirectUrl.searchParams.delete("status_code")
        const azureLogoutUrl = `${applicationConfiguration?.sandozLogoutUrl}?post_logout_redirect_uri=${redirectUrl}`
        window.location.href = azureLogoutUrl
      }
    }
    setShowIndicator(false)
    updateReloadStatus(false)
    const userdata = user?.user?.response?.session_data?.user_data?.current_user?.name

    const trackLogOutEvent = {
      user_id: encodeString(userdata),
    }

    // reset user globally
    resetUserGlobally(false, "")

    // GTM
    dataLayerPush(EVENTS.LOGOUT, trackLogOutEvent)
    setToastMessage(`<p>${topSection.toastMessages.logoutMessage}</p>`)
    setTimeout(() => {
      router.push("/", undefined, { shallow: true }).then(() => router.reload()) // reload after closing toast message
    }, 10000)
    setTimeout(() => {
      setToastMessage("")
    }, 5000)
  }

  const getUserNames = (userDetails: any) => {
    const userProfileData = userDetails?.profile?.response?.profile
    const firstName = userProfileData?.records?.[0]?.FirstName
    const lastName = userProfileData?.records?.[0]?.LastName
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : ""

    return {
      firstName,
      lastName,
      fullName,
    }
  }

  useEffect(() => {
    if (!showLoginToast || !user.isLoggedIn || (user.isLoggedIn && !user.profile)) {
      return
    }

    const loginToastMessage = `<p>${topSection.toastMessages.loginMessage} ${
      getUserNames(user).fullName
    }</p>`
    setToastMessage(loginToastMessage)
    setTimeout(() => {
      setToastMessage("")
      setShowLoginToast(false)
    }, 5000)
  }, [showLoginToast, user])

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  // Handle On click outside function for desktop and mobile
  const desktopSubMenuRef = useRef<HTMLDivElement>(null)
  const mobileSubMenuRef = useRef<HTMLDivElement>(null)
  const [isDesktopSubMenuOpen, setIsDesktopSubMenuOpen] = useState(false)
  const [isMobileSubMenuOpen, setIsMobileSubMenuOpen] = useState(false)

  useEffect(() => {
    const handleClickOutsideDesktop = (event: any) => {
      if (desktopSubMenuRef.current && !desktopSubMenuRef.current.contains(event.target as Node)) {
        setIsDesktopSubMenuOpen(false)
      }
      if (mobileSubMenuRef.current && !mobileSubMenuRef.current.contains(event.target as Node)) {
        setIsMobileSubMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutsideDesktop)

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDesktop)
    }
  }, [])

  // Retain Tab Focus when click on enter key
  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowDown" || event.key === "Enter") {
      setIsDesktopSubMenuOpen(true)
    }
  }

  const handleCartNavigation = () => {
    router.push("/order-materials/cart")
  }

  const handleCartRedirection = (event: any) => {
    if (event.key === "Enter") {
      handleCartNavigation()
    }
  }

  return (
    <>
      <ConfigurationsContext.Provider value={cachedContext}>
        {floatingBanner?.display && displayFloatingBanner !== false && shouldShowBanner && (
          <div className="floatingBanner" ref={bannerRef}>
            <FloatingBanner
              display={floatingBanner.display}
              id="floatingBannerId"
              onClose={onFloatingBannerClose}>
              {floatingBanner.children}
            </FloatingBanner>
          </div>
        )}

        {eyebrowMenu && eyebrowMenu?.enabled === 1 && (
          <EyebrowMenu
            logo={eyebrowMenu?.logo}
            links={eyebrowMenu?.links || []}
            topPosition={eyebrowMenuTopPosition}
            scrollAmount={NUMBERS.SCROLL_AMOUNT}
          />
        )}
      </ConfigurationsContext.Provider>

      {!headerDisplayControl?.isHeaderDisabled && (
        <header className="header">
          <Container>
            <nav className="navbar navbar-expand-lg">
              <div className="header-parent">
                {!headerDisplayControl?.isLogoDisabled && (
                  <div className="header-logo">
                    <Anchor
                      tabindex={0}
                      href={getHomepageurl()}
                      dataLayerOnClick={() => handleDataLayerNavigationHeader("logo")}>
                      <Img
                        src={topSection?.logo?.src}
                        width={206}
                        height={24}
                        alt={topSection?.logo?.alt}
                        type="logo"
                      />
                    </Anchor>
                  </div>
                )}
                {!headerDisplayControl?.isSloganDisabled && (
                  <RichText className="header-text" content={topSection?.tagLine} />
                )}
                <div className="header-menu-section header-menu-desktop">
                  <ul className="navbar-nav header-list">
                    {!headerDisplayControl?.isCartDisabled &&
                      applicationConfiguration?.isWebStoreEnabled &&
                      (userData.loginStatus || showCartIcon) && (
                        <li className="cart-item">
                          <div
                            className="nav-item"
                            tabIndex={0}
                            aria-hidden="true"
                            onClick={handleCartNavigation}
                            onKeyDown={handleCartRedirection}>
                            <Icon iconName="cartIcon" />
                            {webshop?.productsInCart?.length > 0 &&
                              (webshop?.productsInCart?.length < 10 ? (
                                <span className="item-count">
                                  {webshop?.productsInCart?.length}
                                </span>
                              ) : (
                                <span className="item-count">{`${NUMBERS.TEN}+`}</span>
                              ))}
                          </div>
                        </li>
                      )}
                    {!headerDisplayControl?.isLangSwitcherDisabled &&
                      applicationConfiguration?.isLanguageEnabled && (
                        <>
                          <li className="nav-item">
                            <LanguageSwitch
                              {...languageSelected}
                              onClick={handleLanguageSwitcher}
                              displayActive={isLangSwitcherActive}
                            />
                          </li>
                          <div
                            className="header-langswitcher-popup"
                            ref={refLanguageSwitcherButton}>
                            <LanguageSwitcherPopup
                              {...languageList}
                              loading={loading}
                              display={isLangSwitcherActive}
                            />
                          </div>
                        </>
                      )}

                    {!headerDisplayControl?.isLoginRegisterDisabled && userData.profileLoading ? (
                      <Loader display={userData.profileLoading} />
                    ) : (
                      <>
                        {topSection?.login_register_enable &&
                          (!userData.loginStatus ? (
                            <>
                              <li className="nav-item">
                                <div
                                  className="nav-item"
                                  onClick={() => {
                                    applicationConfiguration?.isSandozIDEnabled
                                      ? handleLoginRedirection()
                                      : setLoginPopupShow(true)
                                    handleDataLayerNavigationHeader(topSection?.loginText)
                                  }}
                                  role="button"
                                  onKeyDown={(event) => {
                                    toggleLoginPopUp(event)
                                    handleDataLayerNavigationHeader(topSection?.loginText)
                                  }}
                                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                  tabIndex={0}>
                                  <span className="header-userIcon">
                                    <Icon iconName="ProfileOutline" />
                                  </span>
                                  <p className="login bold">{topSection?.loginText}</p>
                                </div>
                              </li>
                              <li className="nav-item">
                                <span className="divider"> | </span>
                                <Anchor
                                  href={
                                    applicationConfiguration?.isSandozIDEnabled
                                      ? appendEncodedReturnUrl(topSection?.registerLink)
                                      : topSection?.registerLink
                                  }
                                  tabindex={0}
                                  className="register-link external"
                                  noUnderline={true}
                                  dataLayerOnClick={() =>
                                    handleDataLayerNavigationHeader(topSection?.registerText)
                                  }>
                                  <p className="register bold"> {topSection?.registerText}</p>
                                </Anchor>
                              </li>
                            </>
                          ) : (
                            <li
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                              tabIndex={0}
                              className="nav-item"
                              onClick={() => setIsDesktopSubMenuOpen(!isDesktopSubMenuOpen)}
                              onKeyDown={handleKeyDown}>
                              <div ref={desktopSubMenuRef}>
                                <div className="nav-item user-menu-hover">
                                  <div className="profile-name">
                                    <span className="header-userIcon">
                                      <Icon iconName="Profilefill" />
                                    </span>
                                    <p className="mb-0">
                                      {isDocCheckUser(user)
                                        ? applicationConfiguration?.doccheck_user_name
                                        : getUserNames(user).firstName}
                                    </p>
                                  </div>
                                </div>
                                {isDesktopSubMenuOpen && (
                                  <UserMenu
                                    userName={userData?.name}
                                    userMenuDetails={topSection?.userMenuDetails}
                                    makeUserLogout={makeUserLogout}
                                  />
                                )}
                              </div>
                            </li>
                          ))}
                      </>
                    )}
                  </ul>
                </div>

                <div className="header-menu-mobile">
                  {!headerDisplayControl?.isMegaMenuDisabled && (
                    <button
                      className="navbar-toggler collapsed mobile-close-icon"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarHeader"
                      aria-controls="navbarHeader"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      ref={refMenuButton}
                      onClick={() => (clickOutsideMenuDone = false)}
                      tabIndex={0}>
                      {!menuOpen && (
                        <Icon
                          iconName="HamburgerIcon2"
                          onClick={() => setMenuOpen(true)}
                          className="hamburger-icon"
                        />
                      )}
                      {menuOpen && <Icon iconName="Close2" onClick={() => setMenuOpen(false)} />}
                    </button>
                  )}
                  {!headerDisplayControl?.isLangSwitcherDisabled &&
                    applicationConfiguration?.isLanguageEnabled && (
                      <>
                        <div className="language-switcher-mob">
                          <LanguageSwitch
                            {...languageSelected}
                            onClick={handleLanguageSwitcher}
                            displayActive={isLangSwitcherActive}
                          />
                        </div>
                        <div className="header-langswitcher-popup" ref={refLanguageSwitcher}>
                          <LanguageSwitcherPopup {...languageList} display={isLangSwitcherActive} />
                        </div>
                      </>
                    )}

                  {!headerDisplayControl?.isLoginRegisterDisabled &&
                    topSection?.login_register_enable &&
                    (!userData.loginStatus ? (
                      <span
                        onClick={() => {
                          applicationConfiguration?.isSandozIDEnabled
                            ? handleLoginRedirection()
                            : setLoginPopupShow(true)
                        }}
                        onKeyDown={toggleLoginPopUp}
                        role="button"
                        tabIndex={1}
                        className="navbar-toggler collapsed ">
                        <Icon iconName="ProfileOutline" />
                      </span>
                    ) : (
                      <div
                        onClick={() => setIsMobileSubMenuOpen(!isMobileSubMenuOpen)}
                        onKeyDown={() => setIsMobileSubMenuOpen(!isMobileSubMenuOpen)}>
                        <div
                          ref={mobileSubMenuRef}
                          role="button"
                          tabIndex={1}
                          className="profile-ico">
                          <span role="button" tabIndex={1} className="navbar-toggler collapsed ">
                            <Icon iconName="Profilefill" />
                          </span>
                          {isMobileSubMenuOpen && (
                            <UserMenu
                              userName={userData?.name}
                              userMenuDetails={topSection?.userMenuDetails}
                              makeUserLogout={makeUserLogout}
                            />
                          )}
                        </div>
                      </div>
                    ))}

                  {!headerDisplayControl?.isCartDisabled &&
                    applicationConfiguration?.isWebStoreEnabled &&
                    (userData.loginStatus || showCartIcon) && (
                      <div className="cart-item">
                        <div
                          className="cart-icon"
                          role="button"
                          tabIndex={0}
                          onClick={handleCartNavigation}>
                          <span role="button" tabIndex={0} className="">
                            <Icon iconName="cartIcon" />
                            {webshop?.productsInCart?.length > 0 &&
                              (webshop?.productsInCart?.length < 10 ? (
                                <span className="item-count">
                                  {webshop?.productsInCart?.length}
                                </span>
                              ) : (
                                <span className="item-count">{`${NUMBERS.TEN}+`}</span>
                              ))}
                          </span>
                        </div>
                      </div>
                    )}
                  {!headerDisplayControl?.isSearchDisabled && (
                    <span className="navbar-toggler collapsed mobile-close-icon">
                      {!isSearchOpen && (
                        <Icon
                          iconName="Search2"
                          onClick={() => setIsSearchOpen(true)}
                          className="search-icon"
                        />
                      )}
                      {isSearchOpen && (
                        <Icon iconName="Close2" onClick={() => setIsSearchOpen(false)} />
                      )}{" "}
                    </span>
                  )}
                </div>
              </div>
            </nav>
            {!headerDisplayControl?.isMegaMenuDisabled ||
            !headerDisplayControl?.isSearchDisabled ? (
              <nav className="navbar navbar-expand-lg header-child">
                {!headerDisplayControl?.isMegaMenuDisabled && (
                  <div className="navbar-nav">
                    {megaMenu?.map((menuItem: IHeaderMenuProps, index: number) => (
                      <HeaderMenu
                        title={menuItem.title}
                        link={menuItem.link}
                        menuImageData={menuItem.menuImageData}
                        child={menuItem.child}
                        key={index}
                        subTheme={menuItem.subTheme}
                        targetType={menuItem.targetType}
                        icon_uri={menuItem?.icon_uri}
                        distinctive={menuItem?.distinctive}
                      />
                    ))}
                  </div>
                )}
                {!headerDisplayControl?.isSearchDisabled && (
                  <div className="header-list-item search">
                    {!isSearchOpen && (
                      <Icon
                        iconName="Search2"
                        className="search-ico"
                        onClick={() => setIsSearchOpen(true)}
                      />
                    )}
                    {isSearchOpen && (
                      <Icon
                        iconName="Close2"
                        className="close-ico"
                        onClick={() => setIsSearchOpen(false)}
                      />
                    )}
                  </div>
                )}
              </nav>
            ) : (
              <></>
            )}
            {!headerDisplayControl?.isMegaMenuDisabled ? (
              <div className="collapse" id="navbarHeader" ref={refMenu}>
                <div className="accordion accordionFlush" id="accordionFlushHeaderMobile">
                  {megaMenu?.map((menu_item: IHeaderMenuProps, key: number) => (
                    <div className="accordion-item" key={key} data-theme-hover={menu_item.subTheme}>
                      <div className="accordion-header" id={`flush-heading_${key}`}>
                        {menu_item.child ? (
                          <>
                            <Anchor
                              tabindex={0}
                              noUnderline={true}
                              href={menu_item.link}
                              dataLayerOnClick={() =>
                                handleDataLayerNavigationMenu(menu_item.title)
                              }>
                              {menu_item.title}
                            </Anchor>
                            <button
                              className="accordion-button collapsed "
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapse_${key}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapse_${key}`}>
                              <p className="MenuIndicator">
                                <Icon iconName="ChevronHeaderAccordion" width={32} height={32} />
                              </p>
                            </button>
                          </>
                        ) : (
                          <Anchor
                            tabindex={0}
                            noUnderline={true}
                            href={menu_item.link}
                            className=" collapsed menu-link"
                            dataLayerOnClick={() => handleDataLayerNavigationMenu(menu_item.title)}>
                            {menu_item.title}
                          </Anchor>
                        )}
                      </div>
                      <div
                        id={`flush-collapse_${key}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading_${key}`}
                        data-bs-parent="#accordionFlushHeaderMobile">
                        <div className="accordion-body child">
                          <div
                            className={`${"accordion accordion-flush menu-child"} ${
                              menu_item?.distinctive ? "distinctive" : ""
                            }`}
                            id={`accordionFlush${key}`}>
                            <div className="accordion-item">
                              <div className="accordion-header remove-for-now">
                                <Anchor
                                  tabindex={0}
                                  noUnderline={true}
                                  href={menu_item.link}
                                  className={`${"accordion-button collapsed menu-link menu-head"} ${
                                    menu_item?.distinctive ? "distinctive" : ""
                                  }`}
                                  dataLayerOnClick={() =>
                                    handleDataLayerNavigationMenu(menu_item.title)
                                  }>
                                  {menu_item.title}
                                </Anchor>
                              </div>
                            </div>
                            {menu_item.child?.map(
                              (childItem: IHeaderChildProps, childKey: number) => (
                                <div
                                  className="accordion-item"
                                  key={childKey}
                                  data-theme-hover={childItem?.subTheme}>
                                  <div
                                    className="accordion-header"
                                    id={`flush-heading_${key}${childKey}`}>
                                    {childItem.subChild ? (
                                      <>
                                        <Anchor
                                          href={childItem.link}
                                          tabindex={0}
                                          noUnderline={true}
                                          dataLayerOnClick={() =>
                                            handleDataLayerNavigationMenu(
                                              menu_item.title,
                                              childItem.title,
                                            )
                                          }>
                                          {childItem.title}
                                        </Anchor>
                                        <button
                                          className="accordion-button collapsed "
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#flush-collapse_${key}${childKey}`}
                                          aria-expanded="false"
                                          aria-controls={`flush-collapse_${key}${childKey}`}>
                                          <p className="MenuIndicator">
                                            <Icon
                                              iconName="ChevronHeaderAccordion"
                                              width={32}
                                              height={32}
                                            />
                                          </p>
                                        </button>
                                      </>
                                    ) : (
                                      <Anchor
                                        href={childItem.link}
                                        tabindex={0}
                                        noUnderline={true}
                                        className=" collapsed menu-link"
                                        dataLayerOnClick={() =>
                                          handleDataLayerNavigationMenu(
                                            menu_item.title,
                                            childItem.title,
                                          )
                                        }>
                                        {childItem.title}
                                      </Anchor>
                                    )}
                                  </div>

                                  <div
                                    id={`flush-collapse_${key}${childKey}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`flush-heading_${key}${childKey}`}
                                    data-bs-parent={`#accordionFlush${key}`}>
                                    <div className="accordion-body subchild">
                                      <div
                                        className={`${"accordion accordion-flush menu-child"} ${
                                          childItem?.distinctive ? "distinctive" : ""
                                        }`}
                                        id={`accordionFlush${key}${childKey}`}>
                                        <div className="accordion-item">
                                          <div className="accordion-header remove-for-now">
                                            <Anchor
                                              href={childItem.link}
                                              tabindex={0}
                                              noUnderline={true}
                                              className="accordion-button collapsed menu-link"
                                              dataLayerOnClick={() =>
                                                handleDataLayerNavigationMenu(
                                                  menu_item.title,
                                                  childItem.title,
                                                )
                                              }>
                                              {childItem.title}
                                            </Anchor>
                                          </div>
                                        </div>
                                        {childItem.subChild?.map(
                                          (
                                            subChildItem: IHeaderSubChildProps,
                                            subChildKey: number,
                                          ) => (
                                            <div
                                              className="accordion-item"
                                              key={subChildKey}
                                              data-theme-hover={subChildItem?.subTheme}>
                                              <div
                                                className="accordion-header"
                                                id={`flush-heading_${key}${childKey}${subChildKey}`}>
                                                {subChildItem.grandChild ? (
                                                  <>
                                                    {" "}
                                                    <Anchor
                                                      href={subChildItem.link}
                                                      tabindex={0}
                                                      noUnderline={true}
                                                      dataLayerOnClick={() =>
                                                        handleDataLayerNavigationMenu(
                                                          menu_item.title,
                                                          childItem.title,
                                                          subChildItem.title,
                                                        )
                                                      }>
                                                      {subChildItem.title}
                                                    </Anchor>
                                                    <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#flush-collapse_${key}${childKey}${subChildKey}`}
                                                      aria-expanded="false"
                                                      aria-controls={`flush-collapse_${key}${childKey}${subChildKey}`}>
                                                      <p className="MenuIndicator">
                                                        <Icon
                                                          iconName="ChevronHeaderAccordion"
                                                          width={32}
                                                          height={32}
                                                        />
                                                      </p>
                                                    </button>
                                                  </>
                                                ) : (
                                                  <Anchor
                                                    href={subChildItem.link}
                                                    tabindex={0}
                                                    noUnderline={true}
                                                    className=" collapsed menu-link"
                                                    dataLayerOnClick={() =>
                                                      handleDataLayerNavigationMenu(
                                                        menu_item.title,
                                                        childItem.title,
                                                        subChildItem.title,
                                                      )
                                                    }>
                                                    {subChildItem.title}
                                                  </Anchor>
                                                )}
                                              </div>

                                              <div
                                                id={`flush-collapse_${key}${childKey}${subChildKey}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`flush-heading_${key}${childKey}${subChildKey}`}
                                                data-bs-parent={`#accordionFlush${key}${childKey}`}>
                                                <div className="accordion-body grandSubchild">
                                                  <div
                                                    className="accordion accordion-flush"
                                                    id={`accordionFlush${key}${childKey}${subChildKey}`}>
                                                    <div className="accordion-item">
                                                      <div className="accordion-header remove-for-now">
                                                        <Anchor
                                                          href={subChildItem.link}
                                                          tabindex={0}
                                                          noUnderline={true}
                                                          className="accordion-button collapsed menu-link menu-head"
                                                          dataLayerOnClick={() =>
                                                            handleDataLayerNavigationMenu(
                                                              menu_item.title,
                                                              childItem.title,
                                                              subChildItem.title,
                                                            )
                                                          }>
                                                          {subChildItem.title}
                                                        </Anchor>
                                                      </div>
                                                    </div>
                                                    {subChildItem.grandChild?.map(
                                                      (
                                                        grandChildItem: IHeaderGrandChildProps,
                                                        grandChildKey: number,
                                                      ) => (
                                                        <div
                                                          className="accordion-item"
                                                          key={grandChildKey}
                                                          data-theme-hover={subChildItem?.subTheme}>
                                                          <div
                                                            className="accordion-header"
                                                            id={`flush-heading_${key}${childKey}${subChildKey}${grandChildKey}`}>
                                                            {grandChildItem.link == "#" ? (
                                                              <button
                                                                className="accordion-button collapsed"
                                                                type="button">
                                                                {grandChildItem.title}

                                                                <p className="MenuIndicator">
                                                                  <Icon
                                                                    iconName="ChevronHeaderAccordion"
                                                                    width={32}
                                                                    height={32}
                                                                  />
                                                                </p>
                                                              </button>
                                                            ) : (
                                                              <Anchor
                                                                href={grandChildItem.link}
                                                                tabindex={0}
                                                                noUnderline={true}
                                                                className="collapsed menu-link"
                                                                dataLayerOnClick={() =>
                                                                  handleDataLayerNavigationMenu(
                                                                    menu_item.title,
                                                                    childItem.title,
                                                                    subChildItem.title,
                                                                    grandChildItem.title,
                                                                  )
                                                                }>
                                                                {grandChildItem.title}
                                                              </Anchor>
                                                            )}
                                                          </div>
                                                        </div>
                                                      ),
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ),
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="product-list">
                  <Row>
                    {menuImageProduct.map((menuImage: IMenuImageProps, _key: number) => (
                      <MenuImage
                        imageLink={menuImage.imageLink}
                        title={menuImage.title}
                        paragraph={menuImage.paragraph}
                        redirectLink={menuImage.redirectLink}
                        key={_key}
                        alt={menuImage.alt}
                      />
                    ))}
                  </Row>
                </div>
              </div>
            ) : (
              <></>
            )}
            {!headerDisplayControl?.isLoginRegisterDisabled ? (
              <LoginPopup
                showPopup={loginPopupShow}
                id="login-popup"
                form={loginPopup.form}
                bannerCTA={loginPopup.bannerCTA}
                handlePopupShow={(popupShow) => {
                  resetRating({ id: undefined })
                  setLoginPopupShow(popupShow)
                }}
                handleForgotPasswordShow={(popupShow: boolean) => setForgotPasswordShow(popupShow)}
                popup={loginPopup.popup}
                loginConfirmation={() => setShowLoginToast(true)}
              />
            ) : (
              <></>
            )}
          </Container>
        </header>
      )}

      {!headerDisplayControl?.isSearchDisabled && isSearchOpen && (
        <div ref={refSearch}>
          <SearchBar
            isTypeAhead={true}
            searchContent={searchBarConfiguration}
            className="header-modal"
          />
        </div>
      )}
      {!!toastMessage && (
        <Notification
          notificationType="success"
          displayTxt={toastMessage}
          onClose={() => {
            setToastMessage("")
          }}
        />
      )}
      {showIndicator && overlayElement()}
    </>
  )
}

export default Header
