import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { consoleError } from "@utils/error"
import { INewsCardInfo } from "@organisms/newsFilterSearch/_newsFilterSearch.interface"
import { groupBy } from "@utils/dataModifier"
import NUMBERS from "@helpers/constants/numbers"
import moment from "moment"
import { DATE_FORMATS } from "@helpers/constants/generic"
import { getBlockData, getImageMeta, getTaxonomy } from "."
import getConfigDisplayData from "./getConfigDisplayData"
import { getApiDomainAndLang } from "@utils/baseApi"

const renderNewsSearchConfig = async () => {
  let newsConfigData = await getConfigDisplayData()
  newsConfigData = newsConfigData?.data
  return {
    show_sort_cta: newsConfigData?.news_sort_cta_toggle === 1,
    sort_cta_text: newsConfigData?.news_sort_cta_text ?? null,
    new_to_old_text: newsConfigData?.news_new_to_old_text ?? null,
    old_to_new_text: newsConfigData?.news_old_to_new_text ?? null,
    default_news_sort: newsConfigData?.news_default_sort ?? null,
    simple_search_label: newsConfigData?.news_simple_search_label ?? null,
    advance_search_label: newsConfigData?.news_advanced_search_label ?? null,
    from_placeholder_text: newsConfigData?.news_from_text ?? null,
    to_placeholder_text: newsConfigData?.news_to_text ?? null,
    search_cta_text: newsConfigData?.news_search_cta_text ?? null,
    search_placeholder_text: newsConfigData?.news_search_placeholder_text ?? null,
    search_results_found: newsConfigData?.news_search_results_found ?? null,
    no_results_found_title: newsConfigData?.news_no_results_found_title ?? null,
    no_results_found_body: newsConfigData?.news_no_results_found_body?.value ?? null,
    all_category_text: newsConfigData?.news_all_cat_text ?? null,
    other_category_text: newsConfigData?.news_other_category ?? "",
    news_cat_tabs_order1: newsConfigData?.news_cat_tabs_order1 ?? null,
  }
}

const getCardInfo = async (card: any): Promise<INewsCardInfo | null> => {
  const isChildPaywalled = isPaywallProtected(
    getNestedObject(card, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  if (isChildPaywalled) {
    return null
  }
  const imagePaywallData = isPaywallProtected(
    getNestedObject(card, "relationships.field_nas_image"),
  )

  const { lang } = getApiDomainAndLang()
  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(card?.relationships?.field_nas_image)
  const taxonomies =
    (await getTaxonomy(
      getNestedObject(card, "relationships.field_news_category.links.related.href") ?? "",
    )) ?? null
  const publishedDate = getNestedObject(card, "attributes.field_news_published_date")
  const monthAndYear = publishedDate
    ? moment(publishedDate).locale(lang).format(DATE_FORMATS.MMMM_YYYY) ?? ""
    : ""
  return {
    newsTaxonomy: card?.relationships?.field_category?.data?.[0]?.meta?.term_name ?? null,
    newsCategory: taxonomies[0]?.taxonomyLabel ?? null,
    title: isPaywallProtected(getNestedObject(card, "attributes.title") ?? null)
      .isPaywallProtectedFlag
      ? null
      : getNestedObject(card, "attributes.title") ?? null,
    publishedDate: publishedDate ?? null,
    monthAndYear: isPaywallProtected(publishedDate).isPaywallProtectedFlag ? null : monthAndYear,
    readMore: getNestedObject(card, "attributes.path.alias") ?? null,
    body:
      getNestedObject(card, "attributes.body.value") ??
      getNestedObject(card, "attributes.body.processed") ??
      null,
    imageUrl: imageUrl ?? null,
    alt: alt ?? null,
    styledimage: imageStyledUrl ?? null,
  }
}

const getNewsSearchData = async (url: string) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getNewsSearchData.name}.`)
  }
  const response = await getBlockData(url)

  const newsSearchConfig = await renderNewsSearchConfig()
  const newsCatTabsOrder = Object.values(newsSearchConfig?.news_cat_tabs_order1 ?? []) ?? []
  const newsResults = response?.data ?? []
  const fieldPaywallRoles = getAllRoles(newsResults)
  const newsCardData = await Promise.all(newsResults.map(async (card: any) => getCardInfo(card)))

  if (newsSearchConfig.default_news_sort === newsSearchConfig.new_to_old_text) {
    newsCardData.sort(
      (a: INewsCardInfo, b: INewsCardInfo): number =>
        Date.parse(b.publishedDate) - Date.parse(a.publishedDate),
    )
  } else {
    newsCardData.sort(
      (a: INewsCardInfo, b: INewsCardInfo): number =>
        Date.parse(a.publishedDate) - Date.parse(b.publishedDate),
    )
  }

  const groupedData = groupBy<INewsCardInfo>(newsCardData, "newsCategory")
  const groupedDataKeys = Object.keys(groupedData)
  const groupedResult: any[] = []
  groupedDataKeys.forEach((key) => {
    const obj = {} as any
    const newsOrder = newsCatTabsOrder.find((item: any) => item.news_cat === key) as any
    obj.category = key !== "null" ? key : newsSearchConfig?.other_category_text
    obj.results = groupedData[key]
    obj.order = newsOrder ? newsOrder.news_cat_tab_weight : null
    if (obj.category === newsSearchConfig?.other_category_text) {
      groupedResult.push(obj)
    } else {
      groupedResult.unshift(obj)
    }
  })

  groupedResult.sort((a: any, b: any): number => parseInt(a.order, 10) - parseInt(b.order, 10))

  return {
    itemsPerPage: NUMBERS.TWELVE,
    newsCardData: filterNullValues(newsCardData),
    newsSearchConfig,
    groupedResult,
    fieldPaywallRoles,
  }
}

export default getNewsSearchData
