import getNestedObject from "@utils/nestedObjects"

const getSlidesDetails = (data: any) => {
  let arr: any = []
  let slideObj = {}
  const keyLeader = data?.attributes?.field_key_opinion_leaders?.value ?? ""
  const desc = data?.attributes?.body?.value ?? ""
 const slideData= getNestedObject(data,"relationships.field_sandoz_academy_modules_act.data")
 if(slideData){
 slideData.map((slide: any) => {
    slideObj = {
      id: slide?.meta?.drupal_internal__target_id,
      title: slide?.meta?.activity_details?.field_activity_title ?? "",
      subTitle: slide?.meta?.activity_details?.field_sub_title ?? "",
      duration: slide?.meta?.activity_details?.field_activity_time ?? "",
      field_activity_component:
        slide?.meta?.activity_details?.field_activity_component?.[0]?.value ?? "",
      activityStatus: slide?.meta?.activity_details?.activity_status == 1,
      overlayLabel: slide?.meta?.activity_details?.overlay_label ?? "",
      enableOverlay: slide?.meta?.activity_details?.enable_disable_course_completion_overlay == 1,
      media: {
        size:
          slide?.meta?.activity_details?.field_media_container?.[0]?.field_display_variations ??
          null,
        position:
          slide?.meta?.activity_details?.field_media_container?.[0]?.field_alignment_styles ?? "",
        title: "",
        closeIcon: false,
        src: slide?.meta?.activity_details?.field_media_container?.[0]?.body ?? "",
      },
      image_media: slide?.meta?.activity_details?.field_activity_image?.[0] ?? "",
      activityInfo: {
        durationLabel: slide?.meta?.activity_details?.duration_label ?? "",
        duration: slide?.meta?.activity_details?.field_activity_time ?? "",
        title: slide?.meta?.activity_details?.field_activity_title ?? "",
        keyOpinion: keyLeader,
        desc,
        activityStatus: slide?.meta?.activity_details?.activity_status == 1,
        markAsCompCtaLabel: slide?.meta?.activity_details?.mark_as_complete_label ?? "",
        enableMarkAsComp: slide?.meta?.activity_details?.mark_as_complete == 1,
        completedLabel: slide?.meta?.activity_details?.mark_as_complete_cta_completed_label ?? "",
        downloadCtaDetails: {
          title: slide?.meta?.activity_details?.download_label ?? "",
          ctaLabel: slide?.meta?.activity_details?.download_cta_label ?? "",
          ctaLink: slide?.meta?.activity_details?.download_file ?? null,
        },
      },
      returnToCourse: {
        label: slide?.meta?.activity_details?.return_to_course?.label ?? "",
        link: slide?.meta?.activity_details?.return_to_course?.link ?? "",
      },
    }
    arr.push(slideObj)
  })

  return arr
}
else return []
}
const getActivityDetailsData = (...args: any) => {
  const data = args[3]?.data
  const slidesDetails = getSlidesDetails(data)
  return {
    headingSection: {
      moduleHeading: data?.attributes?.field_module_heading?.value ?? "",
      moduleTitle: data?.attributes?.title ?? "",
    },
    slides: {
      duration: 0,
      carouselBlockType: "course_activity",
      carouselDisplayType: "catalogue",
      itemPerSlide: slidesDetails?.length < 4 ? slidesDetails?.length : 4,
      slideItemsForTablet: slidesDetails?.length < 2 ? slidesDetails?.length : 2,
      slideItemsForMobile: 1,
      slidesInfo: slidesDetails,
    },
  }
}

export default getActivityDetailsData
