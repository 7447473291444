import { useSelector } from "react-redux"
import { IActivityDetails } from "./_activityDetails.interface"
import CourseActivitySlider from "@molecules/courseActivitySlider"

const ActivityDetails = ({ headingSection, slides }: IActivityDetails) => {
  const user: any = useSelector((state: UserState) => state.user)
  if (user?.isLoggedIn) {
    return (
      <div className="activityDetails">
        <div className="activityDetails-header">
          {headingSection?.moduleHeading ? (
            <p className="activityDetails-header-heading">{headingSection?.moduleHeading}</p>
          ) : (
            <></>
          )}
          {headingSection?.moduleTitle ? (
            <h2 className="activityDetails-header-title">{headingSection?.moduleTitle}</h2>
          ) : (
            <></>
          )}
        </div>
        <div className="activityDetails-media">
          <CourseActivitySlider slides={slides} />
        </div>
      </div>
    )
  } else return <></>
}

export default ActivityDetails
