import React, { useEffect, useState } from "react"
import { Button, Icon } from "@atoms"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import { IViewSortOptionProps } from "./_viewAndSortOptions.interface"

const ViewAndSortOption = ({
  label,
  onSaveIdHandler,
  view,
  handleSort,
  isMobileDisplay,
  hideViewTypeCTA = true,
  hideSortCTA = true,
  setOrderSortValue,
  defaultSort,
  isOrderByVisible = false,
  labelKeyValuePair
}: IViewSortOptionProps) => {
  const defaultView = "ASC"
  const [isOpen, setIsOpen] = useState(false)
  const [sortValue, setSortValue] = useState<string | undefined>("")
  const [sortView, setSortView] = useState(defaultSort ?? defaultView)
  const showViewTypeButton = !isMobileDisplay && !hideViewTypeCTA

  const option = [label?.old_to_new_text, label?.new_to_old_text]
  useEffect(() => {
    setSortValue(label?.default_sort)
  }, [])

  const handleList = ({ target }: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = target as HTMLInputElement
    setSortValue(value)
    setIsOpen(!isOpen)
    handleSort?.(value)
  }

  const handleOrderSort = () => {
    const changedView = sortView === "ASC" ? "DESC" : "ASC"
    setSortView(changedView)
    setOrderSortValue && setOrderSortValue(changedView)
  }
  const [showTooltip, setShowTooltip] = useState(false)
  const handleToggleViewHover = () => {
    setShowTooltip(true)
  }

  const handleToggleViewLeave = () => {
    setShowTooltip(false)
  }
  const labelObject = (labelKeyValuePair ?? []).reduce((acc, item) => {
    const key = Object.values(item).find((val): val is string => typeof val === "string" && val.endsWith("_tooltip_text"))
    const value = Object.values(item).find((val): val is string => typeof val === "string" && !val.endsWith("_tooltip_text"))
    if (key && value) {
      return { ...acc, [key]: value }
    }
    return acc 
  }, {} as Record<string, string>)
  
  const sortHoverText = labelObject?.["sort_tooltip_text"]
  const listHoverText = labelObject?.["list_tooltip_text"]
  const gridHoverText = labelObject?.["grid_tooltip_text"]

  return (
    <div className="modal-buttons-group">
      {showViewTypeButton && (
        <div
          className="sort-button--toggle"
          onMouseEnter={handleToggleViewHover}
          onMouseLeave={handleToggleViewLeave}
        >
          <Button
            className={`btn-style ${(!label?.list_view_text && view === mediaLibraryConstants.GRID_VIEW) || (!label?.grid_view_text && view !== mediaLibraryConstants.GRID_VIEW) ? "no-label" : ""}`}
            tabindex={1}
            isSecondary
            onClick={() => onSaveIdHandler?.()}
            icon={view === mediaLibraryConstants.GRID_VIEW ? <><Icon iconName="ListView" /> {!label?.list_view_text && listHoverText && showTooltip && <div className="tooltip">{listHoverText}</div>}</>
              :
              <><Icon iconName="GridView" /> {!label?.grid_view_text && gridHoverText && showTooltip && <div className="tooltip">{gridHoverText}</div>}</>}
          >
            {view === mediaLibraryConstants.GRID_VIEW ? label?.list_view_text : label?.grid_view_text}
          </Button>
        </div>
      )}
      {!hideSortCTA && (
        <div className="dropdown">
          <div
            className="sort-button--toggle"
            onMouseEnter={handleToggleViewHover}
            onMouseLeave={handleToggleViewLeave}
          >
            <Button
              tabindex={2}
              onClick={() => setIsOpen(!isOpen)}
              isSecondary
              icon={<><Icon iconName="Sort" /> {!label?.sort_cta_text && sortHoverText && showTooltip && <div className="tooltip">{sortHoverText}</div>}</>}
              className={`btn-style ${!label?.sort_cta_text ? "no-label" : ""}`}
            >
              {label?.sort_cta_text}
            </Button>
          </div>
          {isOpen && (
            <>
              <div className="dropdown-content">
                {option.map((item) => (
                  <button
                    type="button"
                    key={item}
                    className="list-group-item"
                    value={item}
                    onClick={handleList}
                  >
                    <span className={item === sortValue ? "checkMark" : "noCheckMark"}>
                      {item === sortValue ? <Icon iconName="CheckMark" /> : ""}
                    </span>
                    <span>{item}</span>
                  </button>
                ))}
              </div>
              <div className="dropdown-background" onClick={() => setIsOpen(false)} />
            </>
          )}
        </div>
      )}
      {(showViewTypeButton || isOrderByVisible) && (
        <div className="sort-button--toggle"
          onMouseEnter={handleToggleViewHover}
          onMouseLeave={handleToggleViewLeave}>
          <Button
            ariaLabel="sort"
            className="sort-btn-style"
            tabindex={1}
            isSecondary
            onClick={() => handleOrderSort()}
            icon={sortView === "ASC" ? <><Icon iconName="AtoZSort" /> {sortHoverText && showTooltip && <div className="tooltip">{sortHoverText}</div>}</>
              :
              <><Icon iconName="ZtoASort" />{sortHoverText && showTooltip && <div className="tooltip">{sortHoverText}</div>}</>}
          />
        </div>
      )}
    </div>
  )
}

export default ViewAndSortOption
