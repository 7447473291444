import { consoleError } from "@utils/error"
import { config } from "@utils/baseApi/config.external"
import { getApi } from "@utils/baseApi"
import NUMBERS from "@helpers/constants/numbers"
import { IWebFormData } from "./_api.interface"
import getConfigDisplayData from "./getConfigDisplayData"

const SCOUT_FIELD_TYPE = {
  SCOUT_FIELD: "st_sc_field",
  COMMENTS: "comments",
}

const getConditions = (checks: any) => {
  const isMultiCheck = checks?.multiple ?? false
  const multiCheckOperator = isMultiCheck ? checks.operator : null

  const conditions =
    Object.entries(checks || {}).reduce((accumulator, [key, value]) => {
      if (key === "multiple" || key === "operator") {
        return accumulator
      }
      const newAcc = { ...accumulator }
      try {
        if (key.indexOf("parentField") >= 0) {
          const idx = key.replace("parentField", "") || "0"
          newAcc[idx] = { id: idx, selector: (value as string).replace(/[^a-zA-Z[_ 0-9]+/g, "") }
        }
        if (key.indexOf("value") >= 0) {
          const idx = key.replace("value", "") || "0"
          if (newAcc[idx]) {
            newAcc[idx] = {
              ...newAcc[idx],
              value,
              operator: typeof value === "boolean" ? "checked" : "equal",
            }
          }
        }
      } catch (error) {
        return newAcc
      }
      return newAcc
    }, {} as any) ?? null

  return {
    isMultiCheck,
    multiCheckOperator,
    conditions: Object.entries(conditions || {}).map(([, condition]: any) => condition) ?? [],
  }
}

const getScoutFieldName = (val: IWebFormData | any) => {
  if (val["#scout_field_type"] === SCOUT_FIELD_TYPE.SCOUT_FIELD) {
    return val["#scout_field_name"] || null
  }
  if (val["#scout_field_type"] === SCOUT_FIELD_TYPE.COMMENTS) {
    return val["#comment_field_name"] || null
  }
  return null
}

const getFormattedOptions = (val: IWebFormData | any) => {
  return val["#webform_key"] !== "smart_mi_country"
    ? Object.entries(val["#options"] || {}).map(([optionKey, optionValue], index) => ({
        label: optionValue,
        value: optionKey,
        id: index,
      })) ?? []
    : Object.keys(val["#options"] || {}).map((key, index) => ({
        label: val["#options"]?.[key]?.display_name,
        value: key,
        countryCode: val["#options"]?.[key]?.country_code,
        id: index,
      })) ?? []
}

const getFieldValues = (val: IWebFormData | any, emailPhoneFlag = false) => {
  switch (val["#type"]) {
    case "textfield":
    case "date":
    case "webform_time":
    case "number":
    case "textarea": {
      const data = {
        type: val["#type"] ?? "text",
        label: val["#title"] ?? "",
        formName: val["#webform"] ?? "",
        isDisabled: val["#disabled"] ?? false,
        key: val["#webform_key"] ?? "",
        validations: {
          mandatory: {
            required: val["#required"] ?? false,
            message: val["#required_error"] ?? "",
          },
          pattern: {
            required: !!val["#pattern"] ?? false,
            regEx: val["#pattern"] ?? "",
            message: val["#pattern_error"] ?? "",
          },
          counter:{
            counterMinimum: val["#counter_minimum"] ?? null,
            counterMaximum: val["#counter_maximum"] ?? null,
            counterMaximumMessage: val["#counter_maximum_message"] ?? "",
          },
        },
        maxLength: val["#maxlength"] ?? null,
        validationErrorMessage: val["#required_error"] ?? "",
        inputField: {
          name: val["#webform_key"],
          placeholder: val["#placeholder"] ?? "",
          required: val["#required"] ?? false,
        },
        description: val["#description"] ?? "",
        helpText: val["#help_title"] ?? "",
        helpDescription: val["#help"] ?? "",
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
        scoutFieldName: getScoutFieldName(val),
        toolTipText: val["#tooltip"] ?? null,
      }
      return data
    }
    case "tel":
    case "email": {
      const data = {
        type: val["#type"] ?? "text",
        label: val["#title"] ?? "",
        formName: val["#webform"] ?? "",
        isDisabled: val["#disabled"] ?? false,
        key: val["#webform_key"] ?? "",
        validations: {
          mandatory: {
            required: (val["#required"] || emailPhoneFlag) ?? false,
            message: val["#required_error"] ?? emailPhoneFlag ? val["#placeholder"] : "",
          },
          pattern: {
            required: !!val["#pattern"] ?? false,
            regEx: val["#pattern"] ?? "",
            message: val["#pattern_error"] ?? "",
          },
        },
        maxLength: val["#maxlength"] ?? null,
        validationErrorMessage: val["#required_error"] ?? "",
        inputField: {
          name: val["#webform_key"],
          placeholder: val["#placeholder"] ?? "",
          required: (val["#required"] || emailPhoneFlag) ?? false,
        },
        description: val["#description"] ?? "",
        helpText: val["#help_title"] ?? "",
        helpDescription: val["#help"] ?? "",
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
        scoutFieldName: getScoutFieldName(val),
        toolTipText: val["#tooltip"] ?? null,
      }
      return data
    }
    case "checkbox": {
      const data = {
        type: val["#type"],
        title: val["#title"] ?? "",
        label: val["#label"] ?? "",
        id: val["#webform_id"],
        value: val["#title"] ?? "",
        formName: val["#webform"] ?? "",
        key: val["#webform_key"] ?? "",
        validations: {
          mandatory: {
            required: val["#required"] ?? false,
            message: val["#required_error"] ?? "",
          },
        },
        isDisabled: val["#disabled"] ?? false,
        isChecked: val["#default_value"] ?? false,
        description: val["#description"] ?? "",
        helpText: val["#help_title"] ?? "",
        helpDescription: val["#help"] ?? "",
        isRequired: val["#required"] ?? false,
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
        scoutFieldName: getScoutFieldName(val),
      }
      return data
    }
    case "select":
    case "webform_term_select":
    case "checkboxes":
    case "radios": {
      const data = {
        type: val["#type"] ?? "text",
        label: val["#title"] ?? "",
        formName: val["#webform"] ?? "",
        key: val["#webform_key"] ?? "",
        placeholder: val["#empty_option"] ?? "",
        isDisabled: val["#disabled"] ?? false,
        validations: {
          mandatory: {
            required: val["#required"] ?? false,
            message: val["#required_error"] ?? "",
          },
        },
        isMulti: val["#multiple"] ?? false,
        validationErrorMessage: val["#required_error"] ?? "",
        options: getFormattedOptions(val),
        description: val["#description"] ?? "",
        helpText: val["#help_title"] ?? "",
        helpDescription: val["#help"] ?? "",
        isBtnStyled: val["#attributes"]?.class?.indexOf("custom-button-element") >= 0 || false,
        wrapper_attributes: val["#wrapper_attributes"] ?? "",
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
        scoutFieldName: getScoutFieldName(val),
      }
      return data
    }
    case "webform_email_confirm": {
      const emailData = {
        label: val["#title"] ?? "",
        maxLength: val["#maxlength"] ?? null,
        isDisabled: val["#disabled"] ?? false,
        inputField: {
          name: val["#webform_key"],
          placeholder: val["#placeholder"] ?? "",
          required: val["#required"] ?? false,
        },
      }
      const confirmEmailData = {
        label: val["#confirm__title"] ?? "",
        maxLength: val["#maxlength"] ?? null,
        isDisabled: val["#disabled"] ?? false,
        confirmEmailReqMsg: val["#required_confirm_email_error"] ?? "",
        inputField: {
          name: val["#webform_key"],
          placeholder: val["#confirm__placeholder"] ?? "",
          required: val["#required"] ?? false,
        },
      }
      const data = {
        type: val["#type"] ?? "text",
        formName: val["#webform"] ?? "",
        key: val["#webform_key"] ?? "",
        isDisabled: val["#disabled"] ?? false,
        description: val["#description"] ?? "",
        helpText: val["#help_title"] ?? "",
        helpDescription: val["#help"] ?? "",
        validationErrorMessage: val["#error_mismatch_email"] ?? "",
        validations: {
          mandatory: {
            required: val["#required"] ?? false,
            message: val["#required_error"] ?? "",
          },
          pattern: {
            required: !!val["#pattern"] ?? false,
            regEx: val["#pattern"] ?? "",
            message: val["#pattern_error"] ?? "",
          },
        },
        email: emailData,
        confirmEmail: confirmEmailData,
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
      }
      return data
    }
    case "custom_recaptcha_element": {
      const data = {
        type: val["#type"] ?? "",
        isDisabled: val[" #enable_captcha"] === NUMBERS.ZERO,
        formName: val["#webform"] ?? "",
        key: val["#webform_key"] ?? "",
        recaptchaSiteKey: config?.recapchaKey ?? "",
      }
      return data
    }
    case "webform_actions":
      return {
        text: val["#submit__label"] ?? "webform action",
        url: val["#submit_url"] ?? "#",
        type: "button",
        role: "submit",
        isDisabled: val["#disabled"] ?? false,
        formName: val["#webform"] ?? "",
        key: val["#webform_key"] ?? "",
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
      }
    case "label":
      return {
        text: val["#title"] ?? "",
        url: "#",
        type: "button",
        role: "reset",
        isDisabled: val["#disabled"] ?? false,
        formName: val["#webform"] ?? "",
        key: val["#webform_key"] ?? "",
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
      }
    case "processed_text":
      return {
        type: val["#type"],
        text: val["#text"] ?? "",
        formName: val["#webform"] ?? "",
        isDisabled: val["#disabled"] ?? false,
        key: val["#webform_key"] ?? "",
        conditionalShow: val["#states"]?.visible ? getConditions(val["#states"]?.visible) : null,
      }
    case "hidden":
      return {
        type: val["#type"],
        isDisabled: val["#disabled"] ?? false,
        key: val["#webform_key"] ?? "",
      }
    case "webform_autocomplete": {
      const data = {
        type: val["#type"],
        buttonLabel: val["#soi2_search_btn_label"] ?? "null",
        placeholder: val["#placeholder"],
        key: val["#webform_key"],
        id: val["#webform_id"],
        title: val["#title"],
        description: val["#description"] ?? null,
        searchLabel: val["#admin_title"] ?? null,
        fieldName: val["#webform_parents"] ?? null,
        productFoundText: val["#product_found_text"] ?? null,
      }
      return data
    }
    default:
      return {
        type: val["#type"] ?? "text",
      }
  }
}

const getGenericWebFormData = async (url: string, ...args: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getGenericWebFormData.name}.`)
  }
  const serverData = args?.[3] // accepting serverData only
  const rawWebFormData = await getApi(url, false, serverData)
  const notificationMessages = {
    error_message: "",
    success_message: "",
    warning_message: "",
  }
  let configData = await getConfigDisplayData()
  configData = configData?.data
  const emailPhoneFlag = configData?.enable_smart_med_mandatory_email_phone === 0 ? false : true

  let categoryType = ""
  let prefetchUrl = ""
  const redirectUrl = rawWebFormData?.redirect_to ? rawWebFormData.redirect_to : null
  let webformAlignment = ""

  const webFormData = rawWebFormData
    ? Object.entries(rawWebFormData).reduce((accumulator, [label, value]: [string, any]) => {
        if (value && typeof value === "object") {
          if (label === "success" || label === "soi_2_success_msg") {
            notificationMessages.success_message = value["#message_message"]
            return accumulator
          }
          if (label === "error" || label === "soi_2_error_msg") {
            notificationMessages.error_message = value["#message_message"]
            return accumulator
          }
          if (label === "warning") {
            notificationMessages.warning_message = value["#message_message"]
            return accumulator
          }
          if (value["#type"] === "webform_flexbox") {
            webformAlignment = value["#align_items"] || ""
            return accumulator
          }
          if (label === "sendout_taxonomy_details") {
            const logoField = {
              logoFieldValue: value
            }
            return [...accumulator, logoField]
          }
          if (value["#type"] === "category_type") {
            categoryType = value["#value"] || ""
            return accumulator
          }
          if (value["#input_hide"] === true) {
            return accumulator
          }
          if (label == "rest_api_url") {
            prefetchUrl = value["#preFetchUrl"]
          }
          const returnValue = getFieldValues(value, emailPhoneFlag)
          return [...accumulator, returnValue]
        }
        return accumulator
      }, [] as any)
    : []

  return {
    fields: webFormData ?? null,
    notificationMessages,
    webformAlignment,
    categoryType,
    redirectUrl,
    prefetchUrl
  }
}

export default getGenericWebFormData
