import { useCallback, useEffect, useState } from "react"
import { Button, Column, Img, Row } from "@atoms"
import { ICourseActivitySliderProps } from "./_courseActivitySlider.interface"
import FlexibleCarousel from "@organisms/flexibleCarousel"
import MediaPlayer from "@molecules/mediaPlayer"
import ActivityInformation from "@molecules/activityInformation"
import { btnClickTabChange } from "store/actions/AcademyCourseActionCreators"
import { connect, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { useRouter } from "next/router"

interface DispatchProps {
  btnClickTabChange: (data: any) => void
}

type StateTypes = {
  academyCourseFilterData: {
    btnClickTabChange: boolean
  }
}

type Props = DispatchProps & StateTypes & ICourseActivitySliderProps
export const getAIdValue = (queryString: string) => {
  const regex = /[?&]aid=([^&]+)/ // Match the 'aid' parameter
  const match = queryString.match(regex) 
  return match?.length ? match?.[1] : null // Return the captured value or null if not found
}

export const getObjectById = (array: any, idToFind: any) => {
  return array.find((obj: any) => obj.id == idToFind)
}

const CourseActivitySlider = (props: Props) => {
  const queryString = decodeURI(
    window?.location?.search ? window.location.search?.replace("?", "&") : "",
  )
  let queryparamAid
  if (queryString) {
    queryparamAid = getAIdValue(queryString)
  }

  const [aId, setAid] = useState<any>(queryparamAid ?? "")
  const [activeValue, setActiveValue] = useState<any>(
    getObjectById(props?.slides?.slidesInfo, queryparamAid ?? "") ?? {},
  )
  const [slidesData, setSlidesData] = useState<any>(props?.slides)
  const [isActivityCompleted, setActivityCompleted] = useState<boolean>(false)
  const dispatch: Dispatch<any> = useDispatch()
  const btnClickTabChangeState: any = useCallback(
    (value: boolean) => props?.btnClickTabChange(value),
    [dispatch],
  )
  const router = useRouter()
  const moveObjectsToEnd = (arr: any, id: any) => {
    const index = arr.findIndex((obj: any) => obj.id == id)

    if (index !== -1) {
      const objectsToMove = arr.splice(0, index)
      arr.push(...objectsToMove)
    }

    return arr
  }

  useEffect(() => {
    if (props?.slides?.slidesInfo?.length > 4) {
      moveObjectsToEnd(props?.slides?.slidesInfo, aId)
    }
  }, [])

  useEffect(() => {
    if (aId) {
      setActiveValue(getObjectById(slidesData?.slidesInfo, aId))
    }
  }, [aId])

  const handleClick = (link: string) => {
    router.push(link)
    setTimeout(() => {
      btnClickTabChangeState({ btnClickTabChanged: true })
    }, 100)
  }

  const getUpdatedSlidesData = () => {
    const getNewData = slidesData?.slidesInfo?.map((slide: any) => {
      if (slide?.id == aId) {
        const requiredSlide = {
          ...slide,
          activityStatus: true,
          enableOverlay: true,
          overlayLabel: slide?.overlayLabel,
          activityInfo: {
            ...slide?.activityInfo,
            activityStatus: true,
          },
        }
        setActiveValue(requiredSlide)
        return requiredSlide
      }

      return slide
    })
    const updatedSlidesData = { ...props?.slides, slidesInfo: getNewData }
    setSlidesData(updatedSlidesData)
  }

  useEffect(() => {
    if (isActivityCompleted) {
      getUpdatedSlidesData()
    } else null
  }, [isActivityCompleted])

  return (
    <div className="course-activity-slider">
      <Row>
        {activeValue?.field_activity_component === "Main activity components" ? (
          <Column mobile={12} tablet={12} desktop={12}>
            <MediaPlayer {...activeValue?.media} />
          </Column>
        ) : (
          <Column mobile={12} tablet={12} desktop={12} className="course-activity-slider-media">
            <Img
              alt={activeValue?.image_media?.alt}
              src={activeValue?.image_media?.src}
              width="968"
              height="546"
            />
          </Column>
        )}
      </Row>
      <Row>
        <Column mobile={12} tablet={12} desktop={12} className="activity-slider-row">
          <div className="activity-slider">
            <FlexibleCarousel {...slidesData} setAid={setAid} aId={aId} />
          </div>
        </Column>
      </Row>

      <Row>
        <Column mobile={12} tablet={12} desktop={12} className="activity-slider-row">
          <ActivityInformation
            {...activeValue?.activityInfo}
            selectedAid={aId}
            setActivityCompleted={setActivityCompleted}
          />
          {activeValue?.returnToCourse?.label && activeValue?.returnToCourse?.link ? (
            <div>
              <Button onClick={() => handleClick(activeValue?.returnToCourse?.link)} tabindex={0}>
                {activeValue?.returnToCourse?.label}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Column>
      </Row>
    </div>
  )
}

const mapDispatch = {
  btnClickTabChange: (data: any) => btnClickTabChange(data),
}

const mapState = (state: any) => ({
  academyCourseFilterData: state.academyCourseReducer,
})
export default connect(mapState, mapDispatch)(CourseActivitySlider)
